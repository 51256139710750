<template>
  <div class="previewMain">
    <div class="phone">
      <div class="tip">
        <img :src="activityInfo.market_template_image" />
      </div>
    </div>
    <div class="previewAbout">
      <div class="tittle">
        <div class="span"></div>
        活动二维码/链接
      </div>
      <div class="qrcode">
        <div id="qrCodeDiv" ref="qrCodeDiv"></div>
      </div>
      <div class="qrTip" v-if="activityInfo.status === 20">
        <div class="previewMainQrcode" @click="dowload">下载二维码</div>
        <div>1000*1000像素</div>
      </div>
      <div style="display: flex; width: 300px">
        <div class="link">
          {{
            activityInfo.status === 20
              ? activityInfo.h5_url
              : activityInfo.h5_url_preview
          }}
        </div>
        <div
          class="copy"
          data-clipboard-text="复制"
          @click="
            copylogistics(
              activityInfo.status === 20
                ? activityInfo.h5_url
                : activityInfo.h5_url_preview
            )
          "
        >
          复制
        </div>
      </div>
      <div
        style="
          margin-top: 5px;
          text-align: center;
          color: #909399;
          font-size: 12px;
        "
      >
        {{
          activityInfo.status !== 20
            ? "注：以上二维码和地址并非正式链接，仅供预览活动使用"
            : "您可将活动链接复制到您的公众号菜单"
        }}
      </div>
      <div style="display: none" ref="verifCode" id="verifCode"></div>
      <div class="tittle">
        <div class="span"></div>
        活动核销二维码
      </div>
      <div style="display: flex" >
        <el-select
          placeholder="请选择门店"
          style="width: 180px"
          v-model="hierarchy_id"
          @change="handleStore"
        >
          <template v-for="(item, index) in storeList">
            <el-option
              :label="item.hierarchy_name"
              :value="item.hierarchy_id"
              :key="index"
            ></el-option>
          </template>
        </el-select>
        <el-button
          @click="dowloadVerifCode"
          type="primary"
          plain
          style="margin-left: 15px"
          >下载核销二维码</el-button
        >
      </div>
      <div
        style="text-align: center; margin-top: 40px"
        v-if="activityInfo.status !== 20"
      >
        <el-button type="primary" plain @click="handleEdit">进入编辑</el-button>

        <el-button type="primary" @click="updateStatus(20)">立即发布</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Clipboard from "clipboard"; //点击复制文本内容

import QRCode from "qrcodejs2";
import { updateStatus } from "@/api/marketing/activity/index";
import { getMerchants } from "@/api/authority/menage";

export default {
  data() {
    return {
      hierarchy_id: null,
      storeList: [],
      verifUrl: null,
      code: null,
    };
  },
  created() {
    this.getMerchantListReq();
  },
  mounted() {
    this.handleQRCode();
  },
  props: {
    activityInfo: Object,
  },
  methods: {
    handleStore(val) {
      this.verifUrl = this.activityInfo.verif_goods + val;
      if (this.code) {
       this.code.clear();
       this.code.makeCode(this.verifUrl);
      } else {
        this.code = new QRCode("verifCode", {
          text: this.verifUrl,
          width: 520,
          height: 520,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
        });
        console.log(this.code);
      }
    },
    /*
     *  下载核销二维码
     */
    dowloadVerifCode() {
      if (this.verifUrl) {
        let myCanvas = document
          .getElementById("verifCode")
          .getElementsByTagName("canvas");
        let a = document.createElement("a");
        a.href = myCanvas[0].toDataURL("image/png");
        a.download = "核销二维码";
        a.click();
        this.$message({
          message: "正在进行下载保存",
          type: "success",
        });
      } else {
        this.$message({
          type: "info",
          message: "请选择门店",
        });
      }
    },
    //复制功能
    copylogistics(data) {
      let clipboard = new Clipboard(".copy", {
        //绑定数据标签的类名
        text: function () {
          return data;
        },
      });
      clipboard.on("success", (e) => {
        this.$message({
          type: "success",
          message: "复制成功！",
        });
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$message({
          type: "success",
          message: "该浏览器或手机权限不支持复制功能",
        });

        // 释放内存
        clipboard.destroy();
      });
    },
    handleEdit() {
      this.$emit("edit", this.activityInfo);
    },
    /*
     *  下载二维码
     */
    dowload() {
      let myCanvas = document
        .getElementById("qrCodeDiv")
        .getElementsByTagName("canvas");
      let a = document.createElement("a");
      a.href = myCanvas[0].toDataURL("image/png");
      a.download = "二维码";
      a.click();
      this.$message({
        message: "正在进行下载保存",
        type: "success",
      });
    },
    /*
     *  获取门店数据
     */
    getMerchantListReq() {
      getMerchants().then((res) => {
        if (res.code === 1) {
          this.storeList = res.data;
        }
      });
    },
    handleQRCode() {
      this.qrcode = new QRCode("qrCodeDiv", {
        text:
          this.activityInfo.status === 20
            ? this.activityInfo.h5_url
            : this.activityInfo.h5_url_preview,
        width: 220,
        height: 220,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    /*
     *  发布
     */
    updateStatus(status) {
      const data = {
        market_id: this.activityInfo.market_id,
        market_template_category_id:
          this.activityInfo.market_template_category_id,
        status,
      };
      updateStatus(data).then((res) => {
        if (res.code === 1) {
          this.$emit("hide");
          this.getList();
        }
      });
    },
  },
};
</script>


<style lang="less" scoped>
.previewMain {
  margin: 10px 60px 30px 60px;
  display: flex;
  .phone {
    padding-top: 65px;
    width: 300px;
    height: 524px;
    background-image: url("../../../../assets/images/activity/shouji.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .tip {
      margin: 0 auto 0 auto;
      width: 94.5%;
      max-height: 98.5%;
      scrollbar-width: none;
      -ms-overflow-style: none;
      overflow-x: hidden;
      overflow-y: scroll;
      border-radius: 0 0 25px 25px;
      color: #909399;
      font-size: 14px;

      img {
        width: 100%;
      }
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .previewAbout {
    margin: 0 0 0 60px;
    .tittle {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 55px;
      line-height: 55px;
      font-size: 14px;
      font-weight: 600;

      .span {
        margin: auto 6px auto 0;
        width: 4px;
        height: 12px;
        background: #5493f5;
        border-radius: 2px;
      }
    }
    .qrcode {
      padding: 40px;
      width: 220px;
      height: 220pxf;
      opacity: 1;
      background: #ffffff;
      border: 1px solid #e2e2e2;
      border-radius: 5px 5px 0 0;
    }
    .qrTip {
      height: 62px;
      background: #ececec;
      text-align: center;

      .previewMainQrcode {
        text-decoration: underline;
        color: #2791ff;
        line-height: 32px;
      }
      .previewMainQrcode:hover {
        cursor: pointer;
      }
    }
    .link {
      margin-top: 50px;
      width: 345px;
      padding: 3px 0 3px 14px;
      opacity: 1;
      background: #ffffff;
      border: 1px solid #e2e2e2;
      border-radius: 5px 0px 0 5px;
      color: #909399;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
    .copy {
      margin-top: 50px;
      width: 60px;
      padding: 3px 0 3px 0;
      opacity: 1;
      background: rgba(39, 145, 255, 0.06);
      border: 1px solid #2791ff;
      border-radius: 0 5px 5px 0;
      text-align: center;
      color: #2791ff;
    }
    .copy:hover {
      cursor: pointer;
    }
  }
}
</style>
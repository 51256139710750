<template>
  <div style="margin-left: 20px">
    <div style="width: 60%; display: flex">
      <el-button
        type="primary"
        plain
        style="margin-bottom: 15px"
        @click="addGoods"
        class="el-icon-plus"
      >
        新增商品</el-button
      >
      <div style="margin-left: 30px">
        设置返佣
        <el-tooltip
          effect="dark"
          content="推荐开启，可大幅提高活动用户数量。用户A分享活动让用户B下单后就可以获得返佣奖励。注意：返佣金额不能大于支付金额，且不能低于0.3元。"
          placement="top"
        >
          <div
            class="el-icon-info"
            style="margin: 15px 8px 0 0px; color: #409eff"
          ></div>
        </el-tooltip>
        <el-switch
          v-model="activityInfo.rebate_status"
          active-value="20"
          inactive-value="10"
        />
      </div>
      <div
        style="margin-left: 10px"
        v-if="activityInfo.rebate_status && activityInfo.rebate_status === '20'"
      >
        分佣排行榜
        <el-tooltip
          effect="dark"
          content="开启后可以看到分佣的排行榜，提高生活的趣味性"
          placement="top"
        >
          <div
            class="el-icon-info"
            style="margin: 15px 8px 0 0px; color: #409eff"
          ></div>
        </el-tooltip>
        <el-switch
          v-model="activityInfo.rebate_list"
          active-value="10"
          inactive-value="20"
        />
      </div>
    </div>
    <template v-for="(item, index) in activityInfo.goodsList">
      <div :key="index" style="display: flex">
        <div class="goodsBox">
          <div class="lock" v-if="index !== 0 && activityInfo.market_template_category_id == 6">
            <span>解锁模式</span>
            <el-radio-group v-model="item.model">
              <el-radio label="4">邀请解锁模式</el-radio>
              <el-radio label="2">预存解锁模式</el-radio>
              <el-radio label="3">秒杀解锁模式</el-radio>
            </el-radio-group>
          </div>
          <div style="display: flex;">
            <div
              style="position: absolute; right: 5px; top: 5px"
              v-if="index !== 0"
            >
              <img
                class="delete"
                src="../../../../assets/images/activity/close.svg"
                style="width: 25px; height: 25px"
                @click="delGoods(index)"
              />
            </div>
            <div>
              <el-form-item
                style="margin-left: -170px !important"
                :prop="'goodsList.' + index + '.goods_pic'"
                :rules="{
                  required: true,
                  trigger: 'change',
                  message: '请上传图片',
                }"
              >
                <el-upload
                  style="margin-top: 15px"
                  class="avatar-uploader"
                  action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
                  :data="{
                    directory: 'AGENT_INFO',
                  }"
                  :headers="{
                    authorization: token,
                  }"
                  :show-file-list="false"
                  :on-success="
                    (response, file) =>
                      handleDetailsSuccess(response, file, index)
                  "
                >
                  <div>
                    <img
                      v-if="item.goods_pic"
                      :src="item.goods_pic"
                      class="avatarOther"
                    />
                    <i
                      v-else
                      class="el-icon-plus avatar-uploader-icon"
                      style="background-color: white"
                    ></i>
                  </div>
                </el-upload>
              </el-form-item>
            </div>
            <div>
              <div class="flex">
                <el-form-item
                  label-width="150px"
                  label="商品名称"
                  :prop="'goodsList.' + index + '.goods_name'"
                  :rules="{
                    required: true,
                    message: '请输入商品名称',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    v-model="item.goods_name"
                    style="width: 90%"
                    placeholder="请输入商品名称"
                    :maxlength="20"
                    show-word-limit
                  />
                </el-form-item>
                <el-form-item
                  label-width="150px"
                  label="商品原价"
                  :prop="'goodsList.' + index + '.original_price'"
                  :rules="{
                    required: true,
                    message: '请输入商品原价',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    v-model="item.original_price"
                    style="width: 90%"
                    placeholder="请输入商品原价"
                  />
                </el-form-item>
                <el-form-item
                  v-if="
                    activityInfo.market_template_category_id === 2 ||
                    activityInfo.market_template_category_id === 5 ||
                    (activityInfo.market_template_category_id === 6 && item.model == 2)
                  "
                  label-width="150px"
                  label="预存金额"
                  :prop="'goodsList.' + index + '.sale_price'"
                  :rules="{
                    required: true,
                    message: '请输入预存金额',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    v-model="item.sale_price"
                    style="width: 90%"
                    placeholder="请输入预存金额"
                  />
                </el-form-item>
                <el-form-item
                  v-if="activityInfo.market_template_category_id === 3"
                  label-width="150px"
                  label="秒杀金额"
                  :prop="'goodsList.' + index + '.sale_price'"
                  :rules="{
                    required: true,
                    message: '请输入秒杀金额',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    v-model="item.sale_price"
                    style="width: 90%"
                    placeholder="请输入秒杀金额"
                  />
                </el-form-item>
                <el-form-item
                  v-if="((activityInfo.market_template_category_id === 6) && ((item.model == 1) || (item.model == 3))) ||  activityInfo.market_template_category_id === 7"
                  label-width="150px"
                  label="活动售价"
                  :prop="'goodsList.' + index + '.sale_price'"
                  :rules="{
                    required: true,
                    message: '请输入活动售价',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    v-model="item.sale_price"
                    style="width: 90%"
                    :placeholder="'请输入活动售价'"
                  />
                </el-form-item>
                <el-form-item
                  v-if="(activityInfo.market_template_category_id === 6) && (item.model != 1)"
                  label-width="150px"
                  label="邀请"
                  :prop="'goodsList.' + index + '.orders'"
                  :rules="{
                    required: true,
                    message: '请输入邀请人数',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    v-model="item.orders"
                    style="width: 50%"
                    :placeholder="'请输入邀请人数'"
                  /><span style="margin-left: 10px">人购买解锁</span>
                </el-form-item>
                <el-form-item
                  v-if="activityInfo.market_template_category_id === 6"
                  label-width="150px"
                  label="购买限制"
                >
                  <el-input
                    v-if="index==0"
                    :value="1"
                    disabled
                    style="width: 90%"
                    placeholder="请输入购买限制"
                  />
                   <el-input
                    v-else
                     v-model="item.limit"
                    style="width: 90%"
                    placeholder="请输入购买限制"
                  />
                  <div style="color:red;font-size:12px;line-height:24px" v-if="index!=0">0或者不填为不限制次数</div>
                </el-form-item>
                <el-form-item
                  label="到店付"
                  v-if="
                    activityInfo.market_template_category_id === 2 ||
                    activityInfo.market_template_category_id === 5 ||
                    (activityInfo.market_template_category_id === 6 && item.model == 2)
                  "
                  label-width="150px"
                  :prop="'goodsList.' + index + '.surplus_price'"
                  :rules="{
                    required: true,
                    message: '请输入到店付金额',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    placeholder="请输入到店付金额"
                    v-model="item.surplus_price"
                    style="width: 75%"
                    onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                  /><span style="margin-left: 5px">元</span></el-form-item
                >
                <el-form-item
                  label="购买限制"
                  label-width="150px"
                  v-if="
                    activityInfo.market_template_category_id === 3 ||
                    activityInfo.market_template_category_id === 5 ||
                    activityInfo.market_template_category_id === 2 ||
                    activityInfo.market_template_category_id === 7
                  "
                >
                  <el-input
                    style="width: 75%"
                    placeholder="次数"
                    v-model="item.limit"
                  />
                  次
                </el-form-item>
                <el-form-item
                  label="返佣金额"
                  label-width="150px"
                  v-if="
                    activityInfo.rebate_status &&
                    activityInfo.rebate_status === '20' &&
                    item.model != 4
                  "
                >
                  <el-input
                    placeholder="请输入返佣金额"
                    style="width: 82%"
                    v-model="item.rebate_prize"
                    onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                  /><span style="margin-left: 5px">元</span></el-form-item
                >
                <el-form-item
                  label="购买赠送抽奖机会"
                  v-if="
                    activityInfo.market_template_category_id === 5
                  "
                  label-width="150px"
                >
                  <div style="display: flex">
                    <el-input
                      style="width: 90%; margin-right: 5px"
                      placeholder="抽奖机会"
                      v-model="item.buy_draw_num"
                    />
                  </div>
                </el-form-item>
                <el-form-item
                  label-width="150px"
                  label="商品库存"
                  :prop="'goodsList.' + index + '.goods_count'"
                  :rules="{
                    required: true,
                    message: '请输入商品库存',
                    trigger: 'blur',
                  }"
                >
                  <div style="display: flex">
                    <el-input
                      v-model="item.goods_count"
                      :style="{ width: (activityStatus === 30) && (item.market_goods_id) ? '30%' : '90%', marginRight: '5px' }"
                      placeholder="请输入商品数量"
                      :disabled="
                        activityStatus === 30 && item.market_goods_id
                          ? true
                          : false
                      "
                      onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                    />
                    <!-- <span
                      style="margin-left: 10px; font-size: 14px; color: #606266"
                      v-if="activityStatus == 30 && item.market_goods_id"
                      >追加数量：</span
                    >
                    <el-input
                      v-if="activityStatus == 30 && item.market_goods_id"
                      placeholder="请输入商品数量"
                      v-model="item.goods_count_new"
                      style="width: 30%;margin-"
                      onkeyup="this.value=this.value.replace(/[^\d]/g,'');"
                    /> -->
                    <!-- 调整库存 -->
                    <template v-if="activityStatus == 30 && item.market_goods_id">
                      <el-select v-model="item.inventory_type" placeholder="请选择" style="width: 120px;">
                        <el-option label="增加库存" value="inc"></el-option>
                        <el-option label="减少库存" value="dec"></el-option>
                      </el-select>
                      <el-input style="margin-left: 10px; width: 150px;" v-model="item.goods_count_change" onkeyup="this.value=this.value.replace(/[^\d]/g,'');" placeholder="请输入库存数量" />
                    </template>
                  </div>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="prizePlain" style="margin-left: 20px" v-if="activityInfo.market_template_category_id != 6">
          <div
            style="margin-top: 40px"
            class="top"
            v-if="index !== 0"
            @click="handleTop(item, index)"
          >
            <i class="el-icon-top" style="font-size: 32px"></i>
          </div>
          <div
            style="margin-top: 20px"
            class="bottom"
            v-if="index + 1 !== activityInfo.goodsList.length"
            :style="index === 0 ? 'margin-top:60px;' : null"
            @click="handleBottom(item, index)"
          >
            <i class="el-icon-bottom" style="font-size: 32px"></i>
          </div>
        </div>
        <div class="prizePlain" style="margin-left: 20px" v-else>
          <div
            style="margin-top: 40px"
            class="top"
            v-if="index !== 0 && index !== 1"
            @click="handleTop(item, index)"
          >
            <i class="el-icon-top" style="font-size: 32px"></i>
          </div>
          <div
            style="margin-top: 20px"
            class="bottom"
            v-if="index + 1 !== activityInfo.goodsList.length && index !== 0"
            :style="index === 0 ? 'margin-top:60px;' : null"
            @click="handleBottom(item, index)"
          >
            <i class="el-icon-bottom" style="font-size: 32px"></i>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {},
    };
  },
  props: {
    activityInfo: Object,
    templateInfo: Object,
    token: String,
    type: String,
    activityStatus: Number,
  },
  mounted() {
    this.info = this.activityInfo;
    console.log(this.activityInfo.goodsList)
    if (this.type === "add" && this.activityInfo.goodsList.length === 0) {
      if (
        this.activityInfo.goods_state &&
        this.activityInfo.goods_state === "10"
      ) {
        for (var i = 0; i < Number(this.activityInfo.goods_num) - 1; i++) {
          let obj = {
            type: 10,
            goods_name: null,
            goods_pic: null,
            goods_count: 0,
            goods_count_json: [],
            hit_odds: 0,
            original_price: null,
            sale_price: null,
            surplus_price: null,
            rebate_prize: null,
            limit:""
          };
          if (this.activityInfo.market_template_category_id === 6) {
            if (i === 0) {
              obj.model = 1;
            } else {
              obj.model = 4;
            }
          }
          this.info.goodsList.push(obj);
        }
      } else {
        let obj = {
            type: 10,
            goods_name: null,
            goods_pic: null,
            goods_count: null,
            goods_count_json: [],
            hit_odds: null,
            original_price: null,
            sale_price: null,
            surplus_price: null,
            rebate_prize: null,
            limit:""
        };
        if (this.activityInfo.market_template_category_id === 6) {
            obj.model = 1;
          }
        this.info.goodsList = [
          {...obj}
        ];
      }
    }
  },
  methods: {
    handleDetailsSuccess(res, file, i) {
      if (res.code === 1) {
        this.info.goodsList.map((item, index) => {
          if (index === i) {
            item.goods_pic = res.data;
          }
          return item;
        });
        this.$emit("update:activityInfo", this.info);
      } else {
        this.$message({
          type: "error",
          message: res.msg,
        });
      }
    },
    /*
     *  新增商品
     */
    addGoods() {
      let obj = {
        type: 10,
        goods_name: null,
        goods_pic: null,
        goods_count: null,
        goods_count_json: [],
        original_price: null,
        sale_price: null,
        surplus_price: null,
        rebate_prize: null,
        limit:""
      }
      if (this.activityInfo.market_template_category_id === 6) {
        obj.model = '4'
      }
      this.info.goodsList.push({
        ...obj,
      });
    },
    /*
     *  删除商品
     */
    delGoods(i) {
      this.$confirm("是否确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.info.goodsList = this.info.goodsList.filter(
          (item, index) => index !== i
        );
        this.$emit("update:activityInfo", this.info);
      });
    },
    /*
     * 升序
     */
    handleTop(item, index) {
      let list = this.info.goodsList;
      this.handleSwapItems(list, index, index - 1);
      this.$emit("update:activityInfo", this.info);
    },
    /*
     *  降序
     */
    handleBottom(item, index) {
      let list = this.info.goodsList;
      this.handleSwapItems(list, index, index + 1);
      this.$emit("update:activityInfo", this.info);
    },
    handleSwapItems(arr, indexOne, indexTwo) {
      arr[indexOne] = arr.splice(indexTwo, 1, arr[indexOne])[0];
      return arr;
    },
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
}
.lock {
  margin-bottom: 10px;
  span {
    margin-right: 10px;
  }
}
.goodsBox {
  position: relative;
  margin-bottom: 10px;
  padding: 15px 15px 0 15px;
  width: 70%;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
.delete:hover {
  cursor: pointer;
}
.prizePlain:hover {
  cursor: pointer;
}

.prizePlain .top:hover i {
  color: #5cb6ff;
}
.prizePlain .bottom:hover i {
  color: #5cb6ff;
}
</style>

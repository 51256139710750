import { post } from '@/utils/request';

// 列表
const listReq = (cond) => post({
  url: '/store/goods.handling.Split/list',
  data: {
    ...cond,
  },
});

// 拆改单打印数据
const getSplitPrintReq = (params) => post({
  url: '/store/goods.handling.Split/splitPrint',
  data: {
    ...params,
  },
});

// 删除拆改单
const cancelDel = (data) => post({
  url: '/store/goods.handling.Split/splitDel',
  data: data
})

// 拆改单反确认删除
const handelCanceled = (data) => post({
    url: '/store/goods.handling.Split/handelSplit',
    data: data
})

// 拆改单反确认编辑
const canceledUpdate = (data) => post({
  url: '/store/goods.handling.Split/splitUpdate',
  data: data
})

export {
  listReq,
  getSplitPrintReq,
  handelCanceled,
  cancelDel,
  canceledUpdate,
};

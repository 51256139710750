<template>
  <div>
    <div v-show="!isAdd && !isDetail && !activityDataVisiable" style="position: relative;">
      <div class="card" style="display: flex">
        <div class="left">
          <el-button
            type="primary"
            icon="el-icon-plus"
            slot="reference"
            @click="handleAdd"
            >新增活动</el-button
          >
        </div>
        <div class="line"></div>
        <div id="middle">
          <el-form :model="cond" class="middle" label-width="80px">
            <el-form-item label="活动名称" style="margin-right: 30px">
              <el-input
                clearable
                v-model="cond.title"
                autocomplete="off"
                placeholder="请输入活动名称"
                style="width: 180px"
              ></el-input
            ></el-form-item>
            <el-form-item label="活动时间" style="margin-right: 30px">
              <el-date-picker
                type="daterange"
                v-model="cond.time"
                range-separator="至"
                start-placeholder="活动开始日期"
                end-placeholder="活动结束日期"
              ></el-date-picker
            ></el-form-item>
            <el-form-item label="活动状态">
              <el-select
                clearable
                v-model="cond.status"
                placeholder="请选择活动状态"
                style="width: 200px; margin-right: 10px"
              >
                <el-option label="待发布" :value="10"></el-option>
                <el-option label="进行中" :value="20"></el-option>
                <el-option label="暂停" :value="30"></el-option>
                <el-option label="结束" :value="40"></el-option>
                <el-option label="待完善" :value="50"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button
            type="primary"
            @click="searchData()"
            style="margin-left: 10px; height: 40px"
            >搜索</el-button
          >
          <el-button @click="handleReset" style="height: 40px">重置</el-button>
        </div>
      </div>
      <div class="content" >
        <div class="toolTitle">
          <div
            class="box"
            v-for="(item, index) in marketTemplateCategoryList"
            :key="index"
            :style="
              selectTool === item.market_template_category_name
                ? 'color: #2791FF'
                : ''
            "
            @click="changeTool(item)"
          >
            {{ item.market_template_category_name }}
            <div
              class="span"
              v-if="selectTool === item.market_template_category_name"
            ></div>
          </div>
        </div>
      </div>
      <div class="list" style="margin-bottom:100px">
        <template v-for="(item, index) in activityList">
          <div
            :key="index"
            class="item"
            :style="(index + 1) % 5 !== 0 ? 'margin-right:5px' : ''"
          >
            <div class="top">
              <div
                class="img"
                :style="
                  'background-image: url(' + item.market_template_image + ')'
                "
              ></div>
              <div class="actionTittle">
                <div class="name">{{ item.title }}</div>
              </div>
            </div>

            <div
              class="status"
              v-if="item.status === 10"
              style="background: #ffa21a"
            >
              待发布
            </div>
            <div
              class="status"
              v-if="item.status === 20"
              style="background: #40bd63"
            >
              进行中
            </div>
            <div
              class="status"
              v-if="item.status === 30"
              style="background: #ec561f"
            >
              暂停中
            </div>
            <div
              class="status"
              v-if="item.status === 40"
              style="background: #909399"
            >
              已结束
            </div>
            <div
              class="status"
              v-if="item.status === 50"
              style="background: #2791ff"
            >
              待完善
            </div>
            <div class="about">
              <div class="li">
                <div class="title">活动时间</div>
                <div class="text">
                  {{ item.start_time }} 至 {{ item.end_time }}
                </div>
              </div>
              <div class="li">
                <div class="title">参与人数</div>
                <div class="text">{{ item.join_total_num }}</div>
              </div>
              <div class="li">
                <div class="title">创建人</div>
                <div class="text">{{ item.store_user_name }}</div>
              </div>
            </div>
            <div class="activeLine"></div>
            <div class="action">
              <div
                class="btn stop"
                v-if="
                  item.status === 20 &&
                  Number(userInfo.hierarchy_id) === item.hierarchy_id
                "
                @click="updateStatus(30, item)"
              >
                <div class="icon">
                  <img
                    src="../../../../assets/images/activity/icon_suspend.png"
                  />
                </div>
                暂停
              </div>
              <div
                class="btn release"
                @click="handleRelease(item)"
                v-if="
                  item.status === 10 &&
                  Number(userInfo.hierarchy_id) === item.hierarchy_id
                "
              >
                <div class="icon">
                  <img
                    src="../../../../assets/images/activity/icon_release.png"
                  />
                </div>
                发布
              </div>
              <div
                class="btn start"
                v-if="
                  item.status === 30 &&
                  Number(userInfo.hierarchy_id) === item.hierarchy_id
                "
                @click="updateStatus(20, item)"
              >
                <div class="icon">
                  <img
                    src="../../../../assets/images/activity/icon_start.png"
                  />
                </div>
                开始
              </div>
              <div
                class="btn edit"
                @click="handleEdit(item)"
                v-if="
                  item.status !== 20 &&
                  Number(userInfo.hierarchy_id) === item.hierarchy_id
                "
              >
                <div class="icon">
                  <img src="../../../../assets/images/activity/icon_edit.png" />
                </div>
                编辑
              </div>

              <div class="btn edit" @click="handlePreview(item)">
                <div class="icon">
                  <img
                    src="../../../../assets/images/activity/icon-details.png"
                  />
                </div>
                预览
              </div>
              <div
                class="btn edit"
                slot="reference"
                @click="handleActivityData(item)"
                v-if="Number(userInfo.hierarchy_id) !== item.hierarchy_id"
              >
                <div class="icon">
                  <img src="../../../../assets/images/activity/icon_more.png" />
                </div>
                详情
              </div>
              <el-popover
                placement="top-start"
                trigger="hover"
                v-if="Number(userInfo.hierarchy_id) === item.hierarchy_id"
              >
                <div class="data" @click="handleActivityData(item)">详情</div>
                <div class="proLine"></div>
                <div class="del" @click="delActivity(item)">删除</div>
                <div class="btn edit" slot="reference">
                  <div class="icon">
                    <img
                      src="../../../../assets/images/activity/icon_more.png"
                    />
                  </div>
                  更多
                </div>
              </el-popover>
            </div>
          </div>
        </template>
      </div>
      <div v-if="activityList.length === 0" class="noneData">暂无数据</div>

      <div class="pagina" >
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="15"
          :page-sizes="[15, 30, 40, 50]"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
      </div>
    </div>
    <Add
      v-if="isAdd"
      @hide="getList"
      :store_activity_id="store_activity_id"
      :store_category_id="store_category_id"
    />
    <Details
      v-if="isDetail"
      @hide="getList"
      :store_activity_id="store_activity_id"
      :store_category_id="store_category_id"
    />
    <el-dialog
      :visible.sync="releaseVisiable"
      title="发布确认"
      :destroy-on-close="true"
      width="25%"
      :before-close="cancelRelease"
    >
      <div class="releaseText">
        <div class="li">
          <div class="title">活动标题</div>
          <div class="about">{{ activityInfo.title }}</div>
        </div>
        <div class="li">
          <div class="title">活动模板</div>
          <div class="about">测试</div>
        </div>
        <div class="li">
          <div class="title">活动时间</div>
          <div class="about">
            {{ activityInfo.start_time }} -
            {{ activityInfo.end_time }}
          </div>
        </div>
        <div class="li">
          <div class="title">创建人</div>
          <div class="about">{{ activityInfo.store_user_name }}</div>
        </div>
        <div class="li" style="margin-top: 30px; color: #ec561f">
          发布后活动时间、派奖方式、分享增加次数、奖品将不能更改，且
          未发布时的测试数据会被清空。
        </div>
      </div>
      <div style="text-align: center">
        <el-button style="width: 86px" @click="cancelRelease">取消</el-button>

        <el-button
          style="width: 98px; text-align: center"
          type="primary"
          @click="handleFabu(20)"
          >确定发布</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="previewVisiable"
      title="活动预览"
      :destroy-on-close="true"
      :before-close="cancelPreview"
    >
      <Preview
        :activityInfo="activityInfo"
        @hide="getList"
        @edit="handleEdit"
        v-if="previewVisiable"
      />
    </el-dialog>
    <ActivityData
      @hide="getList"
      v-if="activityDataVisiable"
      :store_activity_id="store_activity_id"
      :store_category_id="store_category_id"
    />
  </div>
</template>

<script>
import {
  getActivityList,
  getMarketTemplateCategoryList,
  updateStatus,
  deleteActivity,
} from "@/api/marketing/activity/index";
import Add from "./add.vue";
import ActivityData from "./activityData.vue";
import Preview from "./preview";
import Detail from "../../../goods/goodsHandler/change/Detail.vue";
import { dateFormat } from "@/utils/method";
import storage from "good-storage";
export default {
  data() {
    const userInfo = storage.get("userInfo");
    return {
      userInfo,
      isAdd: false,
      isDetail: false,
      store_activity_id: 0,
      cond: {},
      activityList: [],
      total: 0,
      page: 1,
      limit: 15,
      marketTemplateCategoryList: [],
      selectTool: "全部",
      releaseVisiable: false,
      previewVisiable: false,
      activityDataVisiable: false,
      activityInfo: {},
      qrcode: null,
      market_template_category_id: 0,
      store_category_id: 0,
    };
  },
  created() {
    console.log(this.userInfo);
    this.getList();
    this.getMarketTemplateCategoryList();
  },
  activated() {
    this.isAdd = false;
    this.getList();
  },

  components: {
    Add,
    Detail,
    ActivityData,
    Preview,
  },
  methods: {
    changeTool(item) {
      this.activityList = [];
      this.total = 0;
      this.selectTool = item.market_template_category_name;
      this.market_template_category_id = item.market_template_category_id;
      this.getList();
    },
    /*
     *  获取活动列表
     */
    getList() {
      this.previewVisiable = false;
      this.store_activity_id = 0;
      this.isAdd = false;
      this.isDetail = false;
      this.activityDataVisiable = false;
      const data = {
        market_template_function: 10,
        page: this.page,
        limit: this.limit,
        market_template_category_id: this.market_template_category_id,
      };
      const timeList = this.cond.time || [];
      if (timeList.length !== 0) {
        data.active_time = [
          dateFormat("YYYY-mm-dd", timeList[0]),
          dateFormat("YYYY-mm-dd", timeList[1]),
        ];
      }
      if (this.cond.title) {
        data.title = this.cond.title;
      }
      if (this.cond.status) {
        data.status = this.cond.status;
      }
      getActivityList(data).then((res) => {
        if (res.code === 1) {
          this.activityList = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /*
     *  模板分类
     */
    getMarketTemplateCategoryList() {
      getMarketTemplateCategoryList().then((res) => {
        if (res.code === 1) {
          this.marketTemplateCategoryList = res.data.list;
          this.marketTemplateCategoryList.unshift({
            market_template_category_id: "",
            market_template_category_name: "全部",
          });
        }
      });
    },
    /*
     *  发布
     */
    handleFabu(status) {
      const data = {
        market_id: this.activityInfo.market_id,
        market_template_category_id:
          this.activityInfo.market_template_category_id,
        status,
      };
      updateStatus(data).then((res) => {
        if (res.code === 1) {
          this.releaseVisiable = false;
          this.getList();
        }
      });
    },
    updateStatus(status, item) {
      this.activityInfo = item;
      const data = {
        market_id: this.activityInfo.market_id,
        market_template_category_id:
          this.activityInfo.market_template_category_id,
        status,
      };
      if (status === 30) {
        this.$confirm("是否确定暂停该活动？暂停后将无法游戏", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            updateStatus(data).then((res) => {
              if (res.code === 1) {
                this.$message({
                  type: "success",
                  message: "已暂停活动",
                });
                this.releaseVisiable = false;
                this.getList();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消暂停",
            });
          });
      } else {
        this.$confirm("是否确定开始该活动？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            updateStatus(data).then((res) => {
              if (res.code === 1) {
                this.$message({
                  type: "success",
                  message: "已开始活动",
                });
                this.releaseVisiable = false;
                this.getList();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消暂停",
            });
          });
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      const data = {
        page: val,
        limit: this.limit,
        market_template_function: 10,
        market_template_category_id: this.market_template_category_id,
        ...this.cond,
      };
      getActivityList(data).then((res) => {
        if (res.code === 1) {
          this.activityList = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /*
     *  页数
     */
    handleSizeChange(val) {
      this.limit = val;
      const data = {
        page: this.page,
        limit: val,
        market_template_function: 10,
        market_template_category_id: this.market_template_category_id,
        ...this.cond,
      };
      getActivityList(data).then((res) => {
        if (res.code === 1) {
          this.activityList = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /*
     *  重置
     */
    handleReset() {
      this.page = 1;
      this.cond = {};
      this.market_template_category_id = "";
      this.selectTool = "全部";
      this.getList();
    },
    /*
     *  搜索
     */
    searchData() {
      this.page = 1;
      this.getList();
    },
    /*
     *  新增活动
     */
    handleAdd() {
      this.store_activity_id = 0;
      this.$router.push({
        name: "activityAdd",
      });
    },
    /*
     *  编辑
     */
    handleEdit(item) {
      this.previewVisiable = false;
      this.isAdd = true;
      this.store_activity_id = item.market_id;
      this.store_category_id = item.market_template_category_id;
    },
    /*
     *  发布
     */
    handleRelease(item) {
      this.releaseVisiable = true;
      this.activityInfo = item;
    },
    cancelRelease() {
      this.releaseVisiable = false;
    },
    handlePreview(item) {
      this.activityInfo = item;
      this.previewVisiable = true;
    },

    cancelPreview() {
      this.previewVisiable = false;
    },
    handleActivityData(item) {
      this.store_activity_id = item.market_id;
      this.store_category_id = item.market_template_category_id;
      this.activityDataVisiable = true;
    },
    /*
     *  活动删除
     */
    delActivity(item) {
      this.$confirm("是否确定删除该活动？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteActivity({ market_id: item.market_id }).then((res) => {
            if (res.code === 1) {
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.activityType {
  margin-top: 10px;
  line-height: 30px;

  .li {
    width: 90px;
    font-weight: 600;
  }
  .li:hover {
    cursor: pointer;
    color: #2791ff;
  }
}
.pagina{
  position:fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
.middle {
  display: flex;
  flex-wrap: wrap;
  height: 40px;
}
.toolTitle {
  display: flex;
  height: 50px;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-radius: 0 0 6px 6px;

  .box {
    width: 150px;
    text-align: center;
    line-height: 50px;
    font-size: 14px;
    color: #333333;
    .span {
      margin: -3px auto 0 auto;
      width: 20px;
      height: 4px;
      background: #5493f5;
      border-radius: 2px;
    }
  }
  .box:hover {
    cursor: pointer;
  }
}
.list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .item {
    width: 17.86%;
    position: relative;
    padding: 16px;
    margin-bottom: 10px;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 6px;

    .top {
      display: flex;
      width: 100%;
      .actionTittle {
        width: 60%;
        margin: 10px 0 0 15px;
        font-size: 14px;
        font-weight: 600;

        .name {
          width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
        }
      }
    }
    .img {
      position: relative;
      width: 40%;
      height: 64px;
      border-radius: 6px;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .status {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 57px;
      height: 25px;
      background: linear-gradient(#45d05b 0%, #3fb965 100%);
      border-radius: 4px;
      font-size: 14px;
      text-align: center;
      line-height: 25px;
      color: white;
    }

    .about {
      .li {
        line-height: 30px;
        display: flex;
        .title {
          width: 20%;
          color: #909399;
          font-size: 14px;
        }
        .text {
          width: 80%;
          color: #565656;
          font-size: 14px;
        }
      }
    }
    .activeLine {
      margin: 5px 0 15px 0;
      width: 100%;
      height: 1px;
      background: #e9e9e9;
    }
    .action {
      width: 100%;
      padding: 0 5px;
      display: flex;

      .btn {
        display: flex;
        width: 74px;
        height: 34px;
        opacity: 1;
        border-radius: 5px;
        line-height: 34px;
        text-align: center;
        font-size: 14px;
        margin-right: 9px;
        .icon {
          margin: 3px 6px 0 10px;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      .btn:hover {
        cursor: pointer;
      }
      .start {
        background: rgba(64, 188, 99, 0.06);
        border: 1px solid #40bc63;
        color: #40bc63;
      }
      .stop {
        color: #e7541e;
        border: 1px solid #e7541e;
      }
      .release {
        background: #2791ff;
        border: 1px solid #2791ff;
        color: white;
      }
      .edit {
        background: #f2f9ff;
        border: 1px solid #2791ff;
        color: #2791ff;
      }
    }
  }
}
.noneData {
  width: 100%;
  height: 700px;
  line-height: 700px;
  color: #909399;
  background-color: white;
  font-size: 14px;
  text-align: center;
}
.releaseText {
  margin: 0px 30px 30px 30px;
  .li {
    margin-bottom: 10px;
    display: flex;
    .title {
      color: #909399;
      font-size: 14px;
      width: 80px;
    }
    .about {
      color: #333333;
    }
  }
}

.proLine {
  margin: 10px 0;
  height: 1px;
  opacity: 1;
  background: #dbdbdb;
}
.data {
  text-align: center;
  color: #2791ff;
}
.data:hover {
  cursor: pointer;
}
.del {
  text-align: center;
  color: #e7541e;
}
.del:hover {
  cursor: pointer;
}
</style>

<template>
  <div class="setUpActivityInfo">
    <div class="title">
      <div class="span"></div>
      基本设置
    </div>
    <div class="cutLine" style="margin-bottom: 20px"></div>
    <!-- <el-form-item label="活动类型">{{
              templateInfo.market_template_category_name ||
              market_template_category_name
            }}</el-form-item> -->
    <el-form-item label="活动标题" prop="title">
      <el-input
        clearable
        v-model="activityInfo.title"
        autocomplete="off"
        maxlength="20"
        show-word-limit
        placeholder="请输入活动标题"
        style="width: 41.5%"
      ></el-input
    ></el-form-item>
    <el-form-item label="活动时间" prop="activity_time">
      <el-date-picker
        v-model="activityInfo.start_time"
        type="datetime"
        placeholder="开始时间"
        value-format="yyyy-MM-dd HH:mm:ss"
        :picker-options="pickerOptions"
        @change="handleStartTime"
      >
      </el-date-picker>
      -
      <el-date-picker
        v-model="activityInfo.end_time"
        type="datetime"
        placeholder="结束时间"
        value-format="yyyy-MM-dd HH:mm:ss"
        default-time="23:59:59"
        :picker-options="pickerOptions"
        @change="handleEndTime"
      >
      </el-date-picker
    ></el-form-item>
    <el-form-item label="核销时间" prop="verify_time">
      <el-date-picker
        v-model="activityInfo.confirm_start_time"
        type="datetime"
        placeholder="开始时间"
        value-format="yyyy-MM-dd HH:mm:ss"
        :picker-options="pickerOptions"
        @change="handleConfirmStartTime"
      >
      </el-date-picker>
      -
      <el-date-picker
        v-model="activityInfo.confirm_end_time"
        type="datetime"
        placeholder="结束时间"
        default-time="23:59:59"
        :picker-options="pickerOptions"
        value-format="yyyy-MM-dd HH:mm:ss"
        @change="handleConfirmEndTime"
      >
      </el-date-picker>
      <div style="color: #606266">
        <el-checkbox
          v-model="activityInfo.same_time_status"
          @change="handleActivityTime"
          :true-label="20"
          :false-label="10"
          style="margin-right: 5px"
        />与活动开始和结束时间一致
      </div>
    </el-form-item>
    <el-form-item label="活动展示时间" prop="activity_show_time">
      <el-date-picker
        v-model="activityInfo.show_start_time"
        type="datetime"
        placeholder="开始时间"
        value-format="yyyy-MM-dd HH:mm:ss"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
      -
      <el-date-picker
        v-model="activityInfo.show_end_time"
        type="datetime"
        placeholder="结束时间"
        value-format="yyyy-MM-dd HH:mm:ss"
        default-time="23:59:59"
        :picker-options="pickerOptions"
      >
      </el-date-picker
    ></el-form-item>
    <el-form-item label="核销密码" prop="verif_password">
      <el-input
        clearable
        v-model="activityInfo.verif_password"
        autocomplete="off"
        maxlength="4"
        show-word-limit
        placeholder="请输入核销密码"
        style="width: 41.5%"
      ></el-input>
      <el-tooltip
        class="item"
        effect="dark"
        content="设置核销密码后，顾客的核销页面输入核销密码即可进行礼品的核销"
        placement="top"
      >
        <div
          class="el-icon-info"
          style="margin-left: 5px; color: #409eff"
        ></div>
      </el-tooltip>
    </el-form-item>
    <el-form-item label="会员体系" prop="client_system_id">
      <el-select
        v-model="activityInfo.client_system_id"
        placeholder="请选择会员体系"
        style="width: 41.5%"
        @change="getClientSystemHierarchy"
        :disabled="activityStatus === 30 ? true : false"
      >
        <template v-for="item in systemList">
          <el-option
            :label="item.title"
            :value="item.client_system_id"
            :key="item.client_system_id"
          ></el-option>
        </template> </el-select
    ></el-form-item>
    <el-form-item
      label="参与门店"
      required
      v-if="storeList.length !== 0"
    >
      <el-checkbox
        v-model="checkAll"
        :indeterminate="isIndeterminate"
        @change="handleCheckAllChange"
        :disabled="activityStatus === 30 ? true : false"
        >全选</el-checkbox
      >
      <div style="margin: 15px 0"></div>
      <el-checkbox-group
        v-model="storeArr"
        @change="handleCheckedHierarchyChange"
        :disabled="activityStatus === 30 ? true : false"
      >
        <el-checkbox
          v-for="store in storeList"
          name="type"
          :label="String(store.hierarchy_id)"
          :key="String(store.hierarchy_id)"
          >{{ store.name }}</el-checkbox
        >
      </el-checkbox-group>
    </el-form-item>
    <div class="title">
      <div class="span"></div>
      页面设置
    </div>
    <div class="cutLine" style="margin-bottom: 20px"></div>
    <el-form-item label="页面头图">
      <div style="display: flex">
        <el-upload
          class="avatar-uploader"
          action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
          :data="{
            directory: 'AGENT_INFO',
          }"
          :headers="{
            authorization: token,
          }"
          :show-file-list="false"
          :on-success="handleHeadImageSuccess"
        >
          <img v-if="head_image" :src="head_image" class="avatarOther" />
          <i
            v-else
            class="el-icon-plus avatar-uploader-icon"
            style="background-color: white"
          ></i>
        </el-upload>
        <el-tooltip
          effect="dark"
          content="活动主图，格式为jpg、png、gif,推荐尺寸为750*750px，图片大小不超过500KB"
          placement="top"
        >
          <div
            class="el-icon-info"
            style="margin: 30px 0 0 5px; color: #409eff"
          ></div>
        </el-tooltip>
      </div>
    </el-form-item>
    <el-form-item
      label="头部弹幕"
      style="margin-top: -20px"
      v-if="
        activityInfo.market_template_category_id === 2 ||
        activityInfo.market_template_category_id === 3 ||
        activityInfo.market_template_category_id === 5 ||
        activityInfo.market_template_category_id === 6 ||
        activityInfo.market_template_category_id === 7
      "
      ><el-switch
        v-model="activityInfo.is_barrage"
        :active-value="10"
        :inactive-value="20" />
      <el-tooltip
        class="item"
        effect="dark"
        content="页面顶部将会不断显示用户的购买记录，去激励其他用户下单"
        placement="top"
      >
        <div
          class="el-icon-info"
          style="margin: 0px 0 0 5px; color: #409eff"
        ></div> </el-tooltip
    ></el-form-item>
    <el-form-item
      label="背景颜色"
      v-if="
        activityInfo.market_template_category_id === 5 ||
        activityInfo.market_template_category_id === 3 ||
        activityInfo.market_template_category_id === 2 ||
        activityInfo.market_template_category_id === 6 ||
        activityInfo.market_template_category_id === 7
      "
    >
      <div style="display: flex">
        <el-color-picker
          v-model="activityInfo.background_color"
        ></el-color-picker>
        <div class="resetBackColor" @click="handleResetColor">重置</div>
      </div>
    </el-form-item>
    <el-form-item label="背景音乐">
      <el-button
        type="primary"
        size="small"
        plain
        @click="() => (backMusicVisiable = true)"
        >选择音乐</el-button
      >
      <span style="color: #5493f5; margin-left: 15px"
        >{{ music_name }}
        <div
          style="color: #606266"
          class="el-icon-circle-close"
          @click="handleResetMusic"
          v-if="music_name"
        ></div
      ></span>
    </el-form-item>
    <el-form-item
      label="页面动效"
      v-if="
        activityInfo.market_template_category_id === 2 ||
        activityInfo.market_template_category_id === 3 ||
        activityInfo.market_template_category_id === 5 ||
        activityInfo.market_template_category_id === 6 ||
        activityInfo.market_template_category_id === 7
      "
    >
      <el-button
        type="primary"
        size="small"
        plain
        @click="() => (pageEffectsVisiable = true)"
        >选择动效</el-button
      >
      <span style="color: #5493f5; margin-left: 15px"
        >{{ pageEffectsName }}
        <div
          style="color: #606266"
          class="el-icon-circle-close"
          @click="handleResetEffectsName"
          v-if="pageEffectsName"
        ></div
      ></span>
    </el-form-item>
    <el-form-item label="活动详情">
      <div id="editor" class="editor"></div>
    </el-form-item>
    <div v-if="activityInfo.market_template_category_id === 5">
      <div class="title">
        <div class="span"></div>
        抽奖设置
      </div>
      <div class="cutLine" style="margin-bottom: 20px"></div>
    </div>
    <el-form-item
      label="抽奖类型"
      prop="is_gamble"
      v-if="activityInfo.market_template_category_id === 5"
    >
      <el-radio-group v-model="activityInfo.is_gamble">
        <el-radio label="10">免费抽奖</el-radio>
        <el-radio label="20">付费抽奖</el-radio>
      </el-radio-group>
      <div v-if="activityInfo.is_gamble && activityInfo.is_gamble === '10'">
        <div style="margin: 10px 0 10px 0; display: flex">
          <el-form-item prop="get_draw_opportunities_way">
            <el-select
              v-model="activityInfo.get_draw_opportunities_way"
              placeholder="获得抽奖机会方式"
              style="width: 180px"
            >
              <el-option value="20" label="总抽奖次数"></el-option>
              <el-option value="10" label="每日抽奖次数"></el-option>
            </el-select>
          </el-form-item>
          <div
            v-if="
              activityInfo.get_draw_opportunities_way &&
              activityInfo.get_draw_opportunities_way === '10'
            "
          >
            <el-form-item prop="day_join_number">
              <el-input
                style="margin: 0 10px; width: 100px"
                v-model="activityInfo.day_join_number"
                placeholder="每日抽奖次数"
              />次
            </el-form-item>
          </div>
          <div
            v-if="
              activityInfo.get_draw_opportunities_way &&
              activityInfo.get_draw_opportunities_way === '20'
            "
          >
            <el-form-item prop="total_join_number">
              <el-input
                style="margin: 0 10px; width: 100px"
                v-model="activityInfo.total_join_number"
                placeholder="总抽奖次数"
              />次
            </el-form-item>
          </div>
        </div>
        <div style="margin: 18px 0x 0 0; color: #606266; font-size: 14px">
          分享后获得抽奖机会<el-switch
            style="margin-left: 8px"
            v-model="activityInfo.share_status"
            active-value="20"
            inactive-value="10"
          >
          </el-switch>
          <br />
          <el-radio-group
            v-if="
              activityInfo.share_status && activityInfo.share_status === '20'
            "
            v-model="activityInfo.share_way"
          >
            <el-radio label="10" style="margin-top: 5px"
              >每日分享增加
              <el-input
                style="width: 100px; margin: 0 10px"
                v-model="activityInfo.share_day_number"
              />次</el-radio
            >

            <br />
            <el-radio label="20" style="margin-top: 15px"
              >总分享次数<el-input
                style="width: 100px; margin: 0 10px"
                v-model="activityInfo.share_total_number"
              />次</el-radio
            >
          </el-radio-group>
        </div>
      </div>
      <div
        v-if="activityInfo.is_gamble && activityInfo.is_gamble === '20'"
        style="color: #606266"
      >
        <el-form-item prop="pay_draw">
          <div style="display: flex">
            支付
            <el-input
              style="width: 80px; margin: 0 8px"
              v-model="activityInfo.pay_draw"
            />
            元获得1次抽奖机会
          </div>
        </el-form-item>
        <div style="margin: 30px 0 20px 0; display: flex">
          <el-form-item prop="pay_limit_status">
            <el-select
              placeholder="购买限制"
              style="width: 180px"
              v-model="activityInfo.pay_limit_status"
            >
              <el-option value="10" label="限制"></el-option>
              <el-option value="20" label="不限"></el-option>
            </el-select>
          </el-form-item>
          <div
            v-if="
              activityInfo.pay_limit_status &&
              activityInfo.pay_limit_status === '10'
            "
          >
            <el-form-item prop="pay_limit">
              <el-input
                style="width: 80px; margin: 0 10px"
                v-model="activityInfo.pay_limit"
              />
              次
            </el-form-item>
          </div>
        </div>
        <div style="margin: 0px 0x 0 0; color: #606266; font-size: 14px">
          赠送免费抽奖机会<el-switch
            style="margin-left: 8px"
            v-model="activityInfo.free_draw_status"
            active-value="10"
            inactive-value="20"
          >
          </el-switch>
          <br />
          <el-radio-group
            v-model="activityInfo.get_draw_opportunities_way"
            v-if="
              activityInfo.free_draw_status &&
              activityInfo.free_draw_status === '10'
            "
          >
            <el-radio label="10" style="margin-top: 5px"
              >每日赠送
              <el-input
                style="width: 80px; margin: 0 10px"
                v-model="activityInfo.day_join_number"
              />次</el-radio
            >

            <br />
            <el-radio label="20" style="margin-top: 15px"
              >总赠送<el-input
                v-model="activityInfo.total_join_number"
                style="width: 80px; margin: 0 10px"
              />次</el-radio
            >
          </el-radio-group>
        </div>
        <div style="margin: 18px 0x 0 0; color: #606266; font-size: 14px">
          分享获得抽奖机会<el-switch
            style="margin-left: 8px"
            v-model="activityInfo.share_status"
            active-value="20"
            inactive-value="10"
          >
          </el-switch>
          <br />
          <el-radio-group
            v-if="
              activityInfo.share_status && activityInfo.share_status === '20'
            "
            v-model="activityInfo.share_way"
          >
            <el-radio label="10" style="margin-top: 5px"
              >每日分享增加
              <el-input
                style="width: 100px; margin: 0 10px"
                v-model="activityInfo.share_day_number"
              />次</el-radio
            >

            <br />
            <el-radio label="20" style="margin-top: 15px"
              >总分享次数<el-input
                style="width: 100px; margin: 0 10px"
                v-model="activityInfo.share_total_number"
              />次</el-radio
            >
          </el-radio-group>
        </div>
        <div style="margin: 10px 0x 0 0; color: #606266; font-size: 14px">
          设置返佣<el-switch
            style="margin-left: 8px"
            v-model="activityInfo.draw_rebate_status"
            active-value="10"
            inactive-value="20"
          >
          </el-switch>
          <br />
          <div
            style="display: flex"
            v-if="
              activityInfo.draw_rebate_status &&
              activityInfo.draw_rebate_status === '10'
            "
          >
            返佣
            <el-input
              style="width: 100px; margin: 0 10px"
              v-model="activityInfo.rebate_draw"
            />
            元
          </div>
        </div>
      </div>
    </el-form-item>
    <el-form-item
      label="总抽奖机会"
      prop="total_join_status"
      v-if="
        activityInfo.market_template_category_id === 1
      "
    >
      <el-radio-group
        v-model="activityInfo.total_join_status"
        :disabled="activityStatus === 30 ? true : false"
      >
        <div style="margin-top: 13px">
          <div>
            <el-radio :label="10">不限</el-radio>
          </div>
          <div style="margin-top: 24px">
            <el-radio :label="20"
              >限制<span
                v-if="
                  activityInfo.total_join_status &&
                  activityInfo.total_join_status === 20
                "
                ><span style="margin-left: 15px"
                  >每人最多有<el-input
                    v-model="activityInfo.total_join_number"
                    :disabled="activityStatus === 30 ? true : false"
                    style="width: 60px; height: 30px; margin: 0 10px"
                  />次抽奖机会</span
                ></span
              ></el-radio
            >
          </div>
        </div>
      </el-radio-group>
    </el-form-item>
    <el-form-item
      label="每日抽奖机会"
      prop="day_join_status"
      v-if="
        activityInfo.market_template_category_id === 1
      "
    >
      <el-radio-group
        v-model="activityInfo.day_join_status"
        :disabled="activityStatus === 30 ? true : false"
      >
        <div style="margin-top: 13px">
          <div>
            <el-radio :label="10">不限</el-radio>
          </div>
          <div style="margin-top: 18px">
            <el-radio :label="20"
              >限制<span
                v-if="
                  activityInfo.day_join_status &&
                  activityInfo.day_join_status === 20
                "
                ><span style="margin-left: 15px"
                  >每人每日有<el-input
                    v-model="activityInfo.day_join_number"
                    :disabled="activityStatus === 30 ? true : false"
                    style="width: 80px; margin: 0 10px"
                  />次抽奖机会</span
                ></span
              ></el-radio
            >
          </div>
        </div>
      </el-radio-group>
    </el-form-item>
    <!-- 投票新增 -->
    <template v-if="activityInfo.market_template_category_id === 7">
      <div class="title">
        <div class="span"></div>
        投票设置
      </div>
      <div class="cutLine" style="margin-bottom: 20px"></div>
      <el-form-item label="投票玩法" required prop="voting_game">
        <el-radio-group v-model="activityInfo.voting_game">
          <el-radio label="照片墙" value="1"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="报名人介绍"
        style="margin-top: -20px"
        ><el-switch
          v-model="activityInfo.apply_introduce"
          active-value="10"
          inactive-value="20" />
      </el-form-item>
      <el-form-item
        label="报名人宣言"
        style="margin-top: -20px"
        ><el-switch
          v-model="activityInfo.apply_declaration"
          active-value="10"
          inactive-value="20" />
      </el-form-item>
      <el-form-item label="报名时间" prop="apply_time" required="">
        <el-date-picker
          v-model="activityInfo.apply_start_time"
          type="datetime"
          placeholder="开始时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
          @change="handleChangeApplyTime"
        >
        </el-date-picker>
        -
        <el-date-picker
          v-model="activityInfo.apply_end_time"
          type="datetime"
          placeholder="结束时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          default-time="23:59:59"
          :picker-options="pickerOptions"
          @change="handleChangeApplyTime"
        >
        </el-date-picker
      ></el-form-item>
      <el-form-item label="赠送投票票数" required="" prop="voting_giving_way">
        <el-radio-group v-model="activityInfo.voting_giving_way">
          <el-radio label="10"
            >每人每天赠送
            <span
              ><el-input
                v-model="activityInfo.voting_giving_day"
                style="width: 80px; margin: 0 10px"
              />票
            </span>
          </el-radio>
          <el-radio label="20"
            >每人总赠送
            <span
              ><el-input
                v-model="activityInfo.voting_giving_total"
                style="width: 80px; margin: 0 10px"
              />票
            </span>
          </el-radio>
        </el-radio-group>
      </el-form-item>
        <el-form-item label="投票粉丝展示">
          <el-switch v-model="activityInfo.voting_show_fans" active-value="10" inactive-value="20" />
        </el-form-item>
        <el-form-item label="投票广告投放">
          <div style="display: flex">
            <el-upload
              class="avatar-uploader"
              action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
              :data="{
                directory: 'AGENT_INFO',
              }"
              :headers="{
                authorization: token,
              }"
              :show-file-list="false"
              :on-success="handleVotingAdvertsSuccess"
            >
              <img v-if="voting_adverts" :src="voting_adverts" class="avatarOther" />
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                style="background-color: white"
              ></i>
            </el-upload>
            <el-tooltip
              effect="dark"
              content="活动主图，格式为jpg、png、gif,推荐尺寸为750*750px，图片大小不超过500KB"
              placement="top"
            >
              <div
                class="el-icon-info"
                style="margin: 30px 0 0 5px; color: #409eff"
              ></div>
            </el-tooltip>
          </div>
        </el-form-item>
        <el-form-item label="分享获得投票票数">
          <el-switch v-model="activityInfo.voting_share_status" active-value="10" inactive-value="20" />
          <div v-if="activityInfo.voting_share_status == 10">
            <el-radio-group v-model="activityInfo.voting_share_way" >
              <el-radio label="10"
                >每日分享增加
                <span
                  ><el-input
                    v-model="activityInfo.vote_share_day_number"
                    style="width: 80px; margin: 0 10px"
                  />票
                </span>
              </el-radio>
              <el-radio label="20"
                >总分享增加
                <span
                  ><el-input
                    v-model="activityInfo.vote_share_total_number"
                    style="width: 80px; margin: 0 10px"
                  />票
                </span>
              </el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="上传公众号二维码">
      <div style="display: flex">
        <el-upload
          class="avatar-uploader"
          action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
          :data="{
            directory: 'AGENT_INFO',
          }"
          :headers="{
            authorization: token,
          }"
          :show-file-list="false"
          :on-success="handleQrImageSuccess"
        >
          <img v-if="qr" :src="qr" class="avatarOther" />
          <i
            v-else
            class="el-icon-plus avatar-uploader-icon"
            style="background-color: white"
          ></i>
        </el-upload>
        <el-tooltip
          effect="dark"
          content="建议上传公众号二维码，关注后可接受报名的消息推送"
          placement="top"
        >
          <div
            class="el-icon-info"
            style="margin: 30px 0 0 5px; color: #409eff"
          ></div>
        </el-tooltip>
      </div>
    </el-form-item>
      </template>
      <div class="title">
        <div class="span"></div>
        分享设置
      </div>
      <div class="cutLine" style="margin-bottom: 20px"></div>
      <el-form-item label="分享标题" prop="share_title">
        <el-input
          clearable
          v-model="activityInfo.share_title"
          autocomplete="off"
          maxlength="20"
          show-word-limit
          placeholder="请输入分享标题"
          style="width: 41.5%"
        ></el-input
      ></el-form-item>
      <el-form-item label="分享图片" required>
        <el-upload
          class="avatar-uploader"
          action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
          :data="{
            directory: 'AGENT_INFO',
          }"
          :headers="{
            authorization: token,
          }"
          :show-file-list="false"
          :on-success="handleShareImageSuccess"
        >
          <img v-if="share_image" :src="share_image" class="avatarOther" />
          <i
            v-else
            class="el-icon-plus avatar-uploader-icon"
            style="background-color: white"
          ></i> </el-upload
    ></el-form-item>
    <el-form-item label="分享内容" prop="share_content">
      <el-input
        clearable
        type="textarea"
        v-model="activityInfo.share_content"
        autocomplete="off"
        show-word-limit
        placeholder="请输入分享内容"
        style="width: 41.5%"
      ></el-input>
    </el-form-item>
    <el-form-item
      label="分享增加次数"
      prop="share_status"
      v-if="activityInfo.market_template_category_id === 1"
    >
      <el-radio-group v-model="activityInfo.share_status">
        <el-radio label="10">关闭</el-radio>
        <el-radio label="20"
          >开启<span
            v-if="
              activityInfo.share_status && activityInfo.share_status === '20'
            "
          >
            <span style="margin-left: 40px"
              >分享后增加<el-input
                v-model="activityInfo.share_add_number"
                style="width: 80px; margin: 0 10px"
              />次</span
            >
            <span style="margin-left: 40px"
              >每日可分享<el-input
                v-model="activityInfo.share_day_number"
                style="width: 80px; margin: 0 10px"
              />次</span
            ></span
          ></el-radio
        >
      </el-radio-group>
    </el-form-item>

    <!-- <el-form-item label="活动说明">
      <el-input
        clearable
        v-model="activityInfo.explain"
        autocomplete="off"
        maxlength="500"
        show-word-limit
        type="textarea"
        placeholder="请输入活动说明"
        style="width: 41.5%"
      ></el-input
    ></el-form-item>
    <el-form-item label="活动详情图">
      <el-upload
        class="avatar-uploader"
        action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
        :data="{
          directory: 'AGENT_INFO',
        }"
        :headers="{
          authorization: token,
        }"
        list-type="picture-card"
        multiple
        :file-list="images"
        :on-success="handleImagesSuccess"
        :on-remove="handleImagesFile"
      >
        <i
          class="el-icon-plus avatar-uploader-icon"
          style="background-color: white"
        ></i>
      </el-upload>
      <div style="color: #909399">
        图片格式为jpg或png格式，大小不能超过500KB
      </div>
    </el-form-item> -->

    <div style="display: flex">
      <div class="title" style="width: 90%">
        <div class="span"></div>
        高级设置
      </div>
      <div class="senior">
        <div
          class="putAway"
          v-if="openSeiorSetup === true"
          @click="
            () =>
              openSeiorSetup
                ? (openSeiorSetup = false)
                : (openSeiorSetup = true)
          "
        >
          <div class="jiao"></div>
          点击收起
        </div>
        <div
          class="open"
          v-if="openSeiorSetup === false"
          @click="
            () =>
              openSeiorSetup
                ? (openSeiorSetup = false)
                : (openSeiorSetup = true)
          "
        >
          <div class="jiao"></div>
          更多设置
        </div>
      </div>
    </div>
    <div class="cutLine" style="margin-bottom: 23px"></div>
    <div v-if="openSeiorSetup === true" style="transition: all 0.3s">
      <el-form-item
        label="每人总中奖次数"
        v-if="
          activityInfo.market_template_category_id === 1 ||
          activityInfo.market_template_category_id === 5
        "
      >
        <el-radio-group
          v-model="activityInfo.total_lottery_status"
          :disabled="activityStatus === 30 ? true : false"
        >
          <div>
            <el-radio :label="10">不限</el-radio>
            <el-radio :label="20"
              >限制
              <span
                v-if="
                  activityInfo.total_lottery_status &&
                  activityInfo.total_lottery_status === 20
                "
              >
                <span style="margin-left: 15px"
                  >每人中奖次数<el-input
                    v-model="activityInfo.total_lottery_number"
                    :disabled="activityStatus === 30 ? true : false"
                    style="width: 80px; margin: 0 10px"
                  />次</span
                ><span
                  style="font-size: 14px; color: #ec561f; margin-left: 30px"
                  >注：设置后，最后一个奖项为“谢谢参与”</span
                ></span
              ></el-radio
            >
          </div>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="每日中奖次数"
        v-if="
          activityInfo.market_template_category_id === 1 ||
          activityInfo.market_template_category_id === 5
        "
      >
        <el-radio-group
          v-model="activityInfo.day_lottery_status"
          :disabled="activityStatus === 30 ? true : false"
        >
          <div>
            <el-radio :label="10">不限</el-radio>
            <el-radio :label="20"
              >限制<span
                v-if="
                  activityInfo.day_lottery_status &&
                  activityInfo.day_lottery_status === 20
                "
                ><span style="margin-left: 15px"
                  >每人每日最多可中奖<el-input
                    v-model="activityInfo.day_lottery_number"
                    :disabled="activityStatus === 30 ? true : false"
                    style="width: 80px; margin: 0 10px"
                  />次</span
                ><span
                  style="font-size: 14px; color: #ec561f; margin-left: 30px"
                  >注：设置后，最后个奖项为“谢谢参与”</span
                ></span
              ></el-radio
            >
          </div></el-radio-group
        >
      </el-form-item>
      <el-form-item
        v-if="
          activityInfo.market_template_category_id !== 6 &&
          activityInfo.market_template_category_id !== 7
        "
        :label="
          activityInfo.market_template_category_id === 1 ||
          activityInfo.market_template_category_id === 5
            ? '获奖者轮播'
            : '购买记录轮播'
        "
        prop="show_user"
      >
        <el-radio-group v-model="activityInfo.show_user">
          <el-radio :label="20">隐藏</el-radio>
          <el-radio :label="10">显示</el-radio>
        </el-radio-group></el-form-item
      >
      <el-form-item
        label="购买人数"
        v-if="
          activityInfo.market_template_category_id === 3 ||
          activityInfo.market_template_category_id === 5 ||
          activityInfo.market_template_category_id === 6 ||
          activityInfo.market_template_category_id === 7
        "
      >
        <el-radio-group v-model="activityInfo.show_buy">
          <el-radio :label="20">隐藏</el-radio>
          <el-radio :label="10"
            >显示
            <span v-if="activityInfo.show_buy && activityInfo.show_buy === 10"
              ><el-input
                v-model="activityInfo.virtual_buy"
                style="width: 80px; margin: 0 10px"
              />虚拟购买人数<span
                style="font-size: 14px; color: #909399; margin-left: 30px"
                >注：活动页面显示的购买人数=真实购买人数+虚拟购买人数</span
              ></span
            >
          </el-radio>
        </el-radio-group></el-form-item
      >
      <el-form-item label="浏览次数">
        <el-radio-group v-model="activityInfo.show_scan">
          <el-radio :label="20">隐藏</el-radio>
          <el-radio :label="10"
            >显示
            <span v-if="activityInfo.show_scan && activityInfo.show_scan === 10"
              ><el-input
                v-model="activityInfo.virtual_browsing_history"
                style="width: 80px; margin: 0 10px"
              />虚拟浏览次数<span
                style="font-size: 14px; color: #909399; margin-left: 30px"
                >注：活动页面显示的浏览次数=真实浏览次数+虚拟浏览次数</span
              ></span
            >
          </el-radio>
        </el-radio-group></el-form-item
      >
      <!-- 投票新增 -->
      <el-form-item label="累计票数" v-if="activityInfo.market_template_category_id === 7">
        <el-radio-group v-model="activityInfo.cumulative_votes">
          <el-radio label="20">隐藏</el-radio>
          <el-radio label="10">显示</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="参与人数" prop="show_join" v-if="activityInfo.market_template_category_id !== 6 && activityInfo.market_template_category_id !== 7">
        <el-radio-group v-model="activityInfo.show_join">
          <el-radio :label="20">隐藏</el-radio>
          <el-radio :label="10"
            >显示
            <span v-if="activityInfo.show_join && activityInfo.show_join === 10"
              ><el-input
                v-model="activityInfo.virtual_join"
                style="width: 80px; margin: 0 10px"
              />虚拟参与人数<span
                style="font-size: 14px; color: #909399; margin-left: 30px"
                >注：活动页面显示的参与人数=真实参与人数+虚拟参与人数</span
              ></span
            >
          </el-radio>
        </el-radio-group></el-form-item
      >
      <el-form-item label="分享次数" prop="show_share">
        <el-radio-group v-model="activityInfo.show_share">
          <el-radio :label="20">隐藏</el-radio>
          <el-radio :label="10"
            >显示
            <span
              v-if="activityInfo.show_share && activityInfo.show_share === 10"
              ><el-input
                v-model="activityInfo.virtual_share"
                style="width: 80px; margin: 0 10px"
              />虚拟分享次数<span
                style="font-size: 14px; color: #909399; margin-left: 30px"
                >注：活动页面显示的分享次数=真实分享次数+虚拟分享次数</span
              ></span
            >
          </el-radio>
        </el-radio-group></el-form-item
      >
      <el-form-item label="倒计时" prop="show_countdown">
        <el-radio-group v-model="activityInfo.show_countdown">
          <el-radio :label="20">隐藏</el-radio>
          <el-radio :label="10">显示</el-radio>
        </el-radio-group></el-form-item
      >
      <el-form-item
        label="商品库存"
        prop="inventory_status"
        v-if="
          activityInfo.market_template_category_id === 2 ||
          activityInfo.market_template_category_id === 3 ||
          activityInfo.market_template_category_id === 5 ||
          activityInfo.market_template_category_id === 6 ||
          activityInfo.market_template_category_id === 7
        "
      >
        <el-radio-group v-model="activityInfo.inventory_status">
          <el-radio :label="20">隐藏</el-radio>
          <el-radio :label="10">显示</el-radio>
        </el-radio-group></el-form-item
      >
      <el-form-item
        label="商品销量"
        prop="show_goods_sales"
      >
        <el-radio-group v-model="activityInfo.show_goods_sales">
          <el-radio label="20">隐藏</el-radio>
          <el-radio label="10">显示</el-radio>
        </el-radio-group></el-form-item
      >
      <!-- 投票新增 -->
      <el-form-item label="邀请人">
        <el-radio-group v-model="activityInfo.show_inviter">
          <el-radio label="20">隐藏</el-radio>
          <el-radio label="10">显示</el-radio>
        </el-radio-group>
      </el-form-item>
    </div>
    <el-dialog
      title="选择背景音乐"
      :visible.sync="backMusicVisiable"
      width="30%"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-upload
        style="margin-top: -20px"
        :action="baseUrl + '/store/common.UploadFile/uploadOssMusic'"
        :headers="{
          authorization: token,
        }"
        :show-file-list="false"
        :on-success="handleBackMusicSuccess"
        :before-upload="beforeAvatarUpload"
        ><el-button type="primary" size="small" plain
          >上传音乐</el-button
        ></el-upload
      >
      <div class="chooseMusic">
        <div
          style="width: 100px"
          :style="musicType === 'default' ? 'color:#5493f5' : ''"
          @click="handleMusicType('default')"
        >
          默认音乐库
        </div>
        <div
          :style="musicType === 'my' ? 'color:#5493f5' : ''"
          @click="handleMusicType('my')"
        >
          我上传的
        </div>
      </div>

      <div v-if="musicType === 'default'" style="position: relative">
        <template v-for="(item, index) in backMusicList">
          <div
            class="otherBox"
            :key="index"
            @click.stop="handleChooseMusic(item)"
            :style="
              activityInfo.music === item.id
                ? 'border-color:#5493f5;background-color:#ecf5ff'
                : ''
            "
          >
            <el-checkbox
              :value="
                activityInfo.music && activityInfo.music === item.id
                  ? true
                  : false
              "
              style="margin-right: 8px"
            ></el-checkbox>
            {{ item.music_name }}
            <div
              style="float: right; color: #5493f5"
              class="audition"
              slot="reference"
              @click.stop="hanlleShowMusic(item)"
            >
              试听
            </div>
          </div>
        </template>
      </div>
      <div v-if="musicType === 'my'">
        <template v-for="(item, index) in myBackMusicList">
          <div
            class="otherBox"
            :key="index"
            @click.stop="handleChooseMusic(item)"
            :style="
              activityInfo.music === item.id
                ? 'border-color:#5493f5;background-color:#ecf5ff'
                : ''
            "
          >
            <el-checkbox
              :value="
                activityInfo.music && activityInfo.music === item.id
                  ? true
                  : false
              "
              style="margin-right: 8px"
            ></el-checkbox>
            {{ item.music_name }}
            <div
              style="float: right; color: #5493f5"
              class="audition"
              slot="reference"
              @click.stop="hanlleShowMusic(item)"
            >
              试听
            </div>
          </div>
        </template>
      </div>
    </el-dialog>
    <el-dialog
      title="页面动效"
      :visible.sync="pageEffectsVisiable"
      width="20%"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <template v-for="(item, index) in pageEffectList">
        <div
          class="otherBox"
          :key="index"
          @click="handleDynamic(item)"
          :style="
            activityInfo.dynamic_effect &&
            Number(activityInfo.dynamic_effect) === item.id
              ? 'border-color:#5493f5;background-color:#ecf5ff'
              : ''
          "
        >
          <el-checkbox
            :value="
              activityInfo.dynamic_effect &&
              Number(activityInfo.dynamic_effect) === item.id
                ? true
                : false
            "
            style="margin-right: 8px"
          ></el-checkbox
          >{{ item.name }}
        </div>
      </template>
    </el-dialog>
    <div
      v-if="music_url"
      style="
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
      "
      @click="
        () => {
          music_url = null;
        }
      "
    >
      <audio
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          right: 0;
          width: 300px;
          height: 50px;
        "
        autoplay
        controls="controls"
      >
        <source :src="music_url" type="audio/mpeg" />
      </audio>
    </div>
  </div>
</template>

<script>
import wangeditor from "wangeditor";
import { getClientSystemHierarchyForIDReq } from "@/api/marketing/coupon/index.js";
import {
  getMusic,
  createMyMusic,
  myMusicList,
} from "@/api/marketing/activity/index";
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          return time.getTime() < date;
        },
      },
      openSeiorSetup: false,
      checkAll: false,
      isIndeterminate: true,
      backMusicVisiable: false,
      storeList: [],
      musicType: "default",
      backMusicList: [],
      myBackMusicList: [],
      pageEffectsVisiable: false,
      pageEffectList: [
        {
          id: 1,
          name: "玫瑰花",
        },
        {
          id: 2,
          name: "玫瑰花瓣",
        },
        {
          id: 3,
          name: "红包",
        },
        {
          id: 4,
          name: "礼物",
        },
        {
          id: 5,
          name: "元宝",
        },
        {
          id: 6,
          name: "气球",
        },
      ],
      pageEffectsName: "",
      storeArr: [],
      baseUrl: "",
      music_url: null,
    };
  },
  props: {
    activityInfo: Object,
    activityStatus: Number,
    systemList: Array,
    token: String,
    voting_adverts: String,
    head_image: String,
    qr: String,
    share_image: String,
    images: Array,
    activityImage: Array,
    templateInfo: Object,
    joinHierarchyId: Array,
    music_name: String,
    speed: String,
    msg: String,
    activityDetail: Object,
    type: String,
  },
  created() {
    this.getBackMusic();
  },

  mounted() {
    this.getClientSystemHierarchy(this.activityInfo.client_system_id);
    const env = process.env.NODE_ENV;
    const host = window.location.host;
    if (env === "development") {
      this.baseUrl = "http://api.dev.etouch.vip";
    } else {
      if (host === "edianbao.test.etouch.vip") {
        this.baseUrl = "http://api.test.etouch.vip";
      } else if (host === "www.etouch.top") {
        this.baseUrl = "https://api.etouch.top";
      } else if (host === "edianbao.pretest.etouch.vip") {
        this.baseUrl = "https://api.pretest.etouch.vip";
      } else if (host === "edianbao.dev.etouch.vip") {
        this.baseUrl = "http://api.dev.etouch.vip";
      }
    }
    if (this.speed === "活动设置") {
      this.editor = new wangeditor("#editor");
      this.editor.config.uploadImgServer =
        "https://api.etouch.top/store/common.UploadFile/uploadOssImage";
      this.editor.config.uploadFileName = "file";
      this.editor.config.uploadImgParams = {
        directory: "AGENT_INFO",
      };
      this.editor.config.uploadImgHeaders = {
        authorization: this.token,
      };
      this.editor.config.uploadImgHooks = {
        customInsert: function (insertImgFn, result) {
          // result 即服务端返回的接口
          insertImgFn(result.data);
        },
      };
      this.editor.create();

      // if (this.activityDetail) {//改版后弃用，防止出问题，
      //     this.editor.txt.html(this.activityDetail.txt.html());
      // }
      this.$emit("update:activityDetail", this.editor);
      if (this.type === "edit") {
        this.editor.txt.html(JSON.parse(this.activityInfo.explain));
      }else{
        this.editor.txt.html("");
      }

    }
    this.storeArr = this.joinHierarchyId;
    this.pageEffectsName = {
      1: "玫瑰花",
      2: "玫瑰花瓣",
      3: "红包",
      4: "礼物",
      5: "元宝",
      6: "气球",
    }[Number(this.activityInfo.dynamic_effect)];
  },

  methods: {
    // 报名时间改变
    handleChangeApplyTime() {
       if (
          !this.activityInfo.apply_start_time ||
          !this.activityInfo.apply_end_time
        ) {
          this.activityInfo.apply_time = false;
        } else {
          this.activityInfo.apply_time = true;
        }
    },
    /*
     *  背景音乐
     */
    getBackMusic() {
      myMusicList().then((res) => {
        if (res.code === 1) {
          this.backMusicList = res.data.common_list;
        }
      });
    },
    handleMusicType(type) {
      this.musicType = type;
      if (type === "default") {
        this.getBackMusic();
      } else {
        this.handleMyMusicList();
      }
    },
    hanlleShowMusic(data) {
      this.music_url = data.music_url;
      console.log(this.music_url);
    },
    /*
     *  活动时间选择
     */
    handleStartTime() {
      if (
        this.activityInfo.same_time_status &&
        this.activityInfo.same_time_status === 20
      ) {
        this.activityInfo.confirm_start_time = this.activityInfo.start_time;
      }
    },
    handleEndTime() {
      if (
        this.activityInfo.same_time_status &&
        this.activityInfo.same_time_status === 20
      ) {
        this.activityInfo.confirm_end_time = this.activityInfo.end_time;
      }
    },
    handleConfirmStartTime() {
      if (
        this.activityInfo.confirm_start_time !== this.activityInfo.start_time
      ) {
        this.activityInfo.same_time_status = null;
      }
    },
    handleConfirmEndTime() {
      if (this.activityInfo.confirm_end_time !== this.activityInfo.end_time) {
        this.activityInfo.same_time_status = null;
      }
    },
    /*
     * 核销时间跟活动时间一致
     */
    handleActivityTime(val) {
      if (val === 20) {
        this.activityInfo.confirm_start_time = this.activityInfo.start_time;
        this.activityInfo.confirm_end_time = this.activityInfo.end_time;
        this.$set(this.activityInfo);
      } else {
        this.activityInfo.confirm_start_time = null;
        this.activityInfo.confirm_end_time = null;
        this.$set(this.activityInfo);
      }
    },
    getClientSystemHierarchy(event) {
      getClientSystemHierarchyForIDReq({ client_system_id: event }).then(
        (res) => {
          if (res.code === 1) {
            this.storeList = res.data;
          }
        }
      );
    },
    /*
     * 清空所选音乐
     */
    handleResetMusic() {
      this.activityInfo.music = "";
      this.$emit("update:music_name", "");
    },
    /*
     * 重置背景颜色
     */
    handleResetColor() {
      this.activityInfo.background_color =
        this.templateInfo.market_template_default_json.background_color;
    },
    // 投票广告上传
    handleVotingAdvertsSuccess(res, file) {
      this.$emit("update:voting_adverts", res.data);
    },

    // 公众号二维码上传
    handleQrImageSuccess(res, file) {
      this.$emit("update:qr", res.data);
    },
    /*
     *  头图上传
     */
    handleHeadImageSuccess(res, file) {
      this.$emit("update:head_image", res.data);
    },
    /*
     *  分享图片上传
     */
    handleShareImageSuccess(res, file) {
      this.$emit("update:share_image", res.data);
    },
    /*
     *  上传活动详情图
     */
    handleImagesSuccess(res, file) {
      this.images.push({
        url: res.data,
      });
      this.activityImage.push(res.data);
    },
    /*
     *  删除活动详情图片
     */
    handleImagesFile(res, file) {
      this.images = this.images.filter((item) => {
        return item.url !== res.url;
      });
      this.activityImage = this.activityImage.filter((item) => {
        return item !== res.url;
      });
    },
    beforeAvatarUpload(file) {
      const isMP3 = file.name.split(".")[1] === "mp3";
      if (!isMP3) {
        this.$message.error("上传音乐只能是 mp3 格式!");
      }
      return isMP3;
    },
    /*
     *  自定义上传背景音乐
     */
    handleBackMusicSuccess(res, file) {
      const name = file.name.split(".")[0];
      const data = {
        music_name: name,
        music_url: res.data.data,
      };
      createMyMusic(data).then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "上传音乐成功",
          });
          this.musicType = "my";
          this.handleMyMusicList();
        }
      });
    },
    /*
     *  个人上传音乐列表
     */
    handleMyMusicList() {
      myMusicList().then((res) => {
        if (res.code === 1) {
          this.myBackMusicList = res.data.my_list;
        }
      });
    },
    /*
     *  门店全选
     */
    handleCheckAllChange(val) {
      var hierarchy_id_Arr = [];
      this.storeList.forEach((item) => {
        hierarchy_id_Arr.push(String(item.hierarchy_id));
      });
      let arr = JSON.parse(JSON.stringify(this.joinHierarchyId));
      arr = val ? hierarchy_id_Arr : [];
      this.storeArr = arr;
      this.$emit("update:joinHierarchyId", arr);
      this.isIndeterminate = false;
    },
    handleCheckedHierarchyChange(val) {
      let checkedCount = val.length;
      this.checkAll = checkedCount === this.storeArr.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.storeArr.length;
      this.$emit("update:joinHierarchyId", val);
    },

    handleChooseMusic(item) {
      this.activityInfo.music = item.id;
      this.$emit("update:music_name", item.music_name);
      this.backMusicVisiable = false;
    },
    handleDynamic(item) {
      this.activityInfo.dynamic_effect = item.id;
      this.pageEffectsName = item.name;
      this.pageEffectsVisiable = false;
    },
    handleResetEffectsName() {
      this.activityInfo.dynamic_effect = "";
      this.pageEffectsName = "";
    },
  },
};
</script>

<style lang="less" scoped>
.resetBackColor {
  margin-left: 10px;
  color: #5493f5;
}
.resetBackColor:hover {
  cursor: pointer;
}
.title {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  font-weight: 600;

  .span {
    margin: auto 6px auto 0;
    width: 4px;
    height: 12px;
    background: #5493f5;
    border-radius: 2px;
  }
}
.cutLine {
  width: 100%;
  height: 1px;
  background: #e8e8e8;
}
.chooseMusic {
  display: flex;
  line-height: 40px;
}
.otherBox {
  margin-bottom: 10px;
  padding: 8px 15px;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
}
.chooseMusic:hover,
.otherBox:hover,
.open:hover {
  cursor: pointer;
}
.senior {
  display: flex;
  line-height: 50px;
  font-size: 14px;
  color: #5493f5;

  .putAway {
    display: flex;
    .jiao {
      margin: auto 7px auto 0;
      width: 0;
      height: 0;
      border-bottom: 11px solid #5493f5;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
  }
  .open {
    display: flex;
    .jiao {
      margin: auto 7px auto 0;
      width: 0;
      height: 0;
      border-top: 11px solid #5493f5;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
  }
}
.editor {
  width: 50%;
  min-height: 200px; //编辑框最小高度
  height: auto; //编辑框高度超过最小高度会根据内容高度自适应
}
/deep/ .w-e-toolbar {
  z-index: 1 !important;
}
/deep/ .w-e-text-container {
  z-index: 0 !important;
}
.el-icon-circle-close:hover {
  cursor: pointer;
}
.avatarOther {
  object-fit: contain;
}
</style>

import { post } from '@/utils/request';

// 获取核销门店
const getHierarchyList = (data) => post({
  url: '/store/market.market/getHierarchyList',
  data: data
});

// 活动列表
const getActivityList = (data) => post({
    url: '/store/market.Market/list',
    data: data
});

// 活动创建
const createActivity = (data) => post({
    url: '/store/market.Market/create',
    data: data
})

// 活动详情
const getActivityDetail = (data) => post({
    url: '/store/market.Market/detail',
    data: data
})

// 活动更新
const updateActivity = (data) => post({
    url: '/store/market.Market/update',
    data: data
})

// 活动状态更改
const updateStatus = (data) => post({
    url: '/store/market.Market/updateStatus',
    data: data
})

// 活动删除
const deleteActivity = (data) => post({
    url: '/store/market.Market/delete',
    data: data
})


// 背景音乐
const getMusic = (data) => post({
    url: '/store/common.PublicMethod/getMusic',
    data: data
})


// 营销活动-中奖名单
const prizeUserList = (data) => post({
    url: '/store/Activity.Activity/prizeUserList',
    data: data
})

// 开奖
const setPrize = (data) => post({
    url: '/store/ActivityTools.Vote/setPrize',
    data: data
})

// 标签列表
const getMarketTemplateLableList = (data) => {
    return post({
        url: '/store/common.Select/getMarketTemplateLableList',
        data: data
    })
}

// 模板类型列表
const getMarketTemplateTypeList = (data) => {
    return post({
        url: '/store/common.Select/getMarketTemplateTypeList',
        data: data
    })
}

// 模板分类列表
const getMarketTemplateCategoryList = (data) => {
    return post({
        url: '/store/common.Select/getMarketTemplateCategoryList',
        data: data
    })
}

// 模板列表
const getMarketTemplateList = (data) => {
    return post({
        url: '/store/market.MarketTemplate/list',
        data: data
    })
}

// 模板详情
const getMarketTemplateDetail = (data) => {
    return post({
        url: '/store/market.MarketTemplate/detail',
        data: data
    })
}

// 选择在库商品
const getGoodsByHierarchy = (data) => {
    return post({
        url: '/store/common.Select/getGoodsByHierarchy',
        data: data
    })
}

// 参与记录
const getJoinList = (data) => {
    return post({
        url: '/store/market.MarketUserData/list',
        data: data
    })
}

// 浏览记录
const getDrawnUserList = (data) => {
    return post({
        url: '/store/market.MarketUserScan/list',
        data: data
    })
}

// 中奖记录
const getUserList = (data) => {
    return post({
        url: '/store/market.MarketDrawnUser/list',
        data: data
    })
}

// 新的中奖记录
const getUserList2 = (data) => {
  return post({
      url: '/store/market.MarketDrawnUser/listV2',
      data: data,
      responseType: data.export_url ? 'blob' : null,
  })
}


// 核销记录
const getVerifList = (data) => {
    return post({
        url: '/store/market.MarketDrawnUser/verifList',
        data: data
    })
}

// 核销详情
const verifDetails = (data) => {
    return post({
        url: '/store/order.Authorize/detail',
        data: data
    })
}

// 核销
const checkVerificationCode = (data) => {
    return post({
        url: '/store/order.Order/checkVerificationCode',
        data: data
    })
}
// 门店列表
const getMerchantList = (data) => {
    return post({
        url: "/store/common.select/getMerchantList",
        data: data
    })
}

// 参与记录导出
const handleExportUserData = (data) => {
    return post({
        url: "/store/market.MarketUserData/export",
        responseType: 'blob',
        data: data
    })
}

//  核销列表导出
const handleExportDrawnUser = (data) => {
    return post({
        url: "/store/market.MarketDrawnUser/verifExport",
        responseType: 'blob',
        data: data
    })
}

//  中奖记录导出
const handleExportPirzeUser = (data) => {
    return post({
        url: "/store/market.MarketDrawnUser/export",
        responseType: 'blob',
        data: data
    })
}

// 返佣列表
const getRebateList = (data) => {
    return post({
        url: "/store/market.MarketRebate/list",
        data: data
    })
}

// 返佣详情
const getRebateDetail = (data) => {
    return post({
        url: "/store/market.MarketRebate/detail",
        data: data
    })
}

// 订单列表
const getOrderList = (data) => {
    return post({
        url: "/store/market.Order/list",
        data: data,
    })
}

// 新的订单列表
const getOrderList2 = (data) => {
  return post({
      url: "/store/market.Order/listV2",
      data: data,
      responseType: data.export_url ? 'blob' : null,
  })
}

// 订单导出
const exportOrder = (data) => {
    return post({
        url: "/store/market.Order/export",
        responseType: 'blob',
        data: data
    })
}

// 返佣列表导出
const exportRebate = (data) => {
    return post({
        url: "/store/market.MarketRebate/export",
        responseType: 'blob',
        data: data
    })
}


// 上传个人音乐
const createMyMusic = (data) => {
    return post({
        url: "/store/market.Music/create",
        data: data,
    })
}

//  个人音乐列表
const myMusicList = (data) => {
    return post({
        url: "/store/market.Music/list",
        data: data,
    })
}
export {
    getUserList2,
    getHierarchyList,
    getOrderList2,
    getActivityList,
    createActivity,
    updateActivity,
    deleteActivity,
    updateStatus,
    getActivityDetail,
    getMusic,
    prizeUserList,
    setPrize,
    getMarketTemplateLableList,
    getMarketTemplateTypeList,
    getMarketTemplateCategoryList,
    getMarketTemplateList,
    getMarketTemplateDetail,
    getGoodsByHierarchy,
    getJoinList,
    getDrawnUserList,
    getUserList,
    getVerifList,
    verifDetails,
    checkVerificationCode,
    getMerchantList,
    handleExportUserData,
    handleExportDrawnUser,
    handleExportPirzeUser,
    getRebateList,
    getRebateDetail,
    getOrderList,
    exportOrder,
    exportRebate,
    createMyMusic,
    myMusicList
}

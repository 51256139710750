<template>
  <div style="margin-left: 20px">
    <el-button
      type="primary"
      plain
      style="margin-bottom: 15px"
      @click="addPrize"
      class="el-icon-plus"
    >
      新增奖品</el-button
    >
    <template v-for="(prize, index) in activityInfo.prizeList">
      <div :key="index" style="display: flex">
        <div class="prizeBox">
          <div
            style="position: absolute; right: 5px; top: 5px"
            v-if="index !== 0"
          >
            <img
              class="delete"
              src="../../../../assets/images/activity/close.svg"
              style="width: 25px; height: 25px"
              @click="delPrize(index)"
            />
          </div>
          <div>
            <el-form-item
              style="margin-left: -170px !important"
              :prop="'prizeList.' + index + '.goods_pic'"
              :rules="{
                required: true,
                trigger: 'change',
                message: '请上传图片',
              }"
            >
              <div style="display: flex">
                <el-upload
                  style="margin-top: 15px"
                  class="avatar-uploader"
                  action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
                  :data="{
                    directory: 'AGENT_INFO',
                  }"
                  :headers="{
                    authorization: token,
                  }"
                  :show-file-list="false"
                  :on-success="
                    (response, file) =>
                      handleDetailsSuccess(response, file, index)
                  "
                >
                  <div>
                    <img
                      v-if="prize.goods_pic"
                      :src="prize.goods_pic"
                      class="avatarOther"
                    />
                    <i
                      v-else
                      class="el-icon-plus avatar-uploader-icon"
                      style="background-color: white"
                    ></i>
                  </div>
                </el-upload>
                <el-tooltip
                  effect="dark"
                  content="格式为jpg、png、gif,推荐尺寸为750*750px，图片大小不超过500KB"
                  placement="top"
                >
                  <div
                    class="el-icon-info"
                    style="margin: 45px 0 0 5px; color: #409eff"
                  ></div>
                </el-tooltip>
              </div>
              <div style="margin-left: 20px; font-weight: bold;" v-if="activityInfo.market_template_category_id == 7">第{{index + 1}}名</div>
            </el-form-item>
          </div>
          <div>
            <div style="display: flex; flex-wrap: wrap;">
              <el-form-item
                label-width="100px"
                label="奖品名称"
                :prop="'prizeList.' + index + '.goods_name'"
                :rules="{
                  required: true,
                  trigger: 'blur',
                  message: '请输入奖品名称',
                }"
              >
                <el-input
                  v-model="prize.goods_name"
                  style="width: 90%"
                  placeholder="请输入奖品名称"
                  :maxlength="20"
                  show-word-limit
                />
              </el-form-item>
              <el-form-item
                v-if="activityInfo.market_template_category_id == 7"
                label-width="100px"
                label="商品原价"
                :prop="'prizeList.' + index + '.original_price'"
              >
                <el-input
                  type="number"
                  v-model="prize.original_price"
                  style="width: 90%"
                  placeholder="请输入商品原价"
                /><span style="margin-left: 5px;">元</span>
              </el-form-item>
              <el-form-item
                v-if="activityInfo.market_template_category_id == 7"
                label-width="100px"
                label="获得人数"
                :prop="'prizeList.' + index + '.goods_count'"
              >
                <el-input
                  type="number"
                  v-model="prize.goods_count"
                  style="width: 90%"
                  placeholder="请输入获得人数"
                /><span style="margin-left: 5px;">个</span>
              </el-form-item>
              <el-form-item
                v-if="activityInfo.market_template_category_id != 7"
                label-width="100px"
                label="中奖概率"
                :prop="'prizeList.' + index + '.hit_odds'"
                :rules="{
                  required: true,
                  message: '请输入中奖概率',
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-model="prize.hit_odds"
                  style="width: 90%"
                  placeholder="请输入中奖概率"
                  @input="handleHit_odds"
                />
                %
                <div style="color: #909399; font-size: 12px" v-if="activityInfo.market_template_category_id != 7">
                  当前<span style="color: #fd563a"
                    >还有{{ (100 - hitSum).toFixed(2) }}%</span
                  >概率可以设置
                </div>
              </el-form-item>
            </div>
            <div style="margin-top: -30px" v-if="activityInfo.market_template_category_id != 7">
              <el-form-item
                label-width="100px"
                label="奖品库存"
                :prop="'prizeList.' + index + '.goods_count'"
                :rules="{
                  required: true,
                  message: '请输入奖品库存',
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-model="prize.goods_count"
                  style="width: 35%"
                  placeholder="请输入奖品库存"
                  :disabled="
                    activityStatus === 30 && prize.market_goods_id
                      ? true
                      : false
                  "
                  onkeyup="this.value=this.value.replace(/[^\d]/g,'');"
                />
                <!-- <span
                  style="margin-left: 10px; font-size: 14px; color: #606266"
                  v-if="activityStatus == 30 && prize.market_goods_id"
                  >追加数量：</span
                >
                <el-input
                  v-if="activityStatus == 30 && prize.market_goods_id"
                  placeholder="请输入商品数量"
                  v-model="prize.goods_count_new"
                  style="width: 30%"
                  onkeyup="this.value=this.value.replace(/[^\d]/g,'');"
                /> -->
                 <!-- 调整库存 -->
                  <template v-if="activityStatus == 30 && prize.market_goods_id">
                    <el-select v-model="prize.inventory_type" placeholder="请选择" style="width: 120px;">
                      <el-option label="增加库存" value="inc"></el-option>
                      <el-option label="减少库存" value="dec"></el-option>
                    </el-select>
                    <el-input style="margin-left: 10px; width: 150px;" v-model="prize.goods_count_change" onkeyup="this.value=this.value.replace(/[^\d]/g,'');" placeholder="请输入库存数量" />
                  </template>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="prizePlain" style="margin-left: 20px" v-if="activityInfo.market_template_category_id != 7">
          <div
            style="margin-top: 40px"
            class="top"
            v-if="index !== 0"
            @click="handleTopPrize(prize, index)"
          >
            <i class="el-icon-top" style="font-size: 32px"></i>
          </div>
          <div
            style="margin-top: 20px"
            class="bottom"
            v-if="index + 1 !== activityInfo.prizeList.length"
            :style="index === 0 ? 'margin-top:60px;' : null"
            @click="handleBottomPrize(prize, index)"
          >
            <i class="el-icon-bottom" style="font-size: 32px"></i>
          </div>
        </div>
      </div>
    </template>
    <!-- 谢谢参与 -->
    <div
      class="prizeBox"
      v-if="
        activityInfo.total_lottery_status === 20 ||
        activityInfo.day_lottery_status === 20
      "
    >
      <div>
        <el-form-item
          style="margin-left: -170px !important"
          :rules="{
            required: true,
            trigger: 'change',
            message: '请上传图片',
          }"
        >
          <div style="display: flex">
            <el-upload
              style="margin-top: 15px"
              class="avatar-uploader"
              action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
              :data="{
                directory: 'AGENT_INFO',
              }"
              :headers="{
                authorization: token,
              }"
              :show-file-list="false"
              :on-success="
                (response, file) => handleJoinSuccess(response, file)
              "
            >
              <div>
                <img
                  v-if="activityInfo.participateiInfo.goods_pic"
                  :src="activityInfo.participateiInfo.goods_pic"
                  class="avatarOther"
                />
                <i
                  v-else
                  class="el-icon-plus avatar-uploader-icon"
                  style="background-color: white"
                ></i>
              </div>
            </el-upload>
            <el-tooltip
              effect="dark"
              content="格式为jpg、png、gif,推荐尺寸为750*750px，图片大小不超过500KB"
              placement="top"
            >
              <div
                class="el-icon-info"
                style="margin: 45px 0 0 5px; color: #409eff"
              ></div>
            </el-tooltip>
          </div>
        </el-form-item>
      </div>
      <div>
        <el-form-item label-width="100px" label="中奖概率" prop="hit_odds">
          <el-input
            v-model="activityInfo.participateiInfo.hit_odds"
            style="width: 32%"
            placeholder="请输入中奖概率"
            @input="handleHit_odds"
          />
          %
          <div style="color: #909399; font-size: 12px; line-height: 20px">
            此项固定为谢谢参与，抽中提示未中奖，不出现在奖品列表中。请勿当成奖品配置，概率设置为0则为100%中奖模式
            当前<span style="color: #fd563a"
              >还有{{ (100 - hitSum).toFixed(2) }}%</span
            >概率可以设置
          </div>
        </el-form-item>
      </div>
    </div>
    <!-- 开启参与奖 只有投票有 -->
    <template v-if="activityInfo.market_template_category_id == 7">
      <div class="title">
        <div class="span"></div>
        <span>开启参与奖</span>
        <el-tooltip
          effect="dark"
          content="开启后，所有参与用户可以获得商品"
          placement="top"
        >
          <div
            class="el-icon-info"
            style="margin: 18px 0 0 5px; color: #409eff"
          ></div>
        </el-tooltip>
        <el-switch
          v-model="activityInfo.is_join_award"
          active-value="10"
          inactive-value="0"
          style="margin: 15px 0 0 5px"
        />
      </div>
      <div>
        <div class="prizeBox" v-if="activityInfo.is_join_award == 10">
          <el-form-item
            style="margin-left: -170px !important"
            :rules="{
              required: true,
              trigger: 'change',
              message: '请上传图片',
            }"
          >
            <div style="display: flex">
              <el-upload
                style="margin-top: 15px"
                class="avatar-uploader"
                action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
                :data="{
                  directory: 'AGENT_INFO',
                }"
                :headers="{
                  authorization: token,
                }"
                :show-file-list="false"
                :on-success="
                  (response, file) => handleJoinSuccess(response, file)
                "
              >
                <div>
                  <img
                    v-if="activityInfo.participateiInfo.goods_pic"
                    :src="activityInfo.participateiInfo.goods_pic"
                    class="avatarOther"
                  />
                  <i
                    v-else
                    class="el-icon-plus avatar-uploader-icon"
                    style="background-color: white"
                  ></i>
                </div>
              </el-upload>
              <el-tooltip
                effect="dark"
                content="格式为jpg、png、gif,推荐尺寸为750*750px，图片大小不超过500KB"
                placement="top"
              >
                <div
                  class="el-icon-info"
                  style="margin: 45px 0 0 5px; color: #409eff"
                ></div>
              </el-tooltip>
            </div>
            <div style="margin-left: 20px; font-weight: bold;">参与奖</div>
          </el-form-item>
          <div  style="display: flex; flex-wrap: wrap;">
            <el-form-item
              label-width="100px"
              label="奖品名称"
              :rules="{
                required: true,
                trigger: 'blur',
                message: '请输入奖品名称',
              }"
            >
              <el-input
                v-model="activityInfo.participateiInfo.goods_name"
                style="width: 90%"
                placeholder="请输入奖品名称"
                :maxlength="20"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label-width="100px"
              label="商品原价"
            >
              <el-input
                v-model="activityInfo.participateiInfo.original_price"
                style="width: 90%"
                placeholder="请输入商品原价"
                type="number"
              />
            </el-form-item>
            <el-form-item
              label-width="100px"
              label="商品库存"
            >
              <el-input
                v-model="activityInfo.participateiInfo.goods_count"
                style="width: 90%"
                placeholder="请输入商品库存"
                onkeyup="this.value=this.value.replace(/[^\d]/g,'');"
              />
            </el-form-item>
          </div>
        </div>
      </div>

    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {},
      hitSum: 0,
    };
  },
  props: {
    token: String,
    hit_odds_sum: Number,
    activityInfo: Object,
    type: String,
    activityStatus: Number,
  },
  mounted() {
    this.info = this.activityInfo;
    if (this.type === "add" && this.activityInfo.prizeList.length === 0) {
      if (
        this.activityInfo.prize_state &&
        this.activityInfo.prize_state === "10"
      ) {
        for (var i = 0; i < Number(this.activityInfo.prize_num) - 1; i++) {
          this.info.prizeList.push({
            type: 10,
            goods_name: null,
            goods_pic: null,
            goods_count: null,
            goods_count_json: [],
            hit_odds: null,
            original_price: null,
            sale_price: null,
            surplus_price: null,
            rebate_prize: null,
          });
        }
      } else {
        this.info.prizeList = [
          {
            type: 10,
            goods_name: null,
            goods_pic: null,
            goods_count: null,
            goods_count_json: [],
            hit_odds: null,
            original_price: null,
            sale_price: null,
            surplus_price: null,
            rebate_prize: null,
          },
        ];
      }
    }

    this.hitSum = this.hit_odds_sum;
  },
  methods: {
    handleJoinSuccess(res, file) {
      if (res.code === 1) {
        this.activityInfo.participateiInfo.goods_pic = res.data;
        this.$emit("update:activityInfo", this.activityInfo);
      } else {
        this.$message({
          type: "error",
          message: res.msg,
        });
      }
    },
    handleDetailsSuccess(res, file, i) {
      if (res.code === 1) {
        this.info.prizeList.map((item, index) => {
          if (index === i) {
            item.goods_pic = res.data;
          }
          return item;
        });

        this.$emit("update:activityInfo", this.info);
      } else {
        this.$message({
          type: "error",
          message: res.msg,
        });
      }
    },
    /*
     *  新增奖品
     */
    addPrize() {
      if (this.info.prize_num) {
        if (
          this.info.prizeList.length + 1 === Number(this.info.prize_num) &&
          (this.info.total_lottery_status === 20 ||
            this.info.day_lottery_status === 20)
        ) {
          this.$message({
            type: "error",
            message: "奖品已达最大添加数量",
          });
        } else if (this.info.prizeList.length === Number(this.info.prize_num)) {
          this.$message({
            type: "error",
            message: "奖品已达最大添加数量",
          });
        } else {
          this.info.prizeList.push({
            type: 10,
            goods_name: null,
            goods_pic: null,
            goods_count: null,
            goods_count_json: [],
            hit_odds: null,
            original_price: null,
            sale_price: null,
            surplus_price: null,
            rebate_prize: null,
          });
        }
      } else {
        this.info.prizeList.push({
          type: 10,
          goods_name: null,
          goods_pic: null,
          goods_count: null,
          goods_count_json: [],
          hit_odds: null,
          original_price: null,
          sale_price: null,
          surplus_price: null,
          rebate_prize: null,
        });
      }
    },
    /*
     *  删除奖品
     */
    delPrize(i) {
      this.$confirm("是否确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.info.prizeList = this.info.prizeList.filter(
          (item, index) => index !== i
        );
        this.handleHit_odds();
        this.$emit("update:activityInfo", this.info);
      });
    },
    /*
     *  中奖概率计算
     */
    handleHit_odds() {
      this.hitSum = 0;
      if (
        this.activityInfo.total_lottery_status === 20 ||
        this.activityInfo.day_lottery_status === 20
      ) {
        this.hitSum = Number(this.activityInfo.participateiInfo.hit_odds);
      }
      this.info.prizeList.forEach((item) => {
        console.log(item.hit_odds);
        this.hitSum += Number(item.hit_odds);
      });
      this.$emit("update:hit_odds_sum", this.hitSum);
    },
    /*
     * 升序
     */
    handleTopPrize(prize, index) {
      let list = this.info.prizeList;
      this.handleSwapItems(list, index, index - 1);
      this.$emit("update:activityInfo", this.info);
    },
    /*
     *  降序
     */
    handleBottomPrize(prize, index) {
      let list = this.info.prizeList;
      this.handleSwapItems(list, index, index + 1);
      this.$emit("update:activityInfo", this.info);
    },
    handleSwapItems(arr, indexOne, indexTwo) {
      arr[indexOne] = arr.splice(indexTwo, 1, arr[indexOne])[0];
      return arr;
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
  flex-direction: row;
  // padding-left: 20px;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  font-weight: 600;

  .span {
    margin: auto 6px auto 0;
    width: 4px;
    height: 12px;
    background: #5493f5;
    border-radius: 2px;
  }
}
.prizeBox {
  position: relative;
  margin-bottom: 10px;
  padding: 15px 15px 0 15px;
  display: flex;
  width: 60%;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
.delete:hover,
.prizePlain:hover {
  cursor: pointer;
}

.prizePlain .top:hover i {
  color: #5cb6ff;
}
.prizePlain .bottom:hover i {
  color: #5cb6ff;
}
</style>

import { post } from '@/utils/request';

// 获取退库单列表
const getList = (data) => post({
    url: '/store/goods.handling.DesStock/list',
    data: data
});

// 创建退库单
const create = (data) => post({
    url: '/store/goods.handling.DesStock/create',
    data: data
})

// 退库单商品详情
const details = (data) => post({
    url: '/store/goods.handling.DesStock/details',
    data: data
})

// 退库单基本信息详情
const getInfo = (data) => post({
    url: '/store/goods.handling.DesStock/getInfo',
    data: data
})

// 退库日志
const canLog = (data) => post({
    url: '/store/goods.handling.DesStock/canLog',
    data: data
})

// 退库审核
const review = (data) => post({
    url: '/store/goods.handling.DesStock/review',
    data: data
})

// 编辑退库单
const update = (data) => post({
    url: '/store/goods.handling.DesStock/update',
    data: data
})

// 模式切换
const switchCaneList = (data) => post({
    url: '/store/goods.handling.DesStock/switchCaneList',
    data: data
})

// 退库单打印数据
const getDesSockPrintReq = (data) => post({
    url: '/store/goods.handling.DesStock/desSockPrint',
    data: data
})

// 删除退库单
const cancelDel = (data) => post({
    url: '/store/goods.handling.DesStock/cancelDel',
    data: data
})

// 退库单反确认编辑
const canceledUpdate = (data) => post({
    url: '/store/goods.handling.DesStock/canceledUpdate',
    data: data
})

// 退库单反确认删除
const handelCanceled = (data) => post({
    url: '/store/goods.handling.DesStock/handelCanceled',
    data: data
})

// 退库详情表头 
const detailIndex = (data) => post({
    url: '/store/common.Column/detailIndex',
    data: data
})

// 详情表头重置
const detailRestore = (data) => post({
    url: '/store/common.Column/detailRestore',
    data: data
})

// 详情表头修改
const detailSet = (data) => post({
    url: '/store/common.Column/detailSet',
    data: data
})


export {
    getDesSockPrintReq,
    getList,
    create,
    details,
    canLog,
    review,
    getInfo,
    update,
    switchCaneList,
    canceledUpdate,
    handelCanceled,
    cancelDel,
    detailIndex,
    detailRestore,
    detailSet
}

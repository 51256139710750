<template>
  <div>
    <div class="card">
      <el-page-header @back="goBack" content="活动数据"></el-page-header>
    </div>
    <div class="content">
      <div class="toolTitle">
        <div
          class="box"
          v-for="(item, index) in activityInfo.market_template_category_id === 1
            ? tabs1
            : activityInfo.market_template_category_id === 3 ||
              activityInfo.market_template_category_id === 2
            ? tabs2
            : tabs3"
          :key="index"
          :style="selectTool === item ? 'color: #2791FF' : ''"
          @click="changeTool(item)"
        >
          {{ item }}
          <div class="span" v-if="selectTool === item"></div>
        </div>
      </div>
    </div>
    <div class="activityData" v-if="selectTool === '活动详情'">
      <div class="content" style="padding-bottom: 15px">
        <div class="activityTittle">
          <div class="span"></div>
          活动信息
        </div>
        <div class="activityMain">
          <div
            class="templateImg"
            :style="
              'background-image: url(' +
              activityInfo.market_template_image +
              ')'
            "
          ></div>
          <div class="activityInfo">
            <div class="activityLine">
              <div class="activityInfoTitle">添加人</div>
              <div class="content">
                {{ activityInfo.store_user_name }}
                {{ activityInfo.create_time }}
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">活动类型</div>
              <div class="content">
                {{ activityInfo.market_template_category_name }}
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">活动标题</div>
              <div class="content">{{ activityInfo.title }}</div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">核销密码</div>
              <div class="content">{{ activityInfo.verif_password }}</div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">头图</div>
              <div class="content">
                <img
                  :src="activityInfo.head_image || noPic"
                  style="width: 70px; height: 70px; border-radius: 7px; object-fit: contain;"
                />
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">活动时间</div>
              <div class="content">
                {{ activityInfo.start_time }} - {{ activityInfo.end_time }}
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">核销时间</div>
              <div class="content">
                {{ activityInfo.confirm_start_time }} -
                {{ activityInfo.confirm_end_time }}
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">体系名称</div>
              <div class="content">{{ activityInfo.client_system_name }}</div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">适用门店</div>
              <div class="content">
                <span
                  v-for="(item, index) in activityInfo.join_hierarchy_name"
                  :key="index"
                  >{{ item }}&nbsp;，</span
                >
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">商品是否返佣</div>
              <div class="content">
                {{
                  market_json && market_json.rebate_status === "20"
                    ? "开启"
                    : "关闭"
                }}
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">头部弹幕</div>
              <div class="content">
                {{ market_json.market_json === 10 ? "开启" : "关闭" }}
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">背景颜色</div>
              <div class="content">
                <el-color-picker
                  v-model="style_json.background_color"
                  :disabled="true"
                  size="small"
                ></el-color-picker>
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">背景音乐</div>
              <div class="content">{{ activityInfo.music_name }}</div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">页面动效</div>
              <div class="content">
                {{
                  {
                    1: "玫瑰花",
                    2: "玫瑰花瓣",
                    3: "红包",
                    4: "礼物",
                    5: "元宝",
                    6: "气球",
                  }[Number(this.style_json.dynamic_effect)]
                }}
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">活动说明</div>
              <div
                class="content"
                v-html="activityInfo.explain"
                style="width: 100px"
              ></div>
            </div>
          </div>
          <div class="activityInfo">
             <div v-if="market_json.is_gamble&&activityInfo.market_template_category_id === 5">
            <div class="activityLine">
              <div class="activityInfoTitle">抽奖类型</div>
              <div class="content">
                {{ market_json.is_gamble === "10" ? "免费抽奖" : "付费抽奖" }}
              </div>
            </div>
            <div v-if="market_json.is_gamble === '10'">
              <div class="activityLine">
                <div class="activityInfoTitle">获得抽奖机会方式</div>
                <div class="content">
                  {{
                    market_json.get_draw_opportunities_way === "10"
                      ? "每日抽奖次数" + market_json.day_join_number + "次"
                      : "总抽奖次数" + market_json.total_join_number + "次"
                  }}
                </div>
              </div>
              <div class="activityLine">
                <div class="activityInfoTitle">分享后获得抽奖机会</div>
                <div class="content">
                  {{
                    market_json.share_status === "20"
                      ? market_json.share_way === "10"
                        ? "每日分享增加" + market_json.share_day_number + "次"
                        : "总分享次数" + market_json.share_total_number + "次"
                      : "关闭"
                  }}
                </div>
              </div>
            </div>
            <div v-if="market_json.is_gamble === '20'">
              <div class="activityLine">
                <div class="activityInfoTitle">抽奖支付金额</div>
                <div class="content">{{ market_json.pay_draw }}元</div>
              </div>
              <div class="activityLine">
                <div class="activityInfoTitle">购买限制</div>
                <div class="content">
                  {{
                    market_json.pay_limit_status === "10"
                      ? "限制"
                      : market_json.pay_limit + "次"
                  }}
                </div>
              </div>
              <div class="activityLine">
                <div class="activityInfoTitle">赠送免费抽奖机会</div>
                <div class="content">
                  {{
                    market_json.free_draw_status === "10"
                      ? market_json.day_join_number !== "0"
                        ? "每日赠送" + market_json.day_join_number + "次"
                        : "总赠送" + market_json.total_join_number + "次"
                      : "关闭"
                  }}
                </div>
              </div>
              <div class="activityLine">
                <div class="activityInfoTitle">分享获得抽奖机会</div>
                <div class="content">
                  {{
                    market_json.share_status === "20"
                      ? market_json.share_way === "10"
                        ? "每日分享增加" + market_json.share_day_number + "次"
                        : "总分享次数" + market_json.share_total_number + "次"
                      : "关闭"
                  }}
                </div>
              </div>
              <div class="activityLine">
                <div class="activityInfoTitle">设置返佣</div>
                <div class="content">
                  {{
                    market_json.draw_rebate_status === "10"
                      ? " 返佣" + market_json.rebate_draw + "元"
                      : "关闭"
                  }}
                  </div>

                </div>
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">分享标题</div>
              <div class="content">{{ activityInfo.share_title }}</div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">分享图片</div>
              <div class="content">
                <img
                  :src="activityInfo.share_image"
                  style="width: 70px; height: 70px; border-radius: 7px"
                />
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">分享内容</div>
              <div class="content">{{ activityInfo.share_content }}</div>
            </div>
            <div
              class="activityLine"
              v-if="
                activityInfo.market_template_category_id === 1 ||
                activityInfo.market_template_category_id === 5
              "
            >
              <div class="activityInfoTitle">每人总中奖次数</div>
              <div class="content">
                {{
                  market_json.total_lottery_number === "0"
                    ? "无限"
                    : "每人最多有" +
                      market_json.total_lottery_number +
                      "次抽奖机会"
                }}
              </div>
            </div>
            <div
              class="activityLine"
              v-if="
                activityInfo.market_template_category_id === 1 ||
                activityInfo.market_template_category_id === 5
              "
            >
              <div class="activityInfoTitle">每日中奖次数</div>
              <div class="content">
                {{
                  market_json.day_lottery_number === "0"
                    ? "无限"
                    : "每人每日最多可中奖" +
                      market_json.day_lottery_number +
                      "次"
                }}
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">获奖者轮播</div>
              <div class="content">
                {{ activityInfo.show_user === 10 ? "显示" : "隐藏" }}
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">浏览次数</div>
              <div class="content">
                {{
                  activityInfo.show_scan === 10
                    ? "显示" +
                      "  虚拟浏览次数：" +
                      activityInfo.virtual_browsing_history
                    : "隐藏"
                }}
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">购买人数</div>
              <div class="content">
                {{
                  activityInfo.show_buy === 10
                    ? "显示" + "  虚拟购买人数：" + activityInfo.virtual_buy
                    : "隐藏"
                }}
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">分享次数</div>
              <div class="content">
                {{
                  activityInfo.show_share === 10
                    ? "显示" + "  虚拟购买人数：" + activityInfo.virtual_share
                    : "隐藏"
                }}
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">参与人数</div>
              <div class="content">
                {{
                  activityInfo.show_join === 10
                    ? "显示" + "  虚拟参与人数：" + activityInfo.virtual_join
                    : "隐藏"
                }}
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">活动倒计时</div>
              <div class="content">
                {{ activityInfo.show_countdown === 10 ? "显示" : "隐藏" }}
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">商品库存</div>
              <div class="content">
                {{
                  market_json && market_json.inventory_status === "10"
                    ? "显示"
                    : "隐藏"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content" style="margin-top: 10px">
        <div class="activityTittle">
          <div class="span"></div>
          库存信息
        </div>
        <!-- <div
          style="padding-left: 30px; font-weight: 600; margin-bottom: 15px"
          v-if="
            activityInfo.market_template_category_id === 1 ||
            activityInfo.market_template_category_id === 5
          "
        >
          奖品
        </div> -->
        <div
          v-if="prizeList.length"
          style="padding-left: 30px; font-weight: 600; margin-bottom: 15px"
        >
          奖品
        </div>
        <div
          class="prizeList"
          v-if="prizeList.length
          "
        >
          <div class="prizeBox" v-for="(item, index) in prizeList" :key="index">
            <div class="prizeImg">
              <img :src="item.goods_pic" />
            </div>
            <div class="prizeContent">
              <div class="prizeName">
                {{ item.goods_name }}
              </div>
              <div style="margin-top: 3px">
                共{{ item.goods_count_total }}件 / 剩余{{ item.goods_count }}件
              </div>
              <div style="margin-top: 3px">概率{{ item.hit_odds }}</div>
              <div
                style="display: flex"
                v-if="activityInfo.market_template_category_id === 2"
              >
                <div style="margin-top: 3px; margin-right: 20px">
                  <span style="color: #808080">原价</span>￥{{
                    item.original_price
                  }}
                </div>
                <div
                  style="margin-top: 3px"
                  v-if="activityInfo.market_template_category_id === 2"
                >
                  <span style="color: #808080">预存金额</span>￥{{
                    item.sale_price
                  }}
                </div>
              </div>
              <div
                style="display: flex"
                v-if="activityInfo.market_template_category_id === 2"
              >
                <div
                  style="margin-top: 3px; margin-right: 20px"
                  v-if="activityInfo.market_template_category_id === 2"
                >
                  <span style="color: #808080">到店付</span>￥{{
                    item.surplus_price
                  }}
                </div>
                <div
                  style="margin-top: 3px"
                  v-if="activityInfo.market_template_category_id === 2"
                >
                  <span style="color: #808080">返佣金额</span>￥{{
                    item.sale_price
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="prizeList"
          v-if="
            activityInfo.market_template_category_id === 2 ||
            activityInfo.market_template_category_id === 3
          "
        > -->
        <div
          style="padding-left: 30px; font-weight: 600; margin-bottom: 15px"
          v-if="goodsList.length"
        >
          商品
        </div>
        <div
          class="prizeList"
          v-if="goodsList.length"
        >
          <div class="prizeBox" v-for="(item, index) in goodsList" :key="index">
            <div class="prizeImg">
              <img :src="item.goods_pic" />
            </div>
            <div class="prizeContent">
              <div class="prizeName">
                {{ item.goods_name }}
              </div>
              <div style="margin-top: 3px">
                共{{ item.goods_count_total }}件 / 剩余{{ item.goods_count }}件
              </div>
              <div style="display: flex">
                <div style="margin-top: 3px; margin-right: 20px">
                  <span style="color: #808080">原价</span>￥{{
                    item.original_price
                  }}
                </div>
                <div
                  style="margin-top: 3px"
                  v-if="activityInfo.market_template_category_id === 3"
                >
                  <span style="color: #808080">活动售价</span>￥{{
                    item.sale_price
                  }}
                </div>
                <div
                  style="margin-top: 3px"
                  v-if="(activityInfo.market_template_category_id === 2) || (activityInfo.market_template_category_id === 5)"
                >
                  <span style="color: #808080">预存金额</span>￥{{
                    item.sale_price
                  }}
                </div>
              </div>
              <div
                style="display: flex"
              >
                <div
                  style="margin-top: 3px; margin-right: 20px"
                  v-if="(activityInfo.market_template_category_id === 2) || (activityInfo.market_template_category_id === 5)"
                >
                  <span style="color: #808080">到店付</span>￥{{
                    item.surplus_price
                  }}
                </div>
                <div style="margin-top: 3px">
                  <span style="color: #808080">返佣金额</span>￥{{
                    item.rebate_prize
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="activityData" v-show="selectTool === '浏览记录'">
      <div
        class="search"
        style="margin-top: 10px; border: 1px solid #e8e8e8"
        id="middle"
      >
        <div>
          <el-form :model="cond" class="middle" label-width="80px">
            <el-form-item label="用户名称" style="margin-right: 30px">
              <el-input
                clearable
                v-model="userCond.username"
                autocomplete="off"
                placeholder="请输入用户名称"
              ></el-input
            ></el-form-item>
            <el-form-item label="浏览时间" style="margin-right: 30px">
              <el-date-picker
                type="daterange"
                v-model="userCond.time"
                range-separator="至"
                start-placeholder="浏览开始日期"
                end-placeholder="浏览结束日期"
              ></el-date-picker
            ></el-form-item>
          </el-form>
        </div>

        <div>
          <el-button
            type="primary"
            @click="getDrawnUserList()"
            style="height: 40px"
            >搜索</el-button
          >
          <el-button @click="resetDrawnUserList()" style="height: 40px"
            >重置</el-button
          >
        </div>
      </div>
      <div class="writeData">
        <!-- <div style="margin-left: 30px">
          今日浏览人数<span style="color: #2791ff; margin-left: 10px">{{
            today
          }}人</span>
        </div>
        <div class="line"></div>
        <div style="margin-left: 30px">
          今日浏览次数<span style="color: #2791ff; margin-left: 10px">{{
            today
          }}次</span>
        </div>
        <div class="line"></div>
        <div style="margin-left: 30px">
          总浏览人数<span style="color: #2791ff; margin-left: 10px">{{
            today
          }}人</span>
        </div>
        <div class="line"></div>
        <div style="margin-left: 30px">
          总浏览次数<span style="color: #2791ff; margin-left: 10px">{{
            today
          }}次</span>
        </div>
        <div class="line"></div>
        <div style="margin-left: 30px">
          虚拟浏览次数<span style="color: #2791ff; margin-left: 10px">{{
            today
          }}次</span>
        </div>
        <div class="line"></div>
        <div style="margin-left: 30px">
          总分享次数<span style="color: #2791ff; margin-left: 10px">{{
            today
          }}次</span>
        </div>
        <div class="line"></div>
        <div style="margin-left: 30px">
          虚拟分享次数<span style="color: #2791ff; margin-left: 10px">{{
            today
          }}次</span>
        </div> -->
        <div style="margin-left: 30px">
          今日浏览量<span style="color: #2791ff; margin-left: 10px">{{
            today
          }}</span>
        </div>
        <div class="line"></div>
        <div>
          总浏览数量<span style="color: #2791ff; margin-left: 10px">{{
            scan_total_num
          }}</span>
        </div>
      </div>
      <div class="table" style="margin-top: 10px; border: 1px solid #e8e8e8">
        <el-table
          :data="userScanList"
          stripe
          height="700"
          v-loading="partakeLoading"
        >
          <el-table-column
            align="center"
            type="index"
            label="序号"
          ></el-table-column>
          <el-table-column align="center" prop="index" label="用户头像">
            <template slot-scope="scope">
              <div class="headImg">
                <img :src="scope.row.head_img" />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="username"
            label="用户名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="ip"
            label="ip"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="create_time"
            label="浏览时间"
          ></el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="userScanTotal"
          :page-size="15"
          @current-change="handleCurrentScanChange"
        ></el-pagination>
      </div>
    </div>
    <div class="partakeData" v-show="selectTool === '参与记录'">
      <div
        class="search"
        style="margin-top: 10px; border: 1px solid #e8e8e8"
        id="middle"
      >
        <div>
          <el-form :model="joinCond" class="middle" label-width="100px">
            <el-form-item label="用户名称" style="margin-right: 30px">
              <el-input
                clearable
                v-model="joinCond.username"
                autocomplete="off"
                placeholder="请输入用户名称"
              ></el-input
            ></el-form-item>
            <el-form-item label="用户手机号" style="margin-right: 30px">
              <el-input
                clearable
                v-model="joinCond.mobile"
                autocomplete="off"
                placeholder="请输入用户手机号"
              ></el-input
            ></el-form-item>
            <el-form-item label="活动时间" style="margin-right: 30px">
              <el-date-picker
                type="daterange"
                v-model="joinCond.time"
                range-separator="至"
                start-placeholder="活动开始日期"
                end-placeholder="活动结束日期"
              ></el-date-picker
            ></el-form-item>
          </el-form>
        </div>
        <div>
          <el-button type="primary" @click="getJoinList()" style="height: 40px"
            >搜索</el-button
          >
          <el-button style="height: 40px" @click="resetJoinList"
            >重置</el-button
          >
          <el-button
            type="primary"
            plain
            @click="handleResetJoinExport"
            icon="el-icon-upload2"
            >导出</el-button
          >
        </div>
      </div>
      <div class="writeData">
        <div style="margin-left: 30px">
          实际参与人数<span style="color: #2791ff; margin-left: 10px">{{
            join_total_num
          }}</span>
        </div>
        <div class="line"></div>
        <div>
          虚拟人数<span style="color: #2791ff; margin-left: 10px">{{
            virtual_join
          }}</span>
        </div>
        <div class="line"></div>
        <div style="font-size: 12px; color: #909399">
          实时数据 {{ nowTime }}
        </div>
      </div>
      <div class="table" style="margin-top: 10px; border: 1px solid #e8e8e8">
        <el-table
          :data="joinList"
          stripe
          height="700"
          v-loading="partakeLoading"
        >
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column align="center" prop="index" label="用户头像">
            <template slot-scope="scope">
              <div class="headImg">
                <img :src="scope.row.head_img" />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="username"
            label="用户名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="mobile"
            label="手机号"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="create_time"
            label="参与时间"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="join_count"
            label="抽奖次数"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="share_count"
            label="分享次数"
          ></el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="joinTotal"
          :page-size="15"
          @current-change="handleCurrentJoinChange"
        ></el-pagination>
      </div>
    </div>
    <div class="partakeData" v-show="selectTool === '中奖记录'">
      <div
        class="search"
        style="margin-top: 10px; border: 1px solid #e8e8e8"
        id="middle"
      >
        <div>
          <el-form :model="cond" class="middle" label-width="100px" style="flex-wrap: wrap;">
            <el-form-item label="搜索" style="margin-right: 30px">
              <el-input
                style="width: 300px;"
                clearable
                v-model="drawnUserCond.search"
                autocomplete="off"
                placeholder="请输入用户名称/手机号/商品名称查询"
              ></el-input
            ></el-form-item>
            <el-form-item label="核销人员" style="margin-right: 30px">
              <el-input
                clearable
                v-model="drawnUserCond.cancel_user"
                autocomplete="off"
                placeholder="请输入核销人员"
              ></el-input
            ></el-form-item>
            <!-- <el-form-item label="用户手机号" style="margin-right: 30px">
              <el-input
                clearable
                v-model="drawnUserCond.mobile"
                autocomplete="off"
                placeholder="请输入用户手机号"
              ></el-input
            ></el-form-item> -->
            <el-form-item label="状态">
              <el-select
                v-model="drawnUserCond.status"
                placeholder="请选择状态"
                style="width: 200px; margin-right: 10px"
              >
                <el-option label="未领取" :value="10"></el-option>
                <el-option label="已领取待核销" :value="20"></el-option>
                <el-option label="已核销" :value="30"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="兑换门店">
                <el-select
                  v-model="drawnUserCond.exchange_hierarchy"
                  placeholder="请选择兑换门店"
                  style="width: 200px; margin-right: 10px"
                  clearable=""
                >
                  <template v-for="item in hierarchyList">
                    <el-option :key="item.hierarchy_id" :label="item.name" :value="item.hierarchy_id"></el-option>
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item label="核销门店">
                <el-select
                  v-model="drawnUserCond.channel_hierarchy"
                  placeholder="请选择核销门店"
                  style="width: 200px; margin-right: 10px"
                  clearable=""
                >
                  <template v-for="item in hierarchyList">
                    <el-option :key="item.hierarchy_id" :label="item.name" :value="item.hierarchy_id"></el-option>
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item label="中奖时间">
                <el-date-picker
                  v-model="drawnUserCond.drawn_time"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="核销时间">
                <el-date-picker
                  v-model="drawnUserCond.verification_time"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
          </el-form>
        </div>
        <div style="display: flex;">
          <el-button type="primary" @click="getUserList()" style="height: 40px"
            >搜索</el-button
          >
          <el-button style="height: 40px" @click="resetUserList"
            >重置</el-button
          >
          <el-button
            style="height: 40px;"
            type="primary"
            plain
            @click="handleExportPirzeUser"
            icon="el-icon-upload2"
            >导出</el-button
          >
        </div>
      </div>
      <div class="writeData">
        <div style="margin-left: 30px">
          中奖总数量<span style="color: #2791ff; margin-left: 10px">{{
            drawnUserInfo.total
          }}</span>
        </div>
        <div class="line"></div>
        <div>
          未领取数量<span style="color: #2791ff; margin-left: 10px">{{
            drawnUserInfo.not_get_total
          }}</span>
        </div>
        <div class="line"></div>
        <div>
          已领取待核销数<span style="color: #2791ff; margin-left: 10px">{{
            drawnUserInfo.get_total
          }}</span>
        </div>
        <div class="line"></div>
        <div>
           已核销数<span style="color: #2791ff; margin-left: 10px">{{
            drawnUserInfo.channel_total
          }}</span>
        </div>
        <!-- <div style="font-size: 12px; color: #909399">
          实时数据 {{ nowTime }}
        </div> -->
      </div>
      <div class="table" style="margin-top: 10px; border: 1px solid #e8e8e8">
        <el-table :data="drawnUserList" stripe height="700">
          <el-table-column
            align="center"
            type="index"
            label="序号"
          ></el-table-column>
          <el-table-column align="center" prop="index" label="头像">
            <template slot-scope="scope">
              <div class="headImg">
                <img :src="scope.row.head_img" /></div></template
          ></el-table-column>
          <el-table-column
            align="center"
            prop="username"
            label="用户名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="mobile"
            label="手机号"
          ></el-table-column>
          <el-table-column align="center" prop="goods_pic" label="奖品图片">
            <template slot-scope="scope">
              <div class="goods_Img">
                <img :src="scope.row.goods_pic" /></div></template
          ></el-table-column>
          <el-table-column
            align="center"
            prop="goods_name"
            label="奖品名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="hit_time"
            label="中奖时间"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="verif_hierarchy_id"
            label="选择兑换门店"
          >
            <template slot-scope="scope">{{
              scope.row.verif_hierarchy_id || "--"
            }}</template>
          </el-table-column>
          <el-table-column align="center" prop="receive_time" label="领奖时间">
            <template slot-scope="scope">{{
              scope.row.receive_time || "--"
            }}</template></el-table-column
          >
          <el-table-column align="center" prop="status_name" label="状态">
          </el-table-column>
          <el-table-column
            align="center"
            prop="channel_hierarchy_id"
            label="核销门店"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="store_user"
            label="核销人员"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="verification_time"
            label="核销时间"
          ></el-table-column>
          <el-table-column align="center" prop="action" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="handleVerif(scope.row)" v-if="scope.row.status != 10">{{
                scope.row.status === 20 ? "查看详情" : "核销"
              }}</el-button>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="drawnUserTotal"
          :page-size="15"
          @current-change="handleCurrentDrawnUserChange"
        ></el-pagination>
      </div>
    </div>
    <div class="writeOff" v-show="selectTool === '核销记录'">
      <div class="search" style="margin-top: 10px; border: 1px solid #e8e8e8">
        <div :style="{ height: writeOffHeight }" class="wrapper">
          <div class="middle" id="middle">
            <el-form
              :model="cond"
              class="form"
              style="overflow: hidden; display: flex; flex-wrap: wrap"
              label-width="100px"
            >
              <el-form-item
                :label="
                  activityInfo.market_template_category_id === 1
                    ? '奖品名称'
                    : '商品名称'
                "
                style="margin-right: 10px"
              >
                <el-input
                  clearable
                  v-model="verifCond.goods_name"
                  autocomplete="off"
                  :placeholder="
                    activityInfo.market_template_category_id === 1
                      ? '请输入奖品名称'
                      : '请输入商品名称'
                  "
                ></el-input
              ></el-form-item>
              <el-form-item label="用户名称" style="margin-right: 10px">
                <el-input
                  clearable
                  v-model="verifCond.username"
                  autocomplete="off"
                  placeholder="请输入用户名称"
                ></el-input
              ></el-form-item>
              <el-form-item label="用户手机号" style="margin-right: 10px">
                <el-input
                  clearable
                  v-model="verifCond.mobile"
                  autocomplete="off"
                  placeholder="请输入用户手机号"
                ></el-input
              ></el-form-item>
              <el-form-item label="核销人" style="margin-right: 10px">
                <el-input
                  clearable
                  v-model="verifCond.title"
                  autocomplete="off"
                  placeholder="请输入核销人"
                ></el-input
              ></el-form-item>
              <el-form-item label="核销状态">
                <el-select
                  v-model="verifCond.is_verif"
                  placeholder="请选择核销状态"
                  style="width: 200px; margin-right: 10px"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option label="未核销" :value="10"></el-option>
                  <el-option label="已核销" :value="20"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div>
          <el-button
            @click="handleHeight"
            style="
              width: 40px;
              height: 40px;
              line-height: 36px;
              text-align: center;
              padding: 0;
            "
          >
            <i
              class="el-icon-caret-bottom"
              v-if="writeOffHeight === '55px'"
            ></i>
            <i class="el-icon-caret-top" v-else></i>
          </el-button>
          <el-button type="primary" @click="getVerifList()" style="height: 40px"
            >搜索</el-button
          >
          <el-button style="height: 40px" @click="resetVerifList"
            >重置</el-button
          >
          <el-button
            type="primary"
            plain
            @click="handleExportDrawnUser"
            style="margin-right: 10px"
            icon="el-icon-upload2"
            >导出</el-button
          >
        </div>
      </div>
      <div class="writeData">
        <div style="margin-left: 30px">
          核销总数量<span style="color: #2791ff; margin-left: 10px">{{
            verif_total_num
          }}</span>
        </div>
        <div class="line"></div>
        <div>
          未核销数量<span style="color: #2791ff; margin-left: 10px">{{
            un_verif
          }}</span>
        </div>
        <div class="line"></div>
        <div>
          已核销数量<span style="color: #2791ff; margin-left: 10px">{{
            verif
          }}</span>
        </div>
        <div class="line"></div>
        <div style="font-size: 12px; color: #909399">
          实时数据 {{ nowTime }}
        </div>
      </div>
      <div class="table" style="margin-top: 10px; border: 1px solid #e8e8e8">
        <el-table
          :data="verifList"
          stripe
          height="700"
          v-loading="partakeLoading"
        >
          <el-table-column
            align="center"
            type="index"
            label="序号"
          ></el-table-column>
          <el-table-column align="center" prop="headImg" label="头像">
            <template slot-scope="scope">
              <div class="headImg">
                <img :src="scope.row.head_img" /></div></template
          ></el-table-column>
          <el-table-column
            align="center"
            prop="username"
            label="用户名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="mobile"
            label="手机号"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="goods_pic"
            :label="
              activityInfo.market_template_category_id === 1
                ? '奖品图片'
                : '商品图片'
            "
          >
            <template slot-scope="scope">
              <div class="goods_Img">
                <img :src="scope.row.goods_pic" /></div></template
          ></el-table-column>
          <el-table-column
            align="center"
            prop="goods_name"
            :label="
              activityInfo.market_template_category_id === 1
                ? '奖品名称'
                : '商品名称'
            "
          ></el-table-column>
          <el-table-column
            v-if="activityInfo.market_template_category_id === 1"
            align="center"
            prop="hit_time"
            label="中奖时间"
          ></el-table-column>
          <el-table-column
            v-if="activityInfo.market_template_category_id === 2"
            align="center"
            prop="paid_in_amount"
            label="订单金额"
          ></el-table-column>
          <el-table-column
            v-if="activityInfo.market_template_category_id === 2"
            align="center"
            prop="pay_time"
            label="支付时间"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="verif_hierarchy_name"
            label="选择领奖门店"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="final_verif_hierarchy_name"
            label="核销门店"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="verif_user_name"
            label="核销人"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="verif_time"
            label="核销时间"
          ></el-table-column>

          <el-table-column
            align="center"
            prop="is_receive"
            label="核销状态"
            v-if="
              activityInfo.market_template_category_id === 1 ||
              activityInfo.market_template_category_id === 5
            "
          >
            <template slot-scope="scope">
              {{ scope.row.status == 10 ? "未核销" : "已核销" }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="action" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="handleVerif(scope.row)">{{
                scope.row.status == 20 ? "查看详情" : "核销"
              }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="verifTotal"
          :page-size="15"
          @current-change="handleCurrentVerifChange"
        ></el-pagination>
      </div>
    </div>
    <div class="orderData" v-show="selectTool === '订单记录'">
      <div class="search" style="margin-top: 10px; border: 1px solid #e8e8e8">
        <div :style="{ height: orderHeight }" class="wrapper">
          <div class="middle" id="middle">
            <el-form
              :model="orderCond"
              label-width="100px"
              class="form"
              style="overflow: hidden; display: flex; flex-wrap: wrap"
            >
              <el-form-item label="搜索" style="margin-right: 10px">
                <el-input
                  style="width: 340px;"
                  clearable
                  v-model="orderCond.search"
                  autocomplete="off"
                  placeholder="请输入用户名称/手机号/订单号查询/商品名称"
                ></el-input
              ></el-form-item>
              <el-form-item label="邀请人" style="margin-right: 10px">
                <el-input
                  clearable
                  v-model="orderCond.inviter"
                  autocomplete="off"
                  placeholder="请输入邀请人"
                ></el-input
              ></el-form-item>
              <el-form-item label="核销人" style="margin-right: 10px">
                <el-input
                  clearable
                  v-model="orderCond.cancel_user"
                  autocomplete="off"
                  placeholder="请输入核销人"
                ></el-input
              ></el-form-item>
              <!-- <el-form-item label="用户手机号" style="margin-right: 10px">
                <el-input
                  clearable
                  v-model="orderCond.member_phone"
                  autocomplete="off"
                  placeholder="请输入用户手机号"
                ></el-input
              ></el-form-item> -->
              <!-- <el-form-item label="商品名称" style="margin-right: 10px">
                <el-input
                  clearable
                  v-model="orderCond.goods_name"
                  autocomplete="off"
                  placeholder="请输入商品名称"
                ></el-input
              ></el-form-item> -->
              <!-- <el-form-item label="订单号" style="margin-right: 10px">
                <el-input
                  clearable
                  v-model="orderCond.order_number"
                  autocomplete="off"
                  placeholder="请输入订单号"
                ></el-input
              ></el-form-item> -->
              <el-form-item label="订单状态">
                <el-select
                  v-model="orderCond.order_status"
                  placeholder="请选择订单状态"
                  style="width: 200px; margin-right: 10px"
                  clearable=""
                >
                  <el-option label="全部状态" :value="0"></el-option>
                  <el-option label="待支付" :value="10"></el-option>
                  <el-option label="已支付待核销" :value="20"></el-option>
                  <el-option label="已核销" :value="50"></el-option>
                  <el-option label="已关闭" :value="60"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="订单类型">
                <el-select
                  v-model="orderCond.goods_type"
                  placeholder="请选择订单类型"
                  style="width: 200px; margin-right: 10px"
                  clearable=""
                >
                  <el-option label="商品" :value="30"></el-option>
                  <el-option label="抽奖" :value="40"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="核销门店">
                <el-select
                  v-model="orderCond.channel_hierarchy"
                  placeholder="请选择核销门店"
                  style="width: 200px; margin-right: 10px"
                  clearable=""
                >
                  <template v-for="item in hierarchyList">
                    <el-option :key="item.hierarchy_id" :label="item.name" :value="item.hierarchy_id"></el-option>
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item label="支付时间">
                <el-date-picker
                  v-model="orderCond.pay_time"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="核销时间">
                <el-date-picker
                  v-model="orderCond.verification_time"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div>
          <el-button
            @click="handleOrderHeight"
            style="
              width: 40px;
              height: 40px;
              line-height: 36px;
              text-align: center;
              padding: 0;
            "
          >
            <i class="el-icon-caret-bottom" v-if="orderHeight === '55px'"></i>
            <i class="el-icon-caret-top" v-else></i>
          </el-button>
          <el-button type="primary" @click="getOrderList()" style="height: 40px"
            >搜索</el-button
          >
          <el-button style="height: 40px" @click="resetOrderList"
            >重置</el-button
          >
          <el-button
            type="primary"
            plain
            @click="handleOrderExport"
            icon="el-icon-upload2"
            style="margin-right: 10px"
            >导出</el-button
          >
        </div>
      </div>
      <div class="writeData">
        <div style="margin-left: 30px">
          购买人数<span style="color: #2791ff; margin-left: 8px">{{
            people_buy
          }}</span>人
        </div>
        <div class="line"></div>
        <div>
          订单总数<span style="color: #2791ff; margin-left: 8px">{{
            total
          }}</span>笔
        </div>
        <div class="line"></div>
        <div>
          待支付<span style="color: #2791ff; margin-left: 8px">{{
            wait_pay_count
          }}</span>笔
        </div>
        <div class="line"></div>
        <div>
          已支付待核销<span style="color: #2791ff; margin-left: 8px">{{
            wait_cancel_count
          }}</span>笔
        </div>
        <div class="line"></div>
        <div>
          已核销<span style="color: #2791ff; margin-left: 8px">{{
            finish_count
          }}</span>笔
        </div>
        <div class="line"></div>
        <div>
          已关闭<span style="color: #2791ff; margin-left: 8px">{{
            close_count
          }}</span>笔
        </div>
        <div class="line"></div>
        <div>
          收款总额<span style="color: #2791ff; margin-left: 8px">{{
            order_amounts
          }}</span>元
        </div>
        <div class="line"></div>
        <div>
          返佣总额<span style="color: #2791ff; margin-left: 8px">{{
            rebate_prize
          }}</span>元
        </div>
        <!-- <div style="font-size: 12px; color: #909399">
          实时数据 {{ nowTime }}
        </div> -->
      </div>
      <div class="table" style="margin-top: 10px; border: 1px solid #e8e8e8">
        <el-table :data="orderList" stripe height="700">
          <el-table-column
            align="center"
            type="index"
            label="序号"
          ></el-table-column>
          <el-table-column align="center" prop="index" label="头像">
            <template slot-scope="scope">
              <div class="headImg">
                <img :src="scope.row.member_avatar" /></div></template
          ></el-table-column>
          <el-table-column
            align="center"
            prop="member_name"
            label="用户名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="member_phone"
            label="手机号"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="parent_username"
            label="邀请人"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="order_status_name"
            label="订单状态"
          ></el-table-column>
           <el-table-column
            align="center"
            prop="order_type"
            label="订单类型"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="order_number"
            label="商品订单号"
            width="220"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="transaction_id"
            label="微信订单号"
            width="220"
          ></el-table-column>
          <el-table-column align="center" prop="goods_pic" label="商品图片">
            <template slot-scope="scope">
              <div class="goods_Img">
                <img :src="scope.row.goods_pic" /></div></template
          ></el-table-column>
          <el-table-column
            align="center"
            prop="goods_name"
            label="商品名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="order_amount"
            label="订单金额"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="pay_time"
            label="支付时间"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="rebate_prize_total"
            label="返佣金额"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="hierarchy"
            label="选择领奖门店"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="channel_hierarchy"
            label="核销门店"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="store_user"
            label="核销人员"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="verification_time"
            label="核销时间"
          ></el-table-column>
          <el-table-column align="center" prop="action" label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleVerif(scope.row)" v-if="scope.row.order_status != 60">{{
                scope.row.order_status === 20 ? "核销" : "查看详情"
              }}</el-button>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="orderTotal"
          :page-size="15"
          @current-change="handleCurrentOrderhange"
        ></el-pagination>
      </div>
    </div>
    <div class="expenData" v-show="selectTool === '返佣记录'">
      <div
        class="search"
        style="margin-top: 10px; border: 1px solid #e8e8e8"
        id="middle"
      >
        <div>
          <el-form :model="expenCond" class="middle" label-width="100px">
            <el-form-item label="用户名称" style="margin-right: 10px">
              <el-input
                clearable
                v-model="expenCond.username"
                autocomplete="off"
                placeholder="请输入用户名称"
              ></el-input
            ></el-form-item>
            <el-form-item label="用户手机号" style="margin-right: 10px">
              <el-input
                clearable
                v-model="expenCond.mobile"
                autocomplete="off"
                placeholder="请输入用户手机号"
              ></el-input
            ></el-form-item>
          </el-form>
        </div>
        <div>
          <el-button
            type="primary"
            @click="getRebateList()"
            style="height: 40px"
            >搜索</el-button
          >
          <el-button style="height: 40px" @click="resetExpenList"
            >重置</el-button
          >
          <el-button
            type="primary"
            plain
            @click="handleRebateExport"
            icon="el-icon-upload2"
            >导出</el-button
          >
        </div>
      </div>
      <div class="writeData">
        <div style="margin-left: 30px">
          返佣笔数<span style="color: #2791ff; margin-left: 10px">{{
            count_total
          }}</span>
        </div>
        <div class="line"></div>
        <div>
          返佣金额<span style="color: #2791ff; margin-left: 10px">{{
            price_total
          }}</span>
        </div>
        <div class="line"></div>
        <div>
          已提现金额<span style="color: #2791ff; margin-left: 10px">{{
            finish_price_total
          }}</span>
        </div>
        <div class="line"></div>
        <div>
          未提现金额<span style="color: #2791ff; margin-left: 10px">{{
            un_finish_price_total
          }}</span>
        </div>
        <div class="line"></div>
        <div style="font-size: 12px; color: #909399">
          实时数据 {{ nowTime }}
        </div>
      </div>
      <div class="table" style="margin-top: 10px; border: 1px solid #e8e8e8">
        <el-table :data="expenList" stripe height="700">
          <el-table-column
            align="center"
            type="index"
            label="序号"
          ></el-table-column>
          <el-table-column align="center" prop="index" label="头像">
            <template slot-scope="scope">
              <div class="headImg">
                <img :src="scope.row.head_img" /></div></template
          ></el-table-column>
          <el-table-column
            align="center"
            prop="username"
            label="返佣用户"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="mobile"
            label="手机号"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="count_total"
            label="返佣笔数"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="price_total"
            label="返佣总金额"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="finish_price_total"
            label="已提现金额"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="un_finish_price_total"
            label="未提现金额"
          ></el-table-column>
          <el-table-column align="center" prop="action" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="handleRebateDetail(scope.row)"
                >查看详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="expenTotal"
          :page-size="15"
          @current-change="handleCurrentExpenChange"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      :visible.sync="visiable"
      width="30%"
      title="核销详情"
      :destroy-on-close="true"
      :before-close="cancelVerifer"
    >
      <div class="title">
        <div class="span"></div>
        会员信息
      </div>
      <div style="display: flex">
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">会员名称</div>
          <div>{{ veriferInfo.username }}</div>
        </div>
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">手机号</div>
          <div>{{ veriferInfo.mobile }}</div>
        </div>
      </div>
      <div class="title" style="margin-top: 15px">
        <div class="span"></div>
        奖品信息
      </div>
      <div>
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">奖品名称</div>
          <div>
            {{
              veriferInfo.market_goods_json &&
              veriferInfo.market_goods_json.goods_name
            }}
          </div>
        </div>
      </div>
      <div>
        <div style="margin-top: 15px; display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">奖品图片</div>
          <div>
            <img
              :src="
                veriferInfo.market_goods_json &&
                veriferInfo.market_goods_json.goods_pic
              "
              style="width: 94px; height: 64px; object-fit: cover"
            />
          </div>
        </div>
      </div>
      <div>
        <div style="margin-top: 15px; display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">获奖时间</div>
          <div>{{ veriferInfo.create_time }}</div>
        </div>
      </div>
      <div class="title" style="margin-top: 15px">
        <div class="span"></div>
        兑奖信息
      </div>
      <div style="display: flex">
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">领取门店</div>
          <div>{{ veriferInfo.hierarchy_name }}</div>
        </div>
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">门店地址</div>
          <div>{{ veriferInfo.hierarchy_addr }}</div>
        </div>
      </div>
      <div style="display: flex; margin-top: 10px">
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">门店电话</div>
          <div>{{ veriferInfo.hierarchy_phone_num }}</div>
        </div>
      </div>
      <div style="position: absolute; top: 35%; right: 10%; z-index: 5555">
        <img
          src="@/assets/images/activity/writeOffed.svg"
          style="transform: rotate(-10deg)"
          v-if="veriferInfo.is_verif === 20"
        />
        <img
          src="@/assets/images/activity/writeOff.svg"
          style="transform: rotate(-10deg)"
          v-if="veriferInfo.is_verif === 10"
        />
      </div>
      <div
        class="title"
        style="margin-top: 15px"
        v-if="veriferInfo.is_verif === 20"
      >
        <div class="span"></div>
        核销信息
      </div>
      <div style="display: flex" v-if="veriferInfo.is_verif === 20">
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">核销人员</div>
          <div>{{ veriferInfo.verif_user_name }}</div>
        </div>
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">核销时间</div>
          <div>{{ veriferInfo.verif_time }}</div>
        </div>
      </div>
      <div
        style="display: flex; margin-top: 10px"
        v-if="veriferInfo.is_verif === 20"
      >
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">核销门店</div>
          <div>{{ veriferInfo.final_verif_hierarchy_name }}</div>
        </div>
      </div>
      <div style="margin-top: 15px; text-align: center">
        <el-button
          type="primary"
          plain
          @click="cancelVerifer"
          v-if="veriferInfo.is_verif === 20"
          >取消</el-button
        >
        <el-button
          type="primary"
          @click="handleWriteOff"
          v-if="veriferInfo.is_verif === 10"
          >核销</el-button
        >
        <el-button
          type="primary"
          @click="cancelVerifer"
          v-if="veriferInfo.is_verif === 20"
          >确认</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :fullscreen="false"
      title="返佣详情"
      width="80%"
      :visible.sync="rebateDetailVisiable"
      :before-close="handleCloseRebate"
    >
      <div>
        <div class="writeData" style="border-top: 1px solid #e8e8e8">
          <div style="margin-left: 30px">
            返佣笔数<span style="color: #2791ff; margin-left: 10px">{{
              detail_count_total
            }}</span>
          </div>
          <div class="line"></div>
          <div>
            返佣总金额<span style="color: #2791ff; margin-left: 10px">{{
              detail_price_total
            }}</span>
          </div>
          <div class="line"></div>
          <div>
            已提现金额<span style="color: #2791ff; margin-left: 10px">{{
              detail_finish_price_total
            }}</span>
          </div>
          <div class="line"></div>
          <div>
            未提现金额<span style="color: #2791ff; margin-left: 10px">{{
              detail_un_finish_price_total
            }}</span>
          </div>
        </div>
        <div class="table" style="margin-top: 10px; border: 1px solid #e8e8e8">
          <el-table :data="rebateDetailList" stripe height="700">
            <el-table-column
              align="center"
              type="index"
              label="序号"
            ></el-table-column>
            <el-table-column align="center" prop="index" label="头像">
              <template slot-scope="scope">
                <div class="headImg">
                  <img :src="scope.row.member_avatar" /></div></template
            ></el-table-column>
            <el-table-column
              align="center"
              prop="member_name"
              label="购买用户"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="member_phone"
              label="手机号"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="order_number"
              label="订单号"
              width="220"
            ></el-table-column>
            <el-table-column align="center" prop="goods_pic" label="商品图片">
              <template slot-scope="scope">
                <div class="goods_Img">
                  <img :src="scope.row.goods_pic" /></div></template
            ></el-table-column>
            <el-table-column
              align="center"
              prop="goods_name"
              label="商品名称"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="create_time"
              label="购买时间"
              width="220"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="get_price"
              label="返佣金额"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="finish_status"
              label="提现状态"
            >
              <template slot-scope="scope">
                {{ scope.row.finish_status === 20 ? "已提现" : "未提现" }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="pay_time"
              label="提现时间"
              width="220"
            ></el-table-column>
          </el-table>
        </div>
        <div class="pagina">
          <el-pagination
            background
            layout="total, prev, pager, next, jumper"
            :total="rebateDetailTotal"
            :page-size="15"
            @current-change="handleCurrentRebateDetailChange"
          ></el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getHierarchyList,
  getActivityDetail,
  getJoinList,
  getDrawnUserList,
  getUserList2 as getUserList,
  getVerifList,
  checkVerificationCode,
  verifDetails,
  handleExportUserData,
  handleExportDrawnUser,
  handleExportPirzeUser,
  getOrderList2 as getOrderList,
  getRebateList,
  getRebateDetail,
  exportOrder,
  exportRebate,
} from "@/api/marketing/activity/index";
const noPic = require("@/assets/images/nopic.png");

import { dateFormat } from "../../../../utils/method";
export default {
  data() {
    return {
      drawnUserInfo: {},
      hierarchyList: [], // 核销门店列表
      writeOffHeight: "55px",
      orderHeight: "55px",
      tabs1: ["活动详情", "浏览记录", "参与记录", "中奖记录", "核销记录"],
      tabs2: ["活动详情", "浏览记录", "订单记录", "返佣记录", "核销记录"],
      tabs3: [
        "活动详情",
        "浏览记录",
        "参与记录",
        "中奖记录",
        "订单记录",
        "返佣记录",
        "核销记录",
      ],
      selectTool: "活动详情",
      cond: {},
      partakeData: [],
      partakeLoading: false,
      activityInfo: {},
      prizeList: [],
      goodsList: [],
      joinList: [],
      joinTotal: 0,
      joinCond: {
        page: 1,
        page_num: 15,
      },
      join_total_num: 0,
      virtual_join: 0,
      userScanList: [],
      userScanTotal: 0,
      userCond: {
        page: 1,
        page_num: 15,
      },
      scan_total_num: 0,
      today: 0,
      drawnUserList: [],
      drawnUserTotal: 0,
      drawnUserCond: {
        page: 1,
        page_num: 15,
        verification_time: [],
        drawn_time: [],
      },
      receive: 0,
      total_num: 0,
      un_receive: 0,
      verifList: [],
      verifCond: {
        page: 1,
        page_num: 15,
      },
      verifTotal: 0,
      verif_total_num: 0,
      un_verif: 0,
      verif: 0,
      orderList: [],
      orderCond: {
        page: 1,
        page_num: 15,
        pay_time: [],
        verification_time: [],
      },
      orderTotal: 0,
      finish_count: 0,
      paid_in_amount: 0,
      pay_count: 0,
      un_pay_count: 0,
      close_count: 0,
      wait_pay_count: 0,
      expenList: [],
      expenCond: {
        page: 1,
        page_num: 15,
      },
      expenTotal: 0,
      count_total: 0,
      finish_price_total: 0,
      price_total: 0,
      un_finish_price_total: 0,
      rebateDetailVisiable: false,
      verifGoodsVisiable: false,
      client_weixin_user_id: null,
      rebateDetailList: [],
      rebateDetailTotal: 0,
      detail_count_total: 0,
      detail_finish_price_total: 0,
      detail_price_total: 0,
      detail_un_finish_price_total: 0,
      detail_rebateCond: {
        page: 1,
        page_num: 15,
      },
      verifCode: null, // 核销码
      market_drawn_user_id: "",
      nowTime: null, // 实时数据时间
      noPic: noPic,
      visiable: false,
      veriferInfo: {},
      market_json: {},
      style_json: {},
      people_buy: 0,
      total: 0,
      wait_pay_count: 0,
      wait_cancel_count: 0,
      finish_count: 0,
      close_count: 0,
      order_amounts: 0,
      rebate_prize: 0,
    };
  },
  props: {
    store_category_id: Number,
    store_activity_id: Number,
  },
  created() {
    this.getHierarchyList();
    this.nowTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date());
    this.getActivityDetails();
  },
  methods: {
    // 获取核销门店
    getHierarchyList() {
      getHierarchyList({
        market_id: this.store_activity_id,
      })
        .then((res) => {
          if (res.code === 1) {
            this.hierarchyList = res.data;
          }
        });
    },
    toVerif(row) {
      this.market_drawn_user_id = row.market_drawn_user_id;
      this.verifGoodsVisiable = true;
    },
    handleCloseVerif() {
      this.verifGoodsVisiable = false;
    },
    handleCloseRebate() {
      this.rebateDetailVisiable = false;
      this.rebateDetailList = [];
      this.rebateDetailTotal = 0;
      this.detail_count_total = 0;
      this.detail_finish_price_total = 0;
      this.detail_price_total = 0;
      this.detail_un_finish_price_total = 0;
    },
    goBack() {
      this.$emit("hide");
    },
    /*
     *  活动详情
     */
    getActivityDetails() {
      getActivityDetail({
        market_id: this.store_activity_id,
        market_template_category_id: this.store_category_id,
      }).then((res) => {
        if (res.code === 1) {
          res.data.explain = res.data.explain.replace(/[\'\"\\\/\b\f\n\r\t]/g, '');
          this.activityInfo = res.data;
          this.market_json = res.data.market_json;
          this.style_json = res.data.style_json;
          this.prizeList = res.data.market_goods.filter(
            (item) => item.market_goods_type === 10
          );
          this.goodsList = res.data.market_goods.filter(
            (item) =>
              item.market_goods_type === 20 || item.market_goods_type === 30 || item.market_goods_type === 50
          );
        }
      });
    },
    cancelVerifer() {
      this.veriferInfo = {};
      this.visiable = false;
    },
    handleWriteOff() {
        checkVerificationCode({ code: this.veriferInfo.verif_code }).then(
          (res) => {
            if (res.code === 1) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.cancelVerifer();
              this.getVerifList();
            }
          }
        );
    },
    /*
     *  浏览记录分页操作
     */
    handleCurrentScanChange(val) {
      this.userCond.page = val;
      this.getDrawnUserList();
    },
    /*
     * 核销
     */
    handleVerif(row) {
      this.visiable = true;
      verifDetails({ code: row.verif_code || row.code }).then((res) => {
        if (res.code === 1) {
          this.veriferInfo = res.data.info;
        }
      });
    },
    /*
     *  参与记录分页操作
     */
    handleCurrentJoinChange(val) {
      this.joinCond.page = val;
      this.getJoinList();
    },
    resetJoinList() {
      this.joinCond = {
        page: 1,
        page_num: 15,
      };
      getJoinList({ market_id: this.store_activity_id, ...this.joinCond }).then(
        (res) => {
          if (res.code === 1) {
            this.joinList = res.data.list;
            this.joinTotal = res.data.total;
            this.join_total_num = res.data.join_total_num;

            this.virtual_join = res.data.virtual_join;
          }
        }
      );
    },
    /*
     *  参与记录导出
     */
    handleResetJoinExport() {
      handleExportUserData({
        market_id: this.store_activity_id,
        ...this.joinCond,
      }).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const a = document.createElement("a");
        a.setAttribute("download", `参与记录导出.xlsx`);
        a.href = URL.createObjectURL(blob);
        a.click();
      });
    },
    /*
     *  参与记录
     */
    getJoinList() {
      getJoinList({ market_id: this.store_activity_id, ...this.joinCond }).then(
        (res) => {
          if (res.code === 1) {
            this.joinList = res.data.list;
            this.joinTotal = res.data.total;
            this.join_total_num = res.data.join_total_num;

            this.virtual_join = res.data.virtual_join;
          }
        }
      );
    },
    resetDrawnUserList() {
      this.userCond = {
        page: 1,
        page_num: 15,
      };
      this.getDrawnUserList();
      // getDrawnUserList({
      //   market_id: this.store_activity_id,
      //   ...this.userCond,
      // }).then((res) => {
      //   if (res.code === 1) {
      //     this.userScanList = res.data.list;
      //     this.userScanTotal = res.data.total;
      //     this.scan_total_num = res.data.scan_total_num;
      //     this.today = res.data.today;
      //   }
      // });
    },
    /*
     *  浏览记录
     */
    getDrawnUserList() {
      getDrawnUserList({
        market_id: this.store_activity_id,
        ...this.userCond,
      }).then((res) => {
        if (res.code === 1) {
          this.userScanList = res.data.list;
          this.userScanTotal = res.data.total;
          this.scan_total_num = res.data.scan_total_num;
          this.today = res.data.today;
        }
      });
    },
    /*
     *  中奖记录分页操作
     */
    handleCurrentDrawnUserChange(val) {
      this.drawnUserCond.page = val;
      this.getUserList();
    },
    resetUserList() {
      this.drawnUserCond = {
        page: 1,
        page_num: 15,
        drawn_time: [],
        verification_time: [],
      };
      getUserList({
        market_id: this.store_activity_id,
        ...this.drawnUserCond,
      }).then((res) => {
        if (res.code === 1) {
          this.drawnUserList = res.data.list.list;
          this.drawnUserTotal = res.data.list.total;
        }
      });
    },
    /*
     *  中奖记录
     */
    getUserList() {
      getUserList({
        market_id: this.store_activity_id,
        ...this.drawnUserCond,
      }).then((res) => {
        if (res.code === 1) {
          this.drawnUserList = res.data.list;
          this.drawnUserTotal = res.data.total;
          this.total_num = res.data.total_num;
          this.receive = res.data.receive;
          this.un_receive = res.data.un_receive;
          // 新增
          this.drawnUserInfo = res.data;
        }
      });
    },
    /*
     *  中奖记录导出
     */
    handleExportPirzeUser() {
      getUserList({
        market_id: this.store_activity_id,
        export_url: 1,
        ...this.drawnUserCond,
      }).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const a = document.createElement("a");
        a.setAttribute("download", `中奖记录导出.xlsx`);
        a.href = URL.createObjectURL(blob);
        a.click();
      });
    },
    handleCurrentVerifChange(val) {
      this.verifCond.page = val;
      this.getVerifList();
    },
    resetVerifList() {
      this.verifCond = {
        page: 1,
        page_num: 15,
      };
      getVerifList({
        market_id: this.store_activity_id,
        ...this.verifCond,
      }).then((res) => {
        if (res.code === 1) {
          this.verifList = res.data.list.list;
          this.verifTotal = res.data.list.total;
          this.verif_total_num = res.data.total_num;
          this.verif = res.data.verif;
          this.un_verif = res.data.un_verif;
        }
      });
    },
    /*
     *  核销记录导出
     */
    handleExportDrawnUser() {
      handleExportDrawnUser({
        market_id: this.store_activity_id,
        ...this.verifCond,
      }).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const a = document.createElement("a");
        a.setAttribute("download", `核销记录导出.xlsx`);
        a.href = URL.createObjectURL(blob);
        a.click();
      });
    },
    /*
     *   核销记录
     */
    getVerifList() {
      getVerifList({
        market_id: this.store_activity_id,
        ...this.verifCond,
      }).then((res) => {
        if (res.code === 1) {
          this.verifList = res.data.list.list;
          this.verifTotal = res.data.list.total;
          (this.verif_total_num = res.data.total_num),
            (this.verif = res.data.verif);
          this.un_verif = res.data.un_verif;
        }
      });
    },
    /*
     *  订单记录
     */
    getOrderList() {
      getOrderList({
        market_id: this.store_activity_id,
        ...this.orderCond,
      }).then((res) => {
        if (res.code === 1) {
          this.orderList = res.data.list;
          this.orderTotal = res.data.total;
          this.finish_count = res.data.finish_count;
          this.paid_in_amount = res.data.paid_in_amount;
          this.pay_count = res.data.pay_count;
          this.un_pay_count = res.data.un_pay_count;
          this.wait_pay_count = res.data.wait_pay_count;
          this.close_count = res.data.close_count;
          // 新增
          this.people_buy = res.data.people_buy;
          this.total = res.data.total;
          this.wait_pay_count = res.data.wait_pay_count;
          this.wait_cancel_count = res.data.wait_cancel_count;
          this.finish_count = res.data.finish_count;
          this.close_count = res.data.close_count;
          this.order_amounts = res.data.order_amounts;
          this.rebate_prize = res.data.rebate_prize;
        }
      });
    },
    /*
     *  订单记录导出
     */
    handleOrderExport() {
      getOrderList({
        market_id: this.store_activity_id,
        export_url: 1,
        ...this.orderCond,
      }).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const a = document.createElement("a");
        a.setAttribute("download", `订单记录导出.xlsx`);
        a.href = URL.createObjectURL(blob);
        a.click();
      });
    },
    handleCurrentOrderhange(val) {
      this.orderCond.page = val;
      this.getOrderList();
    },
    /*
     *  订单记录重置
     */
    resetOrderList() {
      this.orderCond = {
        page: 1,
        page_num: 15,
        pay_time: [],
        verification_time: [],
      };
      getOrderList({
        market_id: this.store_activity_id,
        ...this.orderCond,
      }).then((res) => {
        if (res.code === 1) {
          this.orderList = res.data.list;
          this.orderTotal = res.data.total;
        }
      });
    },
    /*
     *  返佣记录导出
     */
    handleRebateExport() {
      exportRebate({
        market_id: this.store_activity_id,
        ...this.expenCond,
      }).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const a = document.createElement("a");
        a.setAttribute("download", `返佣记录导出.xlsx`);
        a.href = URL.createObjectURL(blob);
        a.click();
      });
    },
    /*
     *  返佣记录列表
     */
    getRebateList() {
      getRebateList({
        market_id: this.store_activity_id,
        ...this.expenCond,
      }).then((res) => {
        if (res.code === 1) {
          this.expenList = res.data.list;
          this.expenTotal = res.data.total;
          this.count_total = res.data.count_total;
          this.finish_price_total = res.data.finish_price_total;
          this.price_total = res.data.price_total;
          this.un_finish_price_total = res.data.un_finish_price_total;
        }
      });
    },

    resetExpenList() {
      this.expenCond = {
        page: 1,
        page_num: 15,
      };
      getRebateList({
        market_id: this.store_activity_id,
        ...this.expenCond,
      }).then((res) => {
        if (res.code === 1) {
          this.expenList = res.data.list;
          this.expenTotal = res.data.total;
          this.count_total = res.data.count_total;
          this.finish_price_total = res.data.finish_price_total;
          this.price_total = res.data.price_total;
          this.un_finish_price_total = res.data.un_finish_price_total;
        }
      });
    },
    handleCurrentExpenChange(val) {
      this.expenCond.page = val;
      this.getRebateList();
    },
    /*
     *  返佣详情
     */
    handleRebateDetail(row) {
      this.rebateDetailVisiable = true;
      this.client_weixin_user_id = row.client_weixin_user_id;
      getRebateDetail({
        market_id: this.store_activity_id,
        client_weixin_user_id: row.client_weixin_user_id,
        ...this.detail_rebateCond,
      }).then((res) => {
        if (res.code === 1) {
          this.rebateDetailList = res.data.list;
          this.rebateDetailTotal = res.data.total;
          this.detail_count_total = res.data.count_total;
          this.detail_finish_price_total = res.data.finish_price_total;
          this.detail_price_total = res.data.price_total;
          this.detail_un_finish_price_total = res.data.un_finish_price_total;
        }
      });
    },
    handleHeight() {
      if (parseInt(this.offsetHeight) <= 62) {
        this.$message.warning("没有更多条件");
        return;
      }
      this.writeOffHeight =
        this.writeOffHeight === "55px" ? this.offsetHeight : "55px";
    },
    handleOrderHeight() {
      if (parseInt(this.offsetHeight) <= 62) {
        this.$message.warning("没有更多条件");
        return;
      }
      this.orderHeight =
        this.orderHeight === "55px" ? this.offsetHeight : "55px";
    },
    handleCurrentRebateDetailChange() {
      this.detail_rebateCond = {
        page: 1,
        page_num: 15,
      };
      getRebateDetail({
        market_id: this.store_activity_id,
        client_weixin_user_id: this.client_weixin_user_id,
        ...this.detail_rebateCond,
      }).then((res) => {
        if (res.code === 1) {
          this.rebateDetailList = res.data.list;
          this.rebateDetailTotal = res.data.total;
          this.detail_count_total = res.data.count_total;
          this.detail_finish_price_total = res.data.finish_price_total;
          this.detail_price_total = res.data.price_total;
          this.detail_un_finish_price_total = res.data.finish_price_total;
        }
      });
    },
    /*
     *  切换
     */
    changeTool(item) {
      this.selectTool = item;
      this.joinList = [];
      this.joinTotal = 0;
      this.drawnUserList = [];
      this.verifList = [];
      this.userScanList = [];
      this.userCond.page = 1;
      this.joinCond.page = 1;

      if (item === "参与记录") {
        this.getJoinList();
      }
      if (item === "浏览记录") {
        this.getDrawnUserList();
      }
      if (item === "中奖记录") {
        this.getUserList();
      }
      if (item === "核销记录") {
        this.getVerifList();
      }
      if (item === "订单记录") {
        this.getOrderList();
      }
      if (item === "返佣记录") {
        this.getRebateList();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  transition: all 0.3s;
  overflow: hidden;
  flex: 1;
  .middle {
    display: flex;
    overflow: hidden;
  }
}
.activityMain {
  display: flex;
  .templateImg {
    margin-left: 70px;
    margin-right: 30px;
    width: 230px;
    height: 393px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.toolTitle {
  display: flex;
  height: 50px;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-radius: 0 0 6px 6px;

  .box {
    width: 150px;
    text-align: center;
    line-height: 50px;
    font-size: 14px;
    color: #333333;
    .span {
      margin: -3px auto 0 auto;
      width: 20px;
      height: 4px;
      background: #5493f5;
      border-radius: 2px;
    }
  }
  .box:hover {
    cursor: pointer;
  }
}
.activityData {
  margin-top: 10px;
}
.activityTittle {
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 55px;
  line-height: 55px;
  font-size: 14px;
  font-weight: 600;

  .span {
    margin: auto 6px auto 0;
    width: 4px;
    height: 12px;
    background: #5493f5;
    border-radius: 2px;
  }
}

.prizeList {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  margin-left: 30px;
  .prizeBox {
    margin-right: 15px;
    margin-bottom: 15px;
    display: flex;
    padding: 15px 15px 15px 15px;
    opacity: 1;
    background: #f9f9f9;
    border-radius: 6px;

    .prizeImg {
      width: 80px;
      height: 80px;
      border-radius: 6px;
      img {
        width: 80px;
        height: 80px;
        border-radius: 6px;
        object-fit: cover;
      }
    }
    .prizeContent {
      padding-top: 8px;
      margin-left: 10px;
      .prizeName {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
.activityInfo {
  padding-top: 10px;
  width: 40%;
  .activityLine {
    margin-bottom: 15px;
    display: flex;
    .activityInfoTitle {
      width: 30%;
      text-align: right;
      color: #909399;
    }
    .content {
      width: 70%;
      text-align: left;
      margin-left: 20px;
    }
  }
}
.activityline {
  height: 1px;
  background: #e8e8e8;
}
.search {
  display: flex;
  padding-top: 10px;
  padding-left: 20px;
  opacity: 1;
  background: linear-gradient(0deg, #f3f9ff 1%, #ffffff 100%);
  font-size: 14px;
}

.dataList {
  margin: 30px 0 0 30px;
  display: flex;
  .dataBox {
    padding-top: 12px;
    margin-right: 20px;
    width: 180px;
    height: 88px;
    opacity: 1;
    background: #eef6ff;
    border-radius: 6px;
    .dataNum {
      font-size: 30px;
      text-align: center;
      font-weight: 600;
    }
    .dataText {
      margin-top: 3px;
      font-size: 16px;
      color: #333333;
      text-align: center;
    }
  }
}
.headImg {
  margin: 0 auto;
  width: 32px;
  height: 32px;
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}
.dataTip {
  margin: 15px 0 0 30px;
  color: #909399;
}

.writeData {
  display: flex;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  border-top: none;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: white;
  .line {
    margin: auto 30px;
    width: 1px;
    height: 26px;
    background: #e9e9e9;
  }
}
.goods_Img {
  margin: 8px auto;
  text-align: center;
  width: 54px;
  height: 54px;
  opacity: 1;
  img {
    width: 54px;
    height: 54px;
  }
}
</style>

<template>
  <div>
    <div class="card">
      <el-page-header
        @back="goBack" content="拆改单详情"
      >
      </el-page-header>
    </div>
    <Detail
      @onPrint="handlePrint"
      :showPrint="showPrint"
      :arr="arr"
      :rowList1="rowList1"
      :tableData1="tableData1"
      :rowList2="rowList2"
      :tableData2="tableData2"
      :exportUrl="'/store/goods.handling.Split/exportExcel'"
      :exportData="{
        split_id: this.propObj.value
      }"
      exportName="拆改单详情导出文件"
      @onChangePage1="handlePage"
      @onChangePage2="handlePage2"
      @onSizeChange="handleSizeChange"
      @onSizeChange2="handleSizeChange2"
    />
    <PrintConf function_code="allotExport" @onRowList="handlePrintRow" v-show="false" />
    <LocalPrint :show="false" :printRows="printRows" :printData="printData" :title="title" :dialogVisible="localPrintVisible" @onClose="localPrintVisible = false" />
   </div>
</template>

<script>
import storage from 'good-storage';
import moment from 'moment';
import Detail from '@/components/goods/detail/Index.vue';
import { detailReq, detailGoodsListReq, getLogListReq } from '@/api/goods/goodsHandler/change/detail.js';
import { getSplitPrintReq } from '@/api/goods/goodsHandler/change/list';
import PrintConf from "@/components/printConf/Index.vue";
import LocalPrint from "@/components/localPrint/Index.vue";

export default {
  data() {
    return {
      localPrintVisible: false,
      printData: {},
      printRows: [],
      page2: 1,
      page: 1,
      page_num: this.$store.state.pageSizes[0],
      limit: this.$store.state.pageSizes[0],
      userInfo: {},
      arr: [
        {
          id: 0,
          name: '拆改单号',
          value: '',
          prop: 'split_number',
        },
        {
          id: 1,
          name: '拆改数量',
          value: '',
          prop: 'split_total_num',
        },
        {
          id: 2,
          name: '审核状态',
          value: '',
          prop: 'split_status',
        },
        {
          id: 3,
          name: '创建人',
          value: '',
          prop: 'create_user_name',
        },
        {
          id: 4,
          name: '创建时间',
          value: '',
          prop: 'create_time',
        },
        {
          id: 5,
          name: '审核人员',
          value: '',
          prop: 'audit_user_name',
        },
        {
          id: 6,
          name: '审核时间',
          value: '',
          prop: 'audit_time',
        },
        {
          id: 7,
          name: '未通过原因',
          value: '',
          prop: 'audit_remark',
        },
        {
          id: 8,
          name: '备注',
          value: '',
          prop: 'remark',
        },
      ],
      arr2: [
        {
          id: 0,
          name: '调拨单号',
          value: '',
          prop: 'allot_number',
        },
        {
          id: 1,
          name: '签收状态',
          value: '',
          prop: 'allot_status_text',
        },
        {
          id: 2,
          name: '调拨数量',
          value: '',
          prop: 'allot_total_num',
        },
        {
          id: 3,
          name: '调出门店',
          value: '',
          prop: 'at_hierarchy_name',
        },
        {
          id: 4,
          name: '添加人',
          value: '',
          prop: 'create_store_user_name',
        },
        {
          id: 5,
          name: '添加时间',
          value: '',
          prop: 'create_time',
        },
        {
          id: 6,
          name: '调入仓库',
          value: '',
          prop: 'to_warehouse_name',
        },
        {
          id: 7,
          name: '签收人员',
          value: '',
          prop: 'create_time',
        },
        {
          id: 8,
          name: '签收时间',
          value: '',
          prop: 'audit_time_text',
        },
      ],
      rowList1: [
        {
          label: '序号',
          prop: 'index',
        },
        {
          label: '货品图片',
          prop: 'goods_pic',
          isImg: true,
        },
        {
          label: '货号',
          prop: 'goods_number',
          t_width: 200,
        },
        {
          label: '款号',
          prop: 'goods_style_number',
        },
        {
          label: '品名',
          prop: 'goods_name',
        },
        {
          label: '品类',
          prop: 'goods_type_name',
        },
        {
          label: '所属门店',
          prop: 'hierarchy_name',
          t_width: 120,
        },
        {
          label: '仓库',
          prop: 'warehouse_name',
        },
        {
          label: '数量',
          prop: 'num',
        },
        {
          label: '标签价',
          prop: 'sale_price',
        },
        {
          label: '销售工费',
          prop: '184',
        },
        {
          label: '销售工费类型',
          prop: '185',
          t_width: 120,
        },
        {
          label: '款式',
          prop: '101',
        },
        {
          label: '材质成色',
          prop: '100',
        },
        {
          label: '净金重',
          prop: '104',
        },
        {
          label: '主石重',
          prop: '130',
        },
        {
          label: '辅石1重量',
          prop: '133',
        },
        {
          label: '证书号',
          prop: '112',
          t_width: 160,
        },
      ],
      tableData1: {
        list: [],
        total: 0,
      },
      rowList2: [
        {
          label: '操作时间',
          prop: 'create_time',
          width: 200,
        },
        {
          label: '操作记录',
          prop: 'msg',
        },
        {
          label: '操作人',
          prop: 'create_user_name',
          width: 200,
        },
      ],
      tableData2: {
        list: [],
        total: 0,
      },
    };
  },
  components: {
    Detail,
    PrintConf,
    LocalPrint,
  },
  props: {
    title: String,
    showPrint: Boolean,
    activeName: String,
    allot_id: Number,
    propObj: {
      type: Object,
      default() {
        return {
          key: '',
          value: '',
        };
      },
    },
  },
  created() {
    const userInfo = storage.get('userInfo');
    this.userInfo = userInfo;
    this.getDetail();
    this.getDetailGoods();
    this.getLogList();
  },
  methods: {
    getSplitPrint(cond) {
      getSplitPrintReq({ ...cond })
        .then((res) => {
          if (res.code === 1) {
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            res.data.top = [];
            const data = res.data.data;
            const arr1 = [
              { txt: '创建人', value: data.create_user_name, width: '24%' },
              { txt: '创建时间', value: data.create_time, width: '24%' },
              { txt: '拆改单号', value: data.split_number, width: '30%' },
            ];
            const arr4 = [
              { txt: "门店", value: data.hierarchy_name, width: "24%" },
            ];
            res.data.bottom = [];
            const arr2 = [
              { txt: '备注', value: data.audit_remark, width: '100%' },
            ];
            const arr3 = [
              { txt: '审核状态', value: data.split_status_text, width: '24%' },
              { txt: '审核人', value: data.audit_user_name, width: '24%' },
              { txt: '审核时间', value: data.audit_time ? data.audit_time : '', width: '30%' },
            ];
            res.data.top.push(arr1, arr4);
            res.data.bottom.push(arr2, arr3);
            // 计算合计
            this.printData = res.data;
            const sum = {
              index: '合计',
            };
            const decimal = {};
            this.printData.list.forEach((item) => {
              this.printRows.forEach((row) => {
                if (row.is_sum) {
                  if (!sum[row.field_alias]) {
                    sum[row.field_alias] = 0;
                  }
                  if (item[row.field_alias]) {
                    sum[row.field_alias] += Number(item[row.field_alias]);
                    if (!decimal[row.field_alias]) {
                      const arr = String(item[row.field_alias]).split('.');
                      if (arr.length === 2) {
                        decimal[row.field_alias] = arr[1].length;
                      } else {
                        decimal[row.field_alias] = 0;
                      }
                    }
                  }
                }
              });
            });
            // 需要保留多少位小数
            Object.keys(sum).forEach((item) => {
              if (item !== 'index') {
                sum[item] = sum[item].toFixed(decimal[item]);
              }
            });
            this.printData.list.push(sum);
            this.localPrintVisible = true;
          }
        });
    },
    handlePrint() {
      this.getSplitPrint({
        [this.propObj.key]: this.propObj.value,
      });
    },
    handlePrintRow(rows) {
      this.printRows = rows;
    },
    getLogList() {
      getLogListReq({ split_id: this.propObj.value, page: this.page2, limit: this.limit })
        .then((res) => {
          if (res.code === 1) {
            res.data.list.forEach((item) => {
              item.create_time && (item.create_time = moment(item.create_time * 1000).format('YYYY-MM-DD HH:mm:ss'));
            });
            this.tableData2 = res.data;
          }
        });
    },
    getDetail() {
      detailReq(this.propObj.value)
        .then((res) => {
          if (res.code === 1) {
            const data = res.data;
            data.split_status = (data.split_status === 10? '待审核': data.split_status === 20? '审核通过': data.split_status === 30? '审核不通过': '');
            data.audit_time && (data.audit_time = moment(data.audit_time * 1000).format('YYYY-MM-DD HH:mm:ss'));
            data.create_time && (data.create_time = moment(data.create_time * 1000).format('YYYY-MM-DD HH:mm:ss'));
            this.arr.forEach((item) => {
              item.value = (res.data[item.prop] ? res.data[item.prop] : '--');
            });
          }
        });
    },
    getDetailGoods() {
      detailGoodsListReq({ [this.propObj.key]: this.propObj.value, page: this.page, limit: this.page_num })
        .then((res) => {
          if (res.code === 1) {
            this.tableData1 = res.data;
          }
        });
    },
    goBack() {
      this.$emit('hide');
    },
    handleSizeChange2(limit) {
      this.page = 1;
      this.limit = limit;
      this.getLogList();
    },
    handleSizeChange(page_num) {
      this.page = 1;
      this.page_num = page_num;
      this.getDetailGoods();
    },
    handlePage(page) {
      this.page = page;
      this.getDetailGoods();
    },
    handlePage2(page) {
      this.page2 = page;
      this.getLogList();
    },
  },
};
</script>

<template>
  <div class="previewMain">
    <div class="phone">
      <div class="tip">
        <img :src="previewTempalteInfo.market_template_image" />
      </div>
    </div>
    <div class="previewAbout">
      <div class="tittle">
        <div class="span"></div>
        活动二维码/链接
      </div>
      <div class="qrcode">
        <div id="qrCodeDiv" ref="qrCodeDiv"></div>
      </div>
      <div class="qrTip">
        <div>微信扫一扫预览效果</div>
      </div>

      <div
        style="
          margin-top: 5px;
          text-align: center;
          color: #909399;
          font-size: 12px;
        "
      >
        您可将活动链接复制到您的公众号菜单
      </div>
      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="chooseTemplate()">选择模板</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      qrcode: null,
    };
  },
  mounted() {
    this.handleQRCode();
  },
  beforeDestroyed() {
    console.log(this.previewTempalteInfo);
    this.qrcode.clear();
  },
  props: {
    previewTempalteInfo: Object,
  },
  methods: {
    handleQRCode() {
      if (!this.qrcode) {
        this.qrcode = new QRCode("qrCodeDiv", {
          text: this.previewTempalteInfo.h5_url_preview,
          width: 220,
          height: 220,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
        });
      } else {
        this.qrcode.clear();
      }
    },
    /*
     *  选择模板
     */
    chooseTemplate() {
      this.$emit("hide", this.previewTempalteInfo);
    },
  },
};
</script>


<style lang="less" scoped>
.previewMain {
  margin: 10px 60px 30px 60px;
  display: flex;
  .phone {
    padding-top: 65px;
    width: 290px;
    height: 524px;
    background-image: url("../../../../assets/images/activity/shouji.png");
    background-repeat: no-repeat;
    background-size: contain;
    .tip {
      margin: 0 auto 0 auto;
      width: 94.5%;
      max-height: 98.5%;
      scrollbar-width: none;
      -ms-overflow-style: none;
      overflow-x: hidden;
      overflow-y: scroll;
      border-radius: 0 0 25px 25px;
      color: #909399;
      font-size: 14px;

      img {
        width: 100%;
      }
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .previewAbout {
    margin: 0 0 0 60px;
    .tittle {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 55px;
      line-height: 55px;
      font-size: 14px;
      font-weight: 600;

      .span {
        margin: auto 6px auto 0;
        width: 4px;
        height: 12px;
        background: #5493f5;
        border-radius: 2px;
      }
    }
    .qrcode {
      padding: 40px;
      width: 220px;
      height: 220pxf;
      opacity: 1;
      background: #ffffff;
      border: 1px solid #e2e2e2;
      border-radius: 5px 5px 0 0;
    }
    .qrTip {
      height: 62px;
      line-height: 62px;
      font-size: 18px;
      background: #ececec;
      text-align: center;

      .previewMainQrcode {
        text-decoration: underline;
        color: #2791ff;
        line-height: 32px;
      }
      .previewMainQrcode:hover {
        cursor: pointer;
      }
    }
    .link {
      margin-top: 50px;
      width: 345px;
      padding: 3px 0 3px 14px;
      opacity: 1;
      background: #ffffff;
      border: 1px solid #e2e2e2;
      border-radius: 5px 0px 0 5px;
      color: #909399;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
    .copy {
      margin-top: 50px;
      width: 60px;
      padding: 3px 0 3px 0;
      opacity: 1;
      background: rgba(39, 145, 255, 0.06);
      border: 1px solid #2791ff;
      border-radius: 0 5px 5px 0;
      text-align: center;
      color: #2791ff;
    }
    .copy:hover {
      cursor: pointer;
    }
  }
}
</style>
import { post } from '@/utils/request';

// 会员体系列表
const getList = (data) => post({
    url: '/store/customer.ClientSystem/list',
    data: data
});

// 创建会员体系
const create = (data) => post({
    url: '/store/customer.ClientSystem/create',
    data: data
});

// 编辑修改会员体系
const update = (data) => post({
    url: '/store/customer.ClientSystem/update',
    data: data
});

// 会员体系详情
const detail = (data) => post({
    url: '/store/customer.ClientSystem/detail',
    data: data
});

// 会员体系删除
const deleteSys = (data) => post({
    url: '/store/customer.ClientSystem/delete',
    data: data
});

// 会员体系绑定列表
const systemBindingList = (data) => post({
    url: '/store/customer.ClientSystemBinding/list',
    data: data
});

// 会员体系绑定添加
const systemBindingCreate = (data) => post({
    url: '/store/customer.ClientSystemBinding/create',
    data: data
});

// 会员体系绑定更新
const systemBindingUpdate = (data) => post({
    url: '/store/customer.ClientSystemBinding/update',
    data: data
})

// 会员体系绑定删除
const systemBindingDelete = (data) => post({
    url: '/store/customer.ClientSystemBinding/delete',
    data: data
})

// 会员体系绑定的体系列表 
const getBindingClientSystemList = (data) => post({
    url: '/store/common.Select/getBindingClientSystemList',
    data: data
})
const getClientSystemList = (data) => post({
    url: '/store/common.Select/getClientSystemList',
    data: data
})

// 会员体系绑定详情
const getClientSystemInfo = (data) => post({
    url: '/store/customer.ClientSystemBinding/detail',
    data: data
})


// 会员体系绑定的门店列表
const getClientSystemHierarchyList = (data) => post({
    url: '/store/common.Select/getClientSystemHierarchyList',
    data: data
})



export {
    getList,
    create,
    update,
    detail,
    deleteSys,
    systemBindingList,
    systemBindingCreate,
    systemBindingUpdate,
    systemBindingDelete,
    getClientSystemList,
    getClientSystemHierarchyList,
    getClientSystemInfo,
    getBindingClientSystemList
 

}
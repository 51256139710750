var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"previewMain"},[_c('div',{staticClass:"phone"},[_c('div',{staticClass:"tip"},[_c('img',{attrs:{"src":_vm.activityInfo.market_template_image}})])]),_c('div',{staticClass:"previewAbout"},[_vm._m(0),_c('div',{staticClass:"qrcode"},[_c('div',{ref:"qrCodeDiv",attrs:{"id":"qrCodeDiv"}})]),(_vm.activityInfo.status === 20)?_c('div',{staticClass:"qrTip"},[_c('div',{staticClass:"previewMainQrcode",on:{"click":_vm.dowload}},[_vm._v("下载二维码")]),_c('div',[_vm._v("1000*1000像素")])]):_vm._e(),_c('div',{staticStyle:{"display":"flex","width":"300px"}},[_c('div',{staticClass:"link"},[_vm._v(" "+_vm._s(_vm.activityInfo.status === 20 ? _vm.activityInfo.h5_url : _vm.activityInfo.h5_url_preview)+" ")]),_c('div',{staticClass:"copy",attrs:{"data-clipboard-text":"复制"},on:{"click":function($event){return _vm.copylogistics(
            _vm.activityInfo.status === 20
              ? _vm.activityInfo.h5_url
              : _vm.activityInfo.h5_url_preview
          )}}},[_vm._v(" 复制 ")])]),_c('div',{staticStyle:{"margin-top":"5px","text-align":"center","color":"#909399","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.activityInfo.status !== 20 ? "注：以上二维码和地址并非正式链接，仅供预览活动使用" : "您可将活动链接复制到您的公众号菜单")+" ")]),_c('div',{ref:"verifCode",staticStyle:{"display":"none"},attrs:{"id":"verifCode"}}),_vm._m(1),_c('div',{staticStyle:{"display":"flex"}},[_c('el-select',{staticStyle:{"width":"180px"},attrs:{"placeholder":"请选择门店"},on:{"change":_vm.handleStore},model:{value:(_vm.hierarchy_id),callback:function ($$v) {_vm.hierarchy_id=$$v},expression:"hierarchy_id"}},[_vm._l((_vm.storeList),function(item,index){return [_c('el-option',{key:index,attrs:{"label":item.hierarchy_name,"value":item.hierarchy_id}})]})],2),_c('el-button',{staticStyle:{"margin-left":"15px"},attrs:{"type":"primary","plain":""},on:{"click":_vm.dowloadVerifCode}},[_vm._v("下载核销二维码")])],1),(_vm.activityInfo.status !== 20)?_c('div',{staticStyle:{"text-align":"center","margin-top":"40px"}},[_c('el-button',{attrs:{"type":"primary","plain":""},on:{"click":_vm.handleEdit}},[_vm._v("进入编辑")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.updateStatus(20)}}},[_vm._v("立即发布")])],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tittle"},[_c('div',{staticClass:"span"}),_vm._v(" 活动二维码/链接 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tittle"},[_c('div',{staticClass:"span"}),_vm._v(" 活动核销二维码 ")])
}]

export { render, staticRenderFns }
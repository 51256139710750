import { post } from '@/utils/request';

// 调拨详情
const detailReq = (split_id) => post({
  url: '/store/goods.handling.Split/detail',
  data: {
    split_id,
  },
});

// 调拨详情--商品列表
const detailGoodsListReq = ({ split_id, page, limit }) => post({
  url: '/store/goods.handling.Split/detailGoodsList',
  data: {
    split_id,
    page,
    limit,
  },
});

// 日志列表
const getLogListReq = ({ split_id, page, limit }) => post({
  url: '/store/goods.handling.Split/getLogList',
  data: {
    split_id,
    page,
    limit,
  },
});

export {
  detailReq,
  detailGoodsListReq,
  getLogListReq,
};

<template>
  <div>
    <el-form
      :model="activityInfo"
      class="middle"
      label-width="170px"
      ref="activityInfo"
      :rules="rules"
    >
      <div style="margin-bottom: 30px">
        <div
          class="speed"
          :style="speed !== '选择模板' ? ' border-radius:7px' : ''"
        >
          <div class="li" style="color: #2791ff">
            <img
              src="../../../../assets/images/activity/icon_current.png"
              v-if="speed === '选择模板'"
            />
            <img
              src="../../../../assets/images/activity/icon_carry_out.png"
              v-if="speed !== '选择模板'"
            />
            选择模板
            <i class="right"></i>
          </div>
          <div
            class="li"
            :style="speed !== '选择模板' ? 'color: #2791ff' : null"
          >
            <img
              src="../../../../assets/images/activity/icon_not_selected.png"
              v-if="!['活动设置', '奖品设置', '完成'].includes(speed)"
            />
            <img
              src="../../../../assets/images/activity/icon_current.png"
              v-if="speed === '活动设置'"
            />
            <img
              src="../../../../assets/images/activity/icon_carry_out.png"
              v-if="speed === '奖品设置' || speed === '完成'"
            />
            活动设置
            <i class="right"></i>
          </div>
          <div
            class="li"
            :style="
              speed !== '选择模板' && speed !== '活动设置'
                ? 'color: #2791ff'
                : null
            "
          >
            <img
              src="../../../../assets/images/activity/icon_not_selected.png"
              v-if="speed !== '奖品设置' && speed !== '完成'"
            />
            <img
              src="../../../../assets/images/activity/icon_current.png"
              v-if="speed === '奖品设置'"
            />
            <img
              src="../../../../assets/images/activity/icon_carry_out.png"
              v-if="speed === '完成'"
            />{{
              templateInfo.market_template_category_id === 2 ||
              activityInfo.market_template_category_id === 2
                ? "商品设置"
                : "奖品设置"
            }}
            <i class="right"></i>
          </div>
          <div
            class="li"
            :style="
              !['活动设置', '奖品设置', '选择模板'].includes(speed)
                ? 'color: #2791ff'
                : null
            "
          >
            <img
              src="../../../../assets/images/activity/icon_not_selected.png"
              v-if="speed !== '完成'"
            />
            <img
              src="../../../../assets/images/activity/icon_carry_out.png"
              v-if="speed === '完成'"
            />完成
          </div>
        </div>
        <div class="search" v-if="speed === '选择模板'">
          <div class="search-li">
            <div class="type">活动类型</div>
            <div class="line"></div>
            <template v-for="(item, index) in marketTemplateCategoryList">
              <div
                class="box"
                :style="
                  market_template_category_id ===
                  String(item.market_template_category_id)
                    ? 'color: #2791FF'
                    : ''
                "
                :key="index"
                @click="chooseMarekCategoryId(item.market_template_category_id)"
              >
                {{ item.market_template_category_name }}
              </div>
            </template>
          </div>
          <div class="search-li">
            <div class="type">模板类型</div>
            <div class="line"></div>
            <template v-for="(item, index) in marketTemplateTypeList">
              <div
                class="box"
                :key="index"
                :style="
                  market_template_type_id ===
                  String(item.market_template_type_id)
                    ? 'color: #2791FF'
                    : ''
                "
                @click="chooseMarketTypeId(item.market_template_type_id)"
              >
                {{ item.market_template_type_name }}
              </div>
            </template>
          </div>
          <div class="search-li">
            <div class="type">模板标签</div>
            <div class="line"></div>
            <template v-for="(item, index) in marketTemplateLableList">
              <div
                class="box"
                :key="index"
                :style="
                  market_template_lable_id ===
                  String(item.market_template_lable_id)
                    ? 'color: #2791FF'
                    : ''
                "
                @click="chooseMarketLableId(item.market_template_lable_id)"
              >
                {{ item.market_template_lable_title }}
              </div>
            </template>
          </div>
        </div>
        <div class="templateList" v-if="speed === '选择模板'">
          <template v-for="(item, index) in marketTemplateList">
            <div
              @mouseenter="
                () => {
                  templateIndex = item.market_template_id;
                }
              "
              @mouseleave="
                () => {
                  templateIndex = 0;
                }
              "
              class="templateBox"
              :key="index"
              :style="(index + 1) % 6 !== 0 ? 'margin-right:10px' : ''"
            >
              <div
                class="templateImage"
                :style="
                  'background-image: url(' +
                  item.market_template_image_show +
                  ')'
                "
              ></div>
              <div
                class="templateChoose"
                v-if="templateIndex === item.market_template_id"
              >
                <div class="review" @click="previewTemplate(item)">预览</div>
                <div class="choose" @click="chooseTemplate(item)">选择模板</div>
              </div>
              <div class="templateName">{{ item.market_template_title }}</div>
            </div>
          </template>
        </div>
        <div class="activitySetUp" v-if="speed === '活动设置'">
          <div class="theme">
            <div class="img">
              <div class="tip">
                <img :src="market_template_image" />
              </div>
            </div>
          </div>
          <ActivitySetUp
            :activityInfo="activityInfo"
            :activityStatus="activityStatus"
            :systemList="systemList"
            :token="token"
            :qr.sync="qr"
            :voting_adverts.sync="voting_adverts"
            :head_image.sync="head_image"
            :share_image.sync="share_image"
            :images="images"
            :activityImage="activityImage"
            :templateInfo="templateInfo"
            :joinHierarchyId.sync="joinHierarchyId"

            :music_name.sync="music_name"
            :speed="speed"
            :activityDetail.sync="activityDetail"
            :type="type"
          />
        </div>
        <div class="prizeSetUp" v-if="speed === '奖品设置'">
          <div class="theme">
            <div class="img">
              <div class="tip">
                <img :src="market_template_image" />
              </div>
            </div>
          </div>
          <div class="prizeMain">
            <div class="info">
              <div
                class="prize"
                v-if="
                  activityInfo.market_template_category_id === 1 ||
                  activityInfo.market_template_category_id === 5 ||
                   activityInfo.market_template_category_id === 7
                "
              >
                <div class="title">
                  <div class="span"></div>
                  抽奖区设置
                </div>
                <div class="cutLine" style="margin-bottom: 20px"></div>
              </div>
              <PrizeSet
                v-if="
                  activityInfo.market_template_category_id === 1 ||
                  activityInfo.market_template_category_id === 5 ||
                  activityInfo.market_template_category_id === 7
                "
                :activityInfo.sync="activityInfo"
                :token="token"
                :hit_odds_sum.sync="hit_odds_sum"
                :type="type"
                :activityStatus="activityStatus"
              />
              <div
                v-if="
                  [2, 3, 5, 6, 7].includes(activityInfo.market_template_category_id)
                "
              >
                <div class="prize">
                  <div class="title">
                    <div class="span"></div>
                    <span v-if="activityInfo.market_template_category_id != 7">商品设置</span>
                    <span v-else>开启秒杀</span>
                    <el-tooltip
                      effect="dark"
                      content="开启后，用户可以在页面购买商品，线下店铺领取商品"
                      placement="top"
                    >
                      <div
                        class="el-icon-info"
                        style="margin: 18px 0 0 5px; color: #409eff"
                      ></div>
                    </el-tooltip>
                    <el-switch
                      v-if="activityInfo.market_template_category_id === 5 || activityInfo.market_template_category_id === 7"
                      v-model="activityInfo.is_presell"
                      active-value="10"
                      inactive-value="0"
                      style="margin: 15px 0 0 5px"
                    />
                  </div>
                  <div class="cutLine" style="margin-bottom: 20px"></div>
                </div>
                <div v-if="activityInfo.market_template_category_id === 5 || activityInfo.market_template_category_id === 7">
                  <GoodSet
                    v-if="
                      activityInfo.is_presell &&
                      activityInfo.is_presell === '10'
                    "
                    :activityInfo.sync="activityInfo"
                    :templateInfo="templateInfo"
                    :token="token"
                    :type="type"
                    :activityStatus="activityStatus"
                  />
                </div>
                <div v-else>
                  <GoodSet
                    :activityInfo.sync="activityInfo"
                    :templateInfo="templateInfo"
                    :token="token"
                    :type="type"
                    :activityStatus="activityStatus"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="finsh" v-if="speed === '完成'">
          <div class="theme">
            <div class="img">
              <div class="tip">
                <img :src="market_template_image" />
              </div>
            </div>
          </div>
        </div>
        <div class="create" v-if="speed === '活动设置' || speed === '奖品设置'">
          <div
            style="margin: 15px auto 0 auto;text-align: center display: flex"
          >
            <el-button
              type="success"
              v-if="type === 'add' || activityStatus === 50"
              @click="submit(1)"
              >存为草稿</el-button
            >
            <el-button
              type="primary"
              plain
              v-if="type === 'edit'"
              @click="goBack"
              >返回列表</el-button
            >
            <el-button
              type="primary"
              plain
              @click="lastStep"
              v-if="type === 'add' || (type === 'edit' && speed === '奖品设置')"
              >上一步</el-button
            >
            <el-button
              type="primary"
              v-if="speed === '活动设置'"
              @click="nextStep('activityInfo')"
              >下一步</el-button
            >
            <el-button
              type="primary"
              v-if="speed === '奖品设置'"
              @click="submit(2)"
              >完成</el-button
            >
          </div>
        </div>
        <div class="create" v-if="speed === '完成'">
          <div
            style="margin: 15px auto 0 auto;text-align: center display: flex"
          >
            <el-button type="primary" plain>返回列表</el-button>
            <el-button type="primary">立即发布</el-button>
          </div>
        </div>
      </div>
      <el-dialog
        :fullscreen="false"
        title="预览模板"
        :visible.sync="previewTemplateVisiable"
        :before-close="handleClosePreviewTemplate"
      >
        <ReviewTemplate
          v-if="previewTemplateVisiable"
          :previewTempalteInfo="previewTempalteInfo"
          @hide="chooseTemplate"
        />
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import storage from "good-storage";
import ReviewTemplate from "./previewTemplate";
import { getBindingClientSystemList } from "@/api/member/system/Index.js";
import {
  createActivity,
  updateActivity,
  getActivityDetail,
  getMarketTemplateLableList,
  getMarketTemplateTypeList,
  getMarketTemplateCategoryList,
  getMarketTemplateList,
  getMarketTemplateDetail,
  getGoodsByHierarchy,
  getMerchantList,
} from "@/api/marketing/activity/index";
import PrizeSet from "./prizeSet.vue";
import GoodSet from "./goodSet.vue";
import ActivitySetUp from "./activitySetUp.vue";
export default {
  data() {
    return {
      previewTempalteInfo: {},
      previewTemplateVisiable: false,
      activityStatus: 0,
      market_template_category_name: "",
      type: "add",
      hit_odds_sum: 0,
      systemList: [],
      rules: {
        voting_giving_way: [
          {
            required: true,
            message: "请完善赠送投票票数方式",
            trigger: "blur",
          },
        ],
        voting_game: [
          {
            required: true,
            message: "请选择投票玩法",
            trigger: "blur",
          },
        ],
        title: [
          {
            required: true,
            message: "请完善活动标题",
            trigger: "blur",
          },
        ],
        client_system_id: [
          {
            required: true,
            message: "请完善会员体系",
            trigger: "change",
          },
        ],
        verif_password: [
          {
            required: true,
            message: "请完善核销密码",
            trigger: "blur",
          },
        ],
        total_join_status: [
          {
            required: true,
            message: "请完善总抽奖机会",
            trigger: "change",
          },
        ],
        day_join_status: [
          {
            required: true,
            message: "请完善每日抽奖机会",
            trigger: "change",
          },
        ],
        music: [
          {
            required: true,
            message: "请完善背景音乐",
            trigger: "change",
          },
        ],
        explain: [
          {
            required: true,
            message: "请完善活动说明",
            trigger: "blur",
          },
        ],
        checkAll: [
          {
            required: true,
            message: "请完善活动时间",
            trigger: "change",
          },
        ],
        confirm_status: [
          {
            required: true,
            message: "请完善核销时间",
            trigger: "change",
          },
        ],
        share_title: [
          {
            required: true,
            message: "请完善分享标题",
            trigger: "blur",
          },
        ],
        share_content: [
          {
            required: true,
            message: "请完善分享内容",
            trigger: "blur",
          },
        ],
        share_status: [
          {
            required: true,
            message: "请完善分享增加次数",
            trigger: "change",
          },
        ],
        show_scan: [
          {
            required: true,
            message: "请完善浏览人数",
            trigger: "change",
          },
        ],
        apply_time: [
          {
            required: true,
            trigger: "change",
            validator: (rule, value, callback) => {
              if (
                !this.activityInfo.apply_start_time ||
                !this.activityInfo.apply_end_time
              ) {
                callback(new Error("请完善报名时间"));
              } else {
                callback();
              }
            },
          },
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (
                !this.activityInfo.apply_start_time ||
                !this.activityInfo.apply_end_time
              ) {
                callback(new Error("请完善报名时间"));
              } else {
                callback();
              }
            },
          },
        ],
        activity_time: [
          {
            required: true,
            trigger: "change",
            validator: (rule, value, callback) => {
              if (
                !this.activityInfo.start_time ||
                !this.activityInfo.end_time
              ) {
                callback(new Error("请完善活动时间"));
              } else {
                callback();
              }
            },
          },
        ],
        activity_show_time: [
          {
            required: true,
            trigger: "change",
            validator: (rule, value, callback) => {
              if (
                !this.activityInfo.start_time ||
                !this.activityInfo.end_time
              ) {
                callback(new Error("请完善活动展示时间"));
              } else {
                callback();
              }
            },
          },
        ],
        joinHierarchyId: [
          {
            required: true,
            validator: (rule, value, callback) => {
              console.log(1)
              if (this.joinHierarchyId.length === 0) {
                callback("请选择参与门店");
              } else {
                callback();
              }
            },
          },
        ],
        verify_time: [
          {
            required: true,
            trigger: "change",
            validator: (rule, value, callback) => {
              if (
                !this.activityInfo.confirm_start_time ||
                !this.activityInfo.confirm_end_time
              ) {
                callback(new Error("请完善核销时间"));
              } else {
                callback();
              }
            },
          },
        ],
        is_gamble: [
          {
            required: true,
            message: "请完善抽奖类型",
            trigger: "change",
          },
        ],
        get_draw_opportunities_way: [
          {
            required: true,
            message: "请完善抽奖次数",
            trigger: "change",
          },
        ],
        total_join_number: [
          {
            required: true,
            message: "请完善总抽奖次数",
            trigger: "blur",
          },
        ],
        day_join_number: [
          {
            required: true,
            message: "请完善每日抽奖次数",
            trigger: "blur",
          },
        ],
        pay_draw: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if(value>0){
                callback();
              }else{
                callback(new Error("抽奖支付金额必须大于0"));
              }
            }
          },
        ],
        pay_limit: [
          {
            required: true,
            message: "请完善购买限制次数",
            trigger: "change",
          },
        ],
        pay_limit_status: [
          {
            required: true,
            message: "请完善购买限制",
            trigger: "change",
          },
        ],
        hit_odds: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!this.activityInfo.participateiInfo.hit_odds) {
                callback("请完善中奖概率");
              } else {
                callback();
              }
            },
          },
        ],
      },
      activityInfo: {},
      joinHierarchyId: [],
      speed: "选择模板",
      token: "",
      selectPrize: 0,
      marketTemplateCategoryList: [],
      marketTemplateLableList: [],
      marketTemplateTypeList: [],
      marketTemplateList: [],
      templateInfo: {},
      templateIndex: 0,
      voting_adverts: "",
      qr: "",
      head_image: "",
      share_image: "",
      activityImage: [],
      images: [],
      market_template_id: 0,
      market_template_category_id: "",
      market_template_lable_id: "",
      market_template_type_id: "",
      market_template_image: "",
      music_name: null,
      activityDetail: null,
    };
  },
  components: {
    ReviewTemplate,
    PrizeSet,
    GoodSet,
    ActivitySetUp,
  },
  created() {
    this.token = Cookies.get("token") || storage.session.get("token");
    if (this.store_activity_id && this.store_activity_id !== 0) {
      this.type = "edit";
      this.getActivityDetail();
    } else if (this.store_activity_id === 0) {
      this.speed = "选择模板";
      this.activityInfo = {
        prizeList: [],
        goodsList: [],
      };
    }
    this.getMarketTemplateLableList();
    this.getMarketTemplateCategoryList();
    this.getMarketTemplateTypeList();
    this.getMarketTemplateList();
    this.getBindingClientSystemList();
  },
  activated() {
    this.speed = "选择模板";
    this.activityInfo = {
      prizeList: [],
      goodsList: [],
    };
    this.joinHierarchyId = [];
    this.checkAll = false;
    this.activityImage = [];
    this.activityDetail = null;
  },

  props: {
    store_activity_id: Number,
    store_category_id: Number,
  },
  methods: {
    /*
     *  会员体系下拉数据
     */
    getBindingClientSystemList() {
      getBindingClientSystemList().then((res) => {
        if (res.code === 1) {
          this.systemList = res.data;
        }
      });
    },
    /*
     * 校验输入的数量值
     */
    handleGoods_count(e, indexs) {
      this.prizeList.forEach((item, index) => {
        if (index === this.selectPrize) {
          item.goods_count_json.forEach((i, jsonIndex) => {
            if (jsonIndex === indexs) {
              if (Number(e) > i.g_count) {
                i.goods_count = 0;
                i.goods_count_new = 0;
                this.$message({
                  type: "error",
                  message: "奖品数量不能大于库存数量",
                });
              }
            }
          });
        }
      });
    },
    /*
     *  获取活动详情
     */
    getActivityDetail() {
      getActivityDetail({
        market_id: this.store_activity_id,
        market_template_category_id: this.store_category_id,
      }).then((res) => {
        if (res.code === 1) {
          this.activityStatus = res.data.status;
          this.activityInfo = {
            ...res.data,
            apply_time: true,
            ...res.data.market_json,
            ...res.data.style_json,
            ...res.data.market_template_json,
            total_join_status:
              res.data.market_json.total_join_number === "0" ? 10 : 20,
            day_join_status:
              res.data.market_json.day_join_number === "0" ? 10 : 20,
            total_lottery_status:
              res.data.market_json.total_lottery_number === "0" ? 10 : 20,
            day_lottery_status:
              res.data.market_json.day_lottery_number === "0" ? 10 : 20,
            giving_draw:
              res.data.market_json.day_join_number !== "0" ? "10" : "20",
            total_join_number: res.data.market_json.total_join_number,
            day_join_number: res.data.market_json.day_join_number,
            total_lottery_number: res.data.market_json.total_lottery_number,
            day_lottery_number: res.data.market_json.day_lottery_number,
            share_status: res.data.market_json.share_status,
            rebate_status: res.data.market_json.rebate_status,
            rebate_prize: res.data.market_json.rebate_prize,
            share_add_number: res.data.market_json.share_add_number,
            share_day_number: res.data.market_json.share_day_number,
            same_time_status: Number(res.data.market_json.same_time_status),
            is_barrage: Number(res.data.market_json.is_barrage),
            inventory_status: Number(res.data.market_json.inventory_status),
            prizeList: [],
            goodsList: [],
            pay_limit_status: res.data.pay_limit !== 0 ? "10" : "20",
            participateiInfo:
              res.data.market_json.total_lottery_number !== "0" ||
              res.data.market_json.day_lottery_number !== "0"
                ? res.data.market_goods.find((item) => item.type === 30)
                : {},
          };
          console.log(this.activityInfo);
          // 投票新增结束
          this.market_template_category_name =
            res.data.market_template_category_name;
          this.market_template_id = res.data.market_template_id;
          this.share_image = res.data.share_image;
          this.head_image = res.data.head_image;
          // 投票二维码和广告
          this.qr = res.data.style_json.qr;
          this.voting_adverts = res.data.style_json.voting_adverts;
          // 投票二维码和广告
          this.music_name = res.data.music_name;
          this.market_template_image = res.data.market_template_image;
          this.joinHierarchyId = res.data.join_hierarchy_id.split(",");
          // 如果不是投票的
          if (res.data.market_template_category_id != 7) {
            this.activityInfo.prizeList = res.data.market_goods
            .filter((item) => item.market_goods_type === 10 && item.type === 10)
            .map((item) => {
              this.hit_odds_sum += Number(item.hit_odds);
              var info = { ...item };
              info.inventory_type = 'inc';
              info.goods_count_old = item.goods_count;
              if (res.data.status === 30) {
                item.goods_count_json.map((items, indexs) => {
                  items.goods_count_old = items.goods_count;
                  return items;
                });
              }
              return info;
            });
          } else {
            // 如果是投票的
            this.activityInfo.prizeList = res.data.market_goods.filter((item) => {
              return item.type == 10 && item.market_goods_type == 40
            })
            this.activityInfo.participateiInfo = res.data.market_goods.find((item) => {
              return item.type == 30 && item.market_goods_type == 40
            })
          }

          this.activityInfo.goodsList = res.data.market_goods
            .filter(
              (item) =>
                item.market_goods_type === 20 || item.market_goods_type === 30 || item.market_goods_type === 50
            )
            .map((item) => {
              var info = { ...item };
              info.inventory_type = 'inc';
              info.goods_count_old = item.goods_count;
              if (res.data.status === 30) {
                item.goods_count_json.map((items, indexs) => {
                  items.goods_count_old = items.goods_count;
                  return items;
                });
              }
              return info;
            });
          console.log('请求完成', this.activityInfo.goodsList);

          if (
            res.data.market_json.total_lottery_number !== "0" ||
            res.data.market_json.day_lottery_number !== "0"
          ) {
            this.hit_odds_sum =
              this.hit_odds_sum +
              Number(this.activityInfo.participateiInfo.hit_odds);
          }
          this.speed = "活动设置";
        }
      });
    },

    /*
     *  根据模板分类，筛选模板数据
     */
    chooseMarekCategoryId(id) {
      this.market_template_category_id = String(id);
      this.getMarketTemplateList();
    },
    /*
     * 根据模板类型，筛选模板数据
     */
    chooseMarketTypeId(id) {
      this.market_template_type_id = String(id);
      this.getMarketTemplateList();
    },
    /*
     *  根据模板标签，筛选模板数据
     */
    chooseMarketLableId(id) {
      this.market_template_lable_id = String(id);
      this.getMarketTemplateList();
    },
    /*
     *  模板标签
     */
    getMarketTemplateLableList() {
      getMarketTemplateLableList().then((res) => {
        if (res.code === 1) {
          this.marketTemplateLableList = res.data.list;
          this.marketTemplateLableList.unshift({
            market_template_lable_id: "",
            market_template_lable_title: "全部",
          });
        }
      });
    },
    /*
     *  模板分类
     */
    getMarketTemplateCategoryList() {
      getMarketTemplateCategoryList().then((res) => {
        if (res.code === 1) {
          this.marketTemplateCategoryList = res.data.list;
          this.marketTemplateCategoryList.unshift({
            market_template_category_id: "",
            market_template_category_name: "全部",
          });
        }
      });
    },
    /*
     *  模板类型
     */
    getMarketTemplateTypeList() {
      getMarketTemplateTypeList({ market_template_category_id: "" }).then(
        (res) => {
          if (res.code === 1) {
            this.marketTemplateTypeList = res.data.list;
            this.marketTemplateTypeList.unshift({
              market_template_type_id: "",
              market_template_type_name: "全部",
            });
          }
        }
      );
    },
    handleClosePreviewTemplate() {
      this.previewTemplateVisiable = false;
      this.previewTempalteInfo = {};
    },
    /*
     *  预览模板
     */
    previewTemplate(data) {
      this.previewTemplateVisiable = true;
      this.previewTempalteInfo = data;
    },
    /*
     *  选择模板
     */
    chooseTemplate(item) {
      this.previewTemplateVisiable = false;
      this.market_template_id = item.market_template_id;
      getMarketTemplateDetail({
        market_template_id: item.market_template_id,
      }).then((res) => {
        if (res.code === 1) {
          this.templateInfo = res.data;
          this.market_template_image = res.data.market_template_image;

          this.activityInfo = {
            ...res.data,
            ...res.data.market_template_default_json,
            ...res.data.market_template_json,
            share_title: "",
            title: "",
            confirm_status: 20,
            confirm_start_time: null,
            confirm_end_time: null,
            music: Number(res.data.market_template_default_json.music),
            show_user: Number(res.data.market_template_default_json.show_user),
            show_scan: Number(res.data.market_template_default_json.show_scan),
            show_buy: Number(res.data.market_template_default_json.show_buy),
            show_join: Number(res.data.market_template_default_json.show_join),
            show_share: Number(
              res.data.market_template_default_json.show_share
            ),
            show_countdown: Number(
              res.data.market_template_default_json.show_countdown
            ),
            inventory_status: Number(
              res.data.market_template_default_json.inventory_status
            ),
            is_barrage: Number(
              res.data.market_template_default_json.is_barrage
            ),
            total_join_status:
              res.data.market_template_default_json.total_join_number === "0"
                ? 10
                : 20,
            day_join_status:
              res.data.market_template_default_json.day_join_number === "0"
                ? 10
                : 20,
            total_lottery_status:
              res.data.market_template_default_json.total_lottery_number === "0"
                ? 10
                : 20,
            day_lottery_status:
              res.data.market_template_default_json.day_lottery_number === "0"
                ? 10
                : 20,
            prizeList: [],
            goodsList: [],
            participateiInfo: {
              type:  res.data.market_template_category_id != 7 ? 30 : 60,
              goods_name: res.data.market_template_category_id != 7 ? "谢谢参与" : "",
              goods_count: res.data.market_template_category_id != 7 ? 1 : "",
              hit_odds: null,
              market_goods_type: res.data.market_template_category_id != 7 ? 10 : 40,
              goods_pic: res.data.market_template_category_id != 7 ?
                "https://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/agents/202203/d8365ee9e523e8396ec5b09a9505c21ec3111add.png" : "",
            },
          };
          this.share_image = res.data.market_template_default_json.share_image;
          this.head_image = res.data.market_template_default_json.head_image;
          this.voting_adverts = res.data.market_template_default_json.voting_adverts;
          this.qr = res.data.market_template_default_json.qr;
          this.music_name = res.data.market_template_default_json.music_name;
          this.speed = "活动设置";
          console.log(this.activityInfo);
        }
      });
    },
    /*
     *  模板列表数据
     */
    getMarketTemplateList() {
      const data = {
        market_template_category_id: this.market_template_category_id,
        market_template_type_id: this.market_template_type_id,
        market_template_lable_id: this.market_template_lable_id,
      };
      getMarketTemplateList(data).then((res) => {
        if (res.code === 1) {
          this.marketTemplateList = res.data.list;
        }
      });
    },
    lastStep() {
      if (this.speed === "活动设置") {
        this.activityInfo = {};
        this.joinHierarchyId = [];
        this.activityImage = [];
        this.images = [];
        this.templateInfo = {};
        this.speed = "选择模板";
        this.templateIndex = 0;
      } else if (this.speed === "奖品设置") {
        this.speed = "活动设置";
      }
    },
    nextStep(formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.joinHierarchyId.length==0){
            this.$message({
              type: "error",
              message: "请选择参与门店",
            });
            return;
          }
          if (
            this.activityInfo.total_lottery_status === 20 &&
            Number(this.activityInfo.day_lottery_number) >
              Number(this.activityInfo.total_lottery_number)
          ) {
            this.$message({
              type: "error",
              message: "每人每日抽奖机会不能大于总抽奖机会",
            });
          } else {
            this.speed = "奖品设置";
            console.log(this.activityInfo);
          }
        }
      });
    },
    /*
     *  设置为谢谢参与
     */
    changeLotterType(event, i) {
      if (event === 30) {
        this.prizeList = this.prizeList.map((item, index) => {
          if (index === i) {
            item.goods_name = "谢谢参与";
            item.goods_count = 1; // 谢谢参与默认为1
            item.goods_pic = "";
            item.goods_number = "";
            item.goods_number_type_name = "";
            item.goods_count_json = [];
            item.goods_id = 0;
          }
          return item;
        });
      } else {
        this.prizeList = this.prizeList.map((item, index) => {
          if (index === i) {
            item.goods_name = "";
            item.goods_count = null;
            item.hit_odds = 0;
            item.goods_pic = "";
            item.goods_number = "";
            item.goods_number_type_name = "";
            item.goods_count_json = [];
            item.goods_id = 0;
          }
          return item;
        });
      }
    },
    /*
     * 返回列表
     */
    goBack() {
      this.$emit("hide");
    },
    /*
     *  保存
     */
    submit(type, formName) {
      if (type === 1) {
        this.pushData(50, type);
      } else {
        this.$refs["activityInfo"].validate((valid) => {
          if (valid) {
            this.pushData(this.activityStatus, type);
          }
        });
      }
    },

    /*
     *  提交数据
     */
    pushData(status, type) {
      if (type === 2) {
        if (
          this.templateInfo.market_template_category_id === 1 ||
          this.templateInfo.market_template_category_id === 5
        ) {
          if (this.hit_odds_sum > 100) {
            this.$message({
              type: "error",
              message: "奖品概率相加起来不能超过100%!",
            });
            return;
          }
          if (this.hit_odds_sum < 100) {
            this.$message({
              type: "error",
              message:
                "奖品概率相加起来必须为100%，还差" +
                (100 - this.hit_odds_sum).toFixed(2) +
                "%概率可填写",
            });
            return;
          }
        }
      }
      var statusType = 10;
      if (status === 30) {
        statusType = 30;
      } else if (status === 50 && type === 2) {
        statusType = 10;
      } else if (type === 1) {
        statusType = 50;
      }
      const data = {
        market_template_function: 10,
        status: statusType,
        market_template_id: this.market_template_id,
        market_template_category_id:
          this.activityInfo.market_template_category_id,
        market_template_type_id: this.activityInfo.market_template_type_id,
        market_template_image: this.market_template_image,
        title: this.activityInfo.title,
        explain: JSON.stringify(this.activityDetail.txt.html()),
        verif_password: this.activityInfo.verif_password,
        start_time: this.activityInfo.start_time,
        end_time: this.activityInfo.end_time,
        confirm_status: this.activityInfo.same_time_status,
        confirm_start_time:
          this.activityInfo.same_time_status === 20
            ? this.activityInfo.start_time
            : this.activityInfo.confirm_start_time,
        confirm_end_time:
          this.activityInfo.same_time_status === 20
            ? this.activityInfo.end_time
            : this.activityInfo.confirm_end_time,
        show_start_time: this.activityInfo.show_start_time,
        show_end_time: this.activityInfo.show_end_time,
        client_system_id: this.activityInfo.client_system_id,
        join_hierarchy_id: this.joinHierarchyId.join(","),
        music: this.activityInfo.music,
        share_title: this.activityInfo.share_title,
        share_content: this.activityInfo.share_content,
        head_image: this.head_image,
        share_image: this.share_image,
        show_user: this.activityInfo.show_user,
        show_join: this.activityInfo.show_join,
        show_scan: this.activityInfo.show_scan,
        show_join: this.activityInfo.show_join,
        show_share: this.activityInfo.show_share,
        virtual_join: this.activityInfo.virtual_join || 0,
        virtual_share: this.activityInfo.virtual_share || 0,
        show_countdown: this.activityInfo.show_countdown,
        market_json: {
          // 投票新增开始
          is_join_award: this.activityInfo.is_join_award,
          cumulative_votes: this.activityInfo.cumulative_votes,
          voting_share_way: this.activityInfo.voting_share_way,
          voting_game: this.activityInfo.voting_game,
          apply_introduce: this.activityInfo.apply_introduce,
          apply_declaration: this.activityInfo.apply_declaration,
          apply_start_time: this.activityInfo.apply_start_time,
          apply_end_time: this.activityInfo.apply_end_time,
          voting_giving_way: this.activityInfo.voting_giving_way,
          voting_giving_day: this.activityInfo.voting_giving_day,
          voting_giving_total: this.activityInfo.voting_giving_total,
          voting_show_fans: this.activityInfo.voting_show_fans,
          voting_share_status: this.activityInfo.voting_share_status,
          vote_share_day_number: this.activityInfo.vote_share_day_number,
          vote_share_total_number: this.activityInfo.vote_share_total_number,
          // 投票新增结束
          show_goods_sales: this.activityInfo.show_goods_sales,
          show_inviter: this.activityInfo.show_inviter,
          total_join_number: this.activityInfo.total_join_number,
          day_join_number: this.activityInfo.day_join_number,
          total_lottery_number:
            this.activityInfo.total_lottery_status === 10
              ? 0
              : this.activityInfo.total_lottery_number,
          day_lottery_number:
            this.activityInfo.day_lottery_status === 10
              ? 0
              : this.activityInfo.day_lottery_number,
          share_status: this.activityInfo.share_status,
          share_add_number: this.activityInfo.share_way === "10" ? 1 : 0,
          share_day_number:
            this.activityInfo.share_way === "10"
              ? this.activityInfo.share_day_number
              : 0,
          share_total_number:
            this.activityInfo.share_way === "20"
              ? this.activityInfo.share_total_number
              : 0,
          rebate_status: this.activityInfo.rebate_status,
          rebate_prize: this.activityInfo.rebate_prize,
          pay_limit: this.activityInfo.pay_limit,
          is_gamble: this.activityInfo.is_gamble,
          is_presell: this.activityInfo.is_presell,
          rebate_draw: this.activityInfo.rebate_draw,
          get_draw_opportunities_way:
            this.activityInfo.get_draw_opportunities_way,
          share_way: this.activityInfo.share_way,
          free_draw_status: this.activityInfo.free_draw_status,
          share_total_number: this.activityInfo.share_total_number,
          draw_rebate_status: this.activityInfo.draw_rebate_status,
          rebate_list:
            this.activityInfo.rebate_status === "10"
              ? "20"
              : this.activityInfo.rebate_list,
          pay_draw: this.activityInfo.pay_draw,
          is_barrage: this.activityInfo.is_barrage,
          same_time_status: this.activityInfo.same_time_status,
          inventory_status: this.activityInfo.inventory_status,
        },
      };
      if (
        this.activityInfo.market_template_category_id === 3 ||
        this.activityInfo.market_template_category_id === 5 ||
        this.activityInfo.market_template_category_id === 6
      ) {
        data.show_buy = this.activityInfo.show_buy;
        data.virtual_browsing_history =
          this.activityInfo.virtual_browsing_history || 0;
        data.virtual_buy = this.activityInfo.virtual_buy || 0;
        data.style_json = {
          background_color: this.activityInfo.background_color,
          dynamic_effect: this.activityInfo.dynamic_effect,
        };
      }
      if (this.activityInfo.market_template_category_id === 2) {
        data.style_json = {
          background_color: this.activityInfo.background_color,
        };
      }
      // 投票新增开始
      if (this.activityInfo.market_template_category_id === 7) {
        data.show_buy = this.activityInfo.show_buy;
        data.virtual_browsing_history =
          this.activityInfo.virtual_browsing_history || 0;
        data.virtual_buy = this.activityInfo.virtual_buy || 0;
        data.style_json = {
          background_color: this.activityInfo.background_color,
          dynamic_effect: this.activityInfo.dynamic_effect,
          voting_adverts: this.voting_adverts,
          qr: this.qr,
        };
      }
      // 投票新增结束
      var goods_arr,
        prize_arr = [];
      prize_arr = this.activityInfo.prizeList.map((item, index) => {
        var info = { ...item };
        info.sort = index + 1;
        if(this.activityInfo.market_template_category_id !== 7) {
          info.market_goods_type = 10;
        } else {
          // 投票
          info.market_goods_type = 40;
        }
        return info;
      });
      // 10 抽奖商品 20 预售商品 30 秒杀 50 解锁
      // 1 抽奖 2预存 3秒杀 4签到 5 抽奖 + 预存 6 解锁 7 投票
      if (this.activityInfo.goodsList.length !== 0) {
        goods_arr = this.activityInfo.goodsList.map((item, index) => {
          var info = { ...item };
          info.sort = index + 1;
          if (this.activityInfo.market_template_category_id === 2) {
             info.market_goods_type = 20
          }
          if (this.activityInfo.market_template_category_id === 3) {
             info.market_goods_type = 30
          }
          if (this.activityInfo.market_template_category_id === 5) {
             info.market_goods_type = 20
          }
          if (this.activityInfo.market_template_category_id === 6) {
             info.market_goods_type = 50
          }
          if (this.activityInfo.market_template_category_id === 7) {
             info.market_goods_type = 30
          }
          // info.market_goods_type =
          //   this.activityInfo.market_template_category_id === 3 ? 30 : 20;
          return info;
        });
      }
      // 模板为1、5，7有奖品
      if ([1, 5, 7].includes(this.activityInfo.market_template_category_id)) {
        if (
          this.activityInfo.total_lottery_status === 20 ||
          this.activityInfo.day_lottery_status === 20
        ) {
          prize_arr.push({ ...this.activityInfo.participateiInfo });
        }

        // 投票有参与奖
        if ([7].includes(this.activityInfo.market_template_category_id)) {
          if (
            this.activityInfo.is_join_award == 10
          ) {
            prize_arr.push({ ...this.activityInfo.participateiInfo });
          }
        }

        data.market_goods =
          this.activityInfo.is_presell === "10"
            ? prize_arr.concat(goods_arr)
            : prize_arr;
      } else {
        data.market_goods = goods_arr;
      }

      data.market_goods = data.market_goods.map((item, index) => {
        item.sort = index + 1;
        return item;
      });
      console.log(data);
      // return;
      if (this.type === "add") {
        createActivity(data).then((res) => {
          if (res.code === 1) {
            this.$router.push({
              name: "ActivityList",
            });
          }
        });
      } else {
        // 追加数量去掉
        // if (this.activityStatus === 30) {
        //   data.market_goods.forEach((item) => {
        //     if (item.type === 10) {
        //       if (item.goods_count_new) {
        //         item.goods_count =
        //           Number(item.goods_count_old || 0) +
        //           Number(item.goods_count_new);
        //       } else {
        //         Number(item.goods_count_old || 0);
        //       }

        //       delete item.goods_count_new;
        //     }
        //   });
        // }

        data.market_id = this.store_activity_id;
        updateActivity(data).then((res) => {
          if (res.code === 1) {
            this.type = "add";
            this.$emit("hide");
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.speed {
  display: flex;
  height: 58px;
  background: linear-gradient(0deg, #f3f9ff 1%, #ffffff 100%);
  border: 1px solid #e8e8e8;
  border-radius: 7px 7px 0 0;
  line-height: 58px;
  font-size: 16px;

  .li {
    width: 25%;
    text-align: center;
    color: #909399;
    img {
      margin-right: 5px;
      margin-top: -3px;
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }
    i {
      float: right;
      margin-top: 22px;
      border: solid#c3c3c3;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 6px;
    }
    .right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }
}
.search {
  padding: 21px 0 11px 31px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-radius: 0px 0px 7px 7px;

  .search-li {
    margin-bottom: 10px;
    display: flex;
    .type {
      font-weight: 600;
      padding: 7px 0;
    }
    .line {
      margin: 8px 23px 0 23px;
      width: 1px;
      height: 20px;
      opacity: 1;
      background: #e5e5e5;
    }
    .box {
      margin-right: 15px;
      padding: 7px 12px;
      opacity: 1;
      background: #f7f7f7;
      border-radius: 18px;
    }
  }
}
.theme {
  width: 28%;
  min-height: 80vh;
  padding: 51px 0 8px 0;
  text-align: center;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 7px;
  .img {
    margin: 0 auto;
    padding-top: 65px;
    width: 290px;
    height: 524px;
    background-image: url("../../../../assets/images/activity/shouji.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .tip {
      margin: 0 auto 0 auto;
      width: 94.5%;
      max-height: 98.5%;
      scrollbar-width: none;
      -ms-overflow-style: none;
      overflow-x: hidden;
      overflow-y: scroll;
      border-radius: 0 0 25px 25px;
      color: #909399;
      font-size: 14px;

      img {
        width: 100%;
      }
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
}
.title {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  font-weight: 600;

  .span {
    margin: auto 6px auto 0;
    width: 4px;
    height: 12px;
    background: #5493f5;
    border-radius: 2px;
  }
}
.cutLine {
  width: 100%;
  height: 1px;
  background: #e8e8e8;
}
.activitySetUp {
  width: 100%;
  margin-top: 10px;
  padding-bottom: 50px;
  display: flex;

  .setUpActivityInfo {
    width: 70%;
    margin-left: 10px;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 7px;
  }
}
.finsh {
  width: 100%;
  margin-top: 10px;
  display: flex;
}
.prizeSetUp {
  width: 100%;
  margin-top: 10px;
  display: flex;
  .prizeMain {
    padding-bottom: 30px;
    margin-left: 10px;
    width: 71%;
  }
  .info {
    padding-bottom: 30px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 7px;
  }
  .exchange {
    margin-top: 10px;
    padding-bottom: 40px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 7px;
  }
}
.el-form-item {
  margin-bottom: 23px;
}
/deep/ .el-upload--picture-card {
  width: 70px;
  height: 70px;
  line-height: 80px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 70px;
  height: 70px;
}
/deep/.avatar-uploader .el-upload {
  width: 70px;
  height: 70px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
// /deep/ .el-upload--picture-card {
//   line-height: 115px !important;
// }
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
}

/deep/ .avatarOther {
  width: 70px;
  height: 70px;
  display: block;
}

.templateList {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .templateBox {
    margin-bottom: 10px;
    position: relative;
    padding: 10px 10px 0 10px;
    width: 266px;
    height: 500px;
    opacity: 1;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 7px;
    .templateChoose {
      position: absolute;
      top: 10px;
      background: rgba(0, 0, 0, 0.6);
      width: 267px;
      height: 455px;
      border-radius: 7px;

      .review {
        margin: 180px auto 0 auto;
        width: 100px;
        height: 38px;
        background: #ffffff;
        border: 1px solid #dbdbdb;
        border-radius: 7px;
        font-size: 14px;
        color: #2791ff;
        text-align: center;
        line-height: 38px;
      }

      .choose {
        margin: 15px auto 0 auto;
        width: 100px;
        height: 38px;
        opacity: 1;
        background: #2791ff;
        border-radius: 6px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        line-height: 38px;
      }
    }
    .templateImage {
      width: 267px;
      height: 455px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-radius: 7px;
    }
    .templateName {
      font-size: 14px;
      text-align: center;
      color: #333333;
      line-height: 38px;
    }
  }
}
.create {
  height: 64px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 70px;
  text-align: center;
  background-color: white;
  box-shadow: 0px 1px 3px 1px rgba(153, 153, 153, 0.1);
  z-index: 30;
}

.prizeInfo {
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: 30px;
  width: 850px;
  background: #f9f9f9;
}
.putAway:hover,
.review:hover,
.choose:hover,
.search-li:hover,
.resver:hover {
  cursor: pointer;
}
</style>
